import { AxiosInstance, AxiosResponse } from 'axios';
import { RouteType } from '../generated/graphql';

enum GenerateFixedTourStatus {
  Success,
  Failed,
}

interface GenerateFixedTourResponse {
  status: GenerateFixedTourStatus;
  error: string;
  stats: {
    generatedFrom: Date;
    generatedTo: Date;
    newRoutesCreated: number;
  };
}

export class HttpClient {
  constructor(private readonly client: AxiosInstance) {}

  async uploadCardFile(cardId: number, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return await this.client.post(`/api/card/${cardId}/file`, formData);
  }

  async uploadDocumentFile(file: File, tagIds: number[]): Promise<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    tagIds.forEach((tagId) => {
      formData.append('tagIds[]', `${tagId}`);
    });

    return await this.client.post(`/api/files`, formData);
  }

  downloadFile<T extends 'blob' | 'arraybuffer'>(
    file: { path: string },
    responseType: T,
  ): Promise<AxiosResponse<T extends 'blob' ? Blob : ArrayBuffer>> {
    const path = escape(file.path);
    return this.client.get(`/api/files?path=${path}`, {
      responseType,
    });
  }

  async uploadAttachmentFile(
    file: File,
    entityId: number,
    entityType: string,
  ): Promise<void> {
    const formData = new FormData();

    formData.append('file', file, file.name);
    formData.append('entityId', `${entityId}`);
    formData.append('entityType', entityType);

    return await this.client.post(`/api/files/attachmentfile`, formData);
  }

  downloadEndReportBlob(data: {
    isDownloadAsFile: boolean;
    internalTerminalId: number;
    productionDate: string;
    startDateTime: string;
    endDateTime: string;
    userFullName: string;
    locationId: number;
  }): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/api/endreport`, data, {
      responseType: 'blob',
    });
  }

  downloadTftExcelBlob(data: {
    terminalId: number[];
    startDateTime: string;
    endDateTime: string;
  }): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/api/endreport/tft-excel`, data, {
      responseType: 'blob',
    });
  }

  downloadImportTemplateBlob(data: {
    cardType: string;
  }): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/api/endreport/import-template`, data, {
      responseType: 'blob',
    });
  }

  async uploadReportFile(file: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return await this.client.post(`/api/files/report`, formData);
  }

  downloadFileArrayBuffer(file: {
    path: string;
  }): Promise<AxiosResponse<ArrayBuffer>> {
    const path = escape(file.path);
    return this.client.get(`/api/files?path=${path}`, {
      responseType: 'arraybuffer',
    });
  }

  downloadCMRBlob(data: {
    senderLocationId?: number;
    receiverLocationId?: number;
    subcontractorId?: number;
    subcontractorTwoId?: number;
    deliveryDate?: string;
  }): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/api/cmr`, data, {
      responseType: 'blob',
    });
  }

  downloadCMRBlobFromTFT(data: {
    legId: number;
  }): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/api/routes/get-cmr`, data, {
      responseType: 'blob',
    });
  }

  downloadLoadingListBlob(data: {
    routeId: number;
  }): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/api/loadingList`, data, {
      responseType: 'blob',
    });
  }

  downloadPeakPlanningList(data: {
    startDate: string;
    endDate: string;
  }): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/api/peakPlanningList`, data, {
      responseType: 'blob',
    });
  }
  downloadPeakPlanningDebitList(data: {
    startDate: string;
    endDate: string;
  }): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/api/debitList`, data, {
      responseType: 'blob',
    });
  }

  downloadKPITerminal(data: {
    terminalId: number;
    date: string;
  }): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/api/kpi/terminal`, data, {
      responseType: 'blob',
    });
  }

  generateFixedRoutes(input: {
    skipCronCheck: boolean;
    skipDBEntry: boolean;
  }): Promise<AxiosResponse<GenerateFixedTourResponse>> {
    return this.client.post(`/api/fixed-tour/generate`, input, {
      responseType: 'json',
    });
  }

  downloadTourSchedule(tourId: number) {
    return this.client.get(`/api/tourTemplate/schedule/${tourId}`, {
      responseType: 'blob',
    });
  }

  getIncomingRouteLegs(input: {
    terminalId: number[];
    startDateTime: string;
    endDateTime: string;
    routeFilterType?: string;
    sporadicOnly?: boolean;
    palletSum?: boolean;
    allCancelledLegs?: boolean;
  }): Promise<AxiosResponse<any>> {
    return this.client.post(`/api/routes/incomingroutes`, input, {
      responseType: 'json',
    });
  }

  getListRoutes(input: {
    startDate: string;
    endDate: string;
    type?: RouteType;
  }): Promise<AxiosResponse<any>> {
    return this.client.post(`/api/routes/listroutes`, input, {
      responseType: 'json',
    });
  }

  getPlannedRoutes(input: {
    createdStartDate: string;
    createdEndDate: string;
  }): Promise<AxiosResponse<any>> {
    return this.client.post(`/api/routes/plannedroutes`, input, {
      responseType: 'json',
    });
  }

  getAllTourTemplates(): Promise<AxiosResponse<any>> {
    return this.client.post(`/api/tourTemplate/all`, {
      responseType: 'json',
    });
  }

  getAllTourRoutes(): Promise<AxiosResponse<any>> {
    return this.client.post(`/api/tourTemplate/tourRoutes/all`, {
      responseType: 'json',
    });
  }

  getBookmarkedRoutes(input: { date: string }): Promise<AxiosResponse<any>> {
    return this.client.post(`/api/tourTemplate/bookmarked-routes`, input, {
      responseType: 'json',
    });
  }

  getCheckListRoutes(input: {
    transportationDate: string;
    routeGroupTag: string;
  }): Promise<AxiosResponse<any>> {
    return this.client.post(`/api/routes/checklist-routes`, input, {
      responseType: 'json',
    });
  }

  getRouteLegsForAllTerminal(input: {
    startDateTime: string;
    endDateTime: string;
  }): Promise<AxiosResponse<any>> {
    return this.client.post(`/api/routes/all-terminal-routes`, input, {
      responseType: 'json',
    });
  }
}
