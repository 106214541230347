import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ViewCard } from '../components/ViewCard';
import {
  GetCustomerDocument,
  GetCustomersDocument,
  useDeleteCustomerMutation,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from '../generated/graphql';

interface ViewCustomerProps {
  terminal?: boolean;
}

export function ViewCustomer(props: ViewCustomerProps) {
  const { terminal = false } = props;
  const { id } = useParams<{ id: string }>();
  const router = useHistory();

  const { loading, error, data } = useGetCustomerQuery({
    variables: { id: parseInt(id, 10) },
  });

  const [editOpen, setEditOpen] = useState(false);
  const [updateCustomer, {}] = useUpdateCustomerMutation({
    refetchQueries: [
      {
        query: GetCustomerDocument,
        variables: {
          id: parseInt(id, 10),
        },
      },
    ],
  });

  const [deleteCustomer] = useDeleteCustomerMutation({
    refetchQueries: [
      {
        query: GetCustomersDocument,
      },
    ],
  });

  const handleDeleteCustomer = (id: number) => {
    deleteCustomer({ variables: { id } });
    router.push(`/traffic/cards/customers`);
  };

  const onSubmit = (values: { name: string; id: number }) => {
    updateCustomer({ variables: { ...values } });
    setEditOpen(false);
  };
  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading || data == null) {
    return <div>loading...</div>;
  }

  return (
    <ViewCard
      name={data.customer.name}
      id={data.customer.id}
      type="customer"
      createdBy={data.customer.createdBy}
      lastUpdatedBy={data.customer.lastUpdatedBy}
      updatedAt={data.customer.updatedAt}
      files={data.customer.files}
      contacts={data.customer.contacts}
      onSubmit={onSubmit}
      editOpen={editOpen}
      setEditOpen={setEditOpen}
      deleteCard={handleDeleteCustomer}
      terminal={terminal}
    />
  );
}
