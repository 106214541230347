import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from '@material-ui/core';
import UnLockIcon from '@mui/icons-material/LockOpen';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useEffect, useState } from 'react';
import {
  DangerousGoodsClassification,
  useGetRouteLegForViewLoadingListLazyQuery,
  useUpdateLockMutation,
  useGetRouteLockLazyQuery,
  Maybe,
  useUpdateLegWithLoadingListMutation,
  LegLoadingListInput,
  useRouteLockSubSubscription,
  CarOrTrailerDetails,
} from '../../generated/graphql';

import { LoadingList, useStylesForLoadingList } from '../LoadingList';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useTranslation } from 'react-i18next';
import {
  FieldArray,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { SelectLocation } from '../form/SelectLocation';
import { CreatedAndUpdatedView } from '../CreatedAndUpdatedView';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { terminalLoadingListItemYupValidation } from '../../lib/validationSchema/loadingListItem';
import _ from 'lodash';
import { getLoadingList } from '../DownloadLoadingList';
import { HttpClient } from '../../lib/httpClient';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../lib/date_time';

const loadingListItemValidation = (t: TFunction) =>
  yup.object().shape({
    legsInitValue: yup.array(
      yup.object().shape({
        locationId: yup
          .number()
          .required(
            t('validation.isRequired', { name: t('attributes.terminal') }),
          )
          .positive(
            t('validation.isRequired', { name: t('attributes.terminal') }),
          )
          .min(
            1,
            t('validation.isRequired', {
              name: t('attributes.terminal'),
            }),
          ),
        carDetails: yup.object().shape({
          loadingList: terminalLoadingListItemYupValidation(t),
        }),
        trailerDetails: yup.object().shape({
          loadingList: terminalLoadingListItemYupValidation(t),
        }),
      }),
    ),
  });

export const styleForTimeModal = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  height: '92vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 20,
  p: 2,
  overflowY: 'scroll' as const,
};
interface LoadingList {
  id?: number;
  packages?: Maybe<number>;
  pallets?: Maybe<number>;
  palletSpace?: Maybe<number>;
  weight?: Maybe<number>;
  note: string;
  locationId: number;
  checked: boolean;
  isDangerous: boolean;
  isImportedFromShipment?: boolean;
  unNumber?: Maybe<number> | undefined;
  customWaybillNo?: Maybe<string> | undefined;
  isLimitedQty: boolean;
  classification?: Maybe<DangerousGoodsClassification> | undefined;
}
interface LegWithLoadingListInterface {
  id: number;
  locationId: number;
  position: number;
  isLoadingInCar?: Maybe<boolean> | undefined;
  isLoadingInTrailer?: Maybe<boolean> | undefined;
  carDetails: {
    id: number;
    loadingList: LoadingList[];
  };
  trailerDetails?: {
    id: number;
    loadingList: LoadingList[];
  };
}

interface loadingListProps {
  legId: number;
  routeId: number;
  routeName: string;
  httpClient: HttpClient;
  loadingListError: () => void;
}

export function LoadingListModal(props: loadingListProps) {
  const { t } = useTranslation();
  const { legId, loadingListError, routeId, routeName, httpClient } = props;
  const { id } = useParams<{ id: string }>();
  const [fetchLegList, { data: viewLegListData, error: viewLegListError }] =
    useGetRouteLegForViewLoadingListLazyQuery({
      fetchPolicy: 'no-cache',
    });
  const [fetchRouteLock, { data: routeLockData }] = useGetRouteLockLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [updateLegLoadingList] = useUpdateLegWithLoadingListMutation();
  const [updateLockMutation, { loading: lockLoading }] =
    useUpdateLockMutation();
  const classes = useStylesForLoadingList();
  const [routeStatus, setRouteStatus] = useState<{
    routeCreatedBy: string;
    routeLastUpdatedBy: string;
    routeUpdatedAt: string;
    routeDeletedAt: string;
    routeDeletedBy: string;
  }>({
    routeCreatedBy: '',
    routeDeletedAt: '',
    routeDeletedBy: '',
    routeLastUpdatedBy: '',
    routeUpdatedAt: '',
  });
  const [viewloadingListItems, setViewLoadingListItems] = useState(false);
  const [readOnlyView, setReadOnlyView] = useState(false);
  const [lockObject, setLockObject] = useState<{
    lockedBy: string;
    lockedAt: string;
  }>({ lockedBy: '', lockedAt: '' });
  const [legsInitValue, setLegsInitValue] = useState<
    LegWithLoadingListInterface[]
  >([]);
  const getFormattedDate = (date: any): string => {
    if (date && date !== '-') {
      const dt = new Date(date);
      return format(dt, `HH:mm - ${DATE_FORMAT}`);
    } else return '';
  };

  //for lock/unlock refresh
  useRouteLockSubSubscription({
    variables: {
      routeId: parseInt(id, 10),
    },
    async onSubscriptionData({ subscriptionData }) {
      if (
        subscriptionData.data &&
        subscriptionData.data.routeLockSub &&
        subscriptionData.data.routeLockSub.routeId === parseInt(id, 10)
      ) {
        if (
          (subscriptionData.data.routeLockSub.lock === 3 &&
            subscriptionData.data.routeLockSub.reload === 3) ||
          (subscriptionData.data.routeLockSub.lock === 4 &&
            subscriptionData.data.routeLockSub.reload === 4)
        ) {
          setViewLoadingListItems(false);
        } else {
          if (
            subscriptionData.data.routeLockSub.lockedBy !== '' &&
            subscriptionData.data.routeLockSub.lock === 1
          ) {
            setLockObject({
              lockedBy: subscriptionData.data.routeLockSub.lockedBy,
              lockedAt: getFormattedDate(
                subscriptionData.data.routeLockSub.lockedAt,
              ),
            });
          }
        }
      }
    },
  });
  const [totalSum, setTotalSum] = useState({
    totalPalletsSum: 0,
    totalPPLSum: 0,
  });
  let totalPallets = 0;
  let totalPPL = 0;

  useEffect(() => {
    if (viewLegListData && viewLegListData.routeLegForViewLoadingList) {
      const loadingListInitArray: LegWithLoadingListInterface[] = [];
      viewLegListData.routeLegForViewLoadingList.forEach((leg) => {
        if (leg.isNewLegVariant) {
          loadingListInitArray.push({
            id: leg.id,
            locationId: leg.locationId,
            position: leg.position,
            isLoadingInCar: leg.isLoadingInCar,
            isLoadingInTrailer: leg.isLoadingInTrailer,
            carDetails: {
              id: leg.carDetails?.id ?? 0,
              loadingList: leg.carDetails?.loadingListItems
                ? leg.carDetails?.loadingListItems
                : [],
            },
            trailerDetails: {
              id: leg.trailerDetails?.id ?? 0,
              loadingList: leg.trailerDetails?.loadingListItems
                ? leg.trailerDetails?.loadingListItems
                : [],
            },
          });
          totalPallets =
            totalPallets +
            _.sumBy(leg.carDetails?.loadingListItems, 'pallets') +
            _.sumBy(leg.trailerDetails?.loadingListItems, 'pallets');
          totalPPL =
            totalPPL +
            _.sumBy(leg.carDetails?.loadingListItems, 'palletSpace') +
            _.sumBy(leg.trailerDetails?.loadingListItems, 'palletSpace');
        } else {
          loadingListInitArray.push({
            id: leg.id,
            locationId: leg.locationId,
            position: leg.position,
            isLoadingInCar: true,
            isLoadingInTrailer: false,
            carDetails: {
              id: leg.carDetails?.id ?? 0,
              loadingList: leg.loadingListItems,
            },
            trailerDetails: undefined,
          });
          totalPallets =
            totalPallets + _.sumBy(leg.loadingListItems, 'pallets');
          totalPPL = totalPPL + _.sumBy(leg.loadingListItems, 'palletSpace');
        }
      });
      setLegsInitValue(loadingListInitArray);
      setTotalSum({ totalPalletsSum: totalPallets, totalPPLSum: totalPPL });
      if (viewLegListData.routeLegForViewLoadingList[0].route) {
        setRouteStatus({
          routeCreatedBy:
            viewLegListData.routeLegForViewLoadingList[0].route.createdBy,
          routeLastUpdatedBy:
            viewLegListData.routeLegForViewLoadingList[0].route.lastUpdatedBy,
          routeUpdatedAt:
            viewLegListData.routeLegForViewLoadingList[0].route.updatedAt,
          routeDeletedAt:
            viewLegListData.routeLegForViewLoadingList[0].route.deletedAt,
          routeDeletedBy:
            viewLegListData.routeLegForViewLoadingList[0].route.deletedBy,
        });
      }
      if (viewLegListData.routeLegForViewLoadingList[0].id === legId) {
        //if selected leg is load type then we check if its route is locked or not
        fetchRouteLock({ variables: { routeId } });
      } else {
        //if selected leg is unload type then we display previous leg in readOnly mode.
        setViewLoadingListItems(true);
        setReadOnlyView(true);
      }
    } else if (viewLegListError) {
      loadingListError();
    }
  }, [viewLegListData, viewLegListError]);

  useEffect(() => {
    if (routeLockData?.routeLock) {
      setViewLoadingListItems(true);
      if (routeLockData?.routeLock && routeLockData.routeLock.lockedBy) {
        setLockObject({
          lockedAt: getFormattedDate(routeLockData?.routeLock.lockedAt),
          lockedBy: routeLockData?.routeLock.lockedBy,
        });
        setReadOnlyView(true);
      } else {
        //Locked by you
        updateLockMutation({
          variables: { routeId: routeId, lock: 1, reload: 0 },
        });
        setReadOnlyView(false);
      }
    }
  }, [routeLockData]);

  return (
    <>
      <Tooltip
        title={`${t('actions.viewItem', {
          item: t('resource.loadingList.capitalized'),
        })}`}
      >
        <IconButton
          onClick={() => {
            fetchLegList({
              variables: {
                legId: legId,
              },
            });
          }}
        >
          <PageviewIcon color="action" />
        </IconButton>
      </Tooltip>
      {legsInitValue.length > 0 && (
        <Modal
          open={viewloadingListItems}
          onClose={() => {
            //if route is locked by other
            if (!readOnlyView) {
              updateLockMutation({
                variables: { routeId: routeId, lock: 0, reload: 1 },
              }).then(() => {
                setViewLoadingListItems(false);
              });
            } else setViewLoadingListItems(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableScrollLock
        >
          <Box sx={styleForTimeModal}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography
                  align="center"
                  style={{ color: 'black', fontSize: 20, fontWeight: 'bold' }}
                >
                  {`Route Id: ${routeName}`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Box>
                  {lockObject?.lockedBy && readOnlyView ? (
                    <Tooltip title={`${t('actions.unlock')}`}>
                      <IconButton
                        edge="end"
                        aria-label="Unlock"
                        onClick={() => {
                          updateLockMutation({
                            variables: { routeId: routeId, lock: 1, reload: 1 },
                          }).then(() => {
                            setReadOnlyView(false);
                            setLockObject({
                              lockedAt: '',
                              lockedBy: '',
                            });
                          });
                        }}
                      >
                        {<UnLockIcon />}
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
            {routeStatus.routeCreatedBy && (
              <CreatedAndUpdatedView
                createdBy={routeStatus.routeCreatedBy}
                lastUpdatedBy={routeStatus.routeLastUpdatedBy}
                updatedAt={routeStatus.routeUpdatedAt}
                deletedAt={routeStatus.routeDeletedAt}
                deletedBy={routeStatus.routeDeletedBy}
                lockedBy={lockObject?.lockedBy}
                lockedAt={lockObject?.lockedAt}
              />
            )}

            {!readOnlyView && (
              <Typography align="left" style={{ color: 'orangered' }}>
                {t('attributes.routeLockedBySelf')}
              </Typography>
            )}
            {/* if leg is of type load then only we can edit else we will 
            just disply previous leg in read only mode */}
            <Grid container>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <Typography align="center" style={{ fontWeight: 'bold' }}>
                  {`Total-pallets: ${totalSum.totalPalletsSum}`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align="center" style={{ fontWeight: 'bold' }}>
                  {`Total pallet-place: ${totalSum.totalPPLSum}`}
                </Typography>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Formik
              initialValues={{ legsInitValue }}
              validate={(value) => {
                try {
                  validateYupSchema(
                    value,
                    loadingListItemValidation(t),
                    true,
                    value,
                  );
                } catch (err) {
                  const errors = yupToFormErrors<{ error: string }>(err);
                  return errors;
                }

                return {};
              }}
              onSubmit={(value) => {
                const carDetails = (value: {
                  legsInitValue: LegWithLoadingListInterface[];
                }): CarOrTrailerDetails => {
                  if (
                    value.legsInitValue &&
                    value.legsInitValue[0].carDetails
                  ) {
                    return {
                      id: value.legsInitValue[0].carDetails.id,
                      loadingListItems:
                        value.legsInitValue[0].carDetails.loadingList?.map(
                          (ll) => ({
                            id: ll.id,
                            locationId: ll.locationId,
                            packages: ll.packages ? +ll.packages : 0,
                            pallets: ll.pallets ? +ll.pallets : 0,
                            palletSpace: ll.palletSpace ? +ll.palletSpace : 0,
                            weight: ll.weight ? +ll.weight : 0,
                            checked: ll.checked,
                            isDangerous: ll.isDangerous,
                            classification: ll.classification,
                            unNumber: ll.unNumber,
                            isLimitedQty: ll.isLimitedQty,
                            note: ll.note,
                          }),
                        ),
                    };
                  }
                  return {
                    id: 0,
                    loadingListItems: [],
                  };
                };

                const trailerDetails = (value: {
                  legsInitValue: LegWithLoadingListInterface[];
                }): CarOrTrailerDetails => {
                  if (
                    value.legsInitValue &&
                    value.legsInitValue[0].trailerDetails
                  ) {
                    return {
                      id: value.legsInitValue[0].trailerDetails.id,
                      loadingListItems:
                        value.legsInitValue[0].trailerDetails.loadingList?.map(
                          (ll) => ({
                            id: ll.id,
                            locationId: ll.locationId,
                            packages: ll.packages ? +ll.packages : 0,
                            pallets: ll.pallets ? +ll.pallets : 0,
                            palletSpace: ll.palletSpace ? +ll.palletSpace : 0,
                            weight: ll.weight ? +ll.weight : 0,
                            checked: ll.checked,
                            isDangerous: ll.isDangerous,
                            classification: ll.classification,
                            unNumber: ll.unNumber,
                            isLimitedQty: ll.isLimitedQty,
                            note: ll.note,
                          }),
                        ),
                    };
                  }
                  return {
                    id: 0,
                    loadingListItems: [],
                  };
                };

                const mutationVariable: LegLoadingListInput = {
                  id: legId,
                  carDetails: carDetails(value),
                  trailerDetails: trailerDetails(value),
                };

                updateLegLoadingList({
                  variables: { input: mutationVariable },
                });
                setViewLoadingListItems(false);
                if (!readOnlyView)
                  updateLockMutation({
                    variables: { routeId: routeId, lock: 0, reload: 1 },
                  }).then(() => setViewLoadingListItems(false));
                else setViewLoadingListItems(false);
              }}
            >
              {(props) => {
                return (
                  <Form>
                    <FieldArray
                      name={'legsInitValue'}
                      render={() => (
                        <Grid item xs={12}>
                          {props.values.legsInitValue.map((leg, legIndex) => (
                            <>
                              <Grid container style={{ marginTop: 5 }}>
                                <Grid item xs={1}>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      marginBottom: 5,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {`Leg ${leg.position + 1}`}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <SelectLocation
                                    key={`legsInitValue.${legIndex}`}
                                    name={`legsInitValue.${legIndex}.locationId`}
                                    className={classes.selecLocationField}
                                    readOnly={true}
                                    size="small"
                                    smallFontSize
                                    noLabel
                                    smallPadding
                                    shortAddress={true}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item container xs={12}>
                                {leg.isLoadingInCar && (
                                  <LoadingList
                                    load={true}
                                    formikPathName={`legsInitValue.${legIndex}.carDetails.loadingList`}
                                    readOnly={readOnlyView}
                                    orders={leg.carDetails?.loadingList ?? []}
                                    carOrTrailer="Car Order"
                                  />
                                )}
                                {leg.isLoadingInTrailer && (
                                  <LoadingList
                                    load={true}
                                    formikPathName={`legsInitValue.${legIndex}.trailerDetails.loadingList`}
                                    readOnly={readOnlyView}
                                    orders={
                                      leg.trailerDetails?.loadingList ?? []
                                    }
                                    carOrTrailer="Trailer Order"
                                  />
                                )}
                              </Grid>
                              <Divider
                                style={{
                                  backgroundColor: 'green',
                                  marginTop: 5,
                                }}
                              />
                            </>
                          ))}
                        </Grid>
                      )}
                    />
                    <Grid
                      item
                      container
                      justifyContent="center"
                      style={{ marginTop: 5 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 10 }}
                        startIcon={<CloudDownloadIcon />}
                        disabled={lockLoading}
                        onClick={() => {
                          getLoadingList(routeId, routeName, httpClient);
                          setViewLoadingListItems(false);

                          if (!readOnlyView) {
                            updateLockMutation({
                              variables: {
                                routeId: routeId,
                                lock: 0,
                                reload: 1,
                              },
                            }).then(() => {
                              setViewLoadingListItems(false);
                            });
                          } else {
                            setViewLoadingListItems(false);
                          }
                        }}
                      >
                        {t('actions.download')}
                      </Button>
                      {!readOnlyView && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginRight: 10 }}
                          disabled={lockLoading}
                        >
                          {t('actions.update')}
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        disabled={lockLoading}
                        onClick={() => {
                          setViewLoadingListItems(false);
                          if (!readOnlyView) {
                            updateLockMutation({
                              variables: {
                                routeId: routeId,
                                lock: 0,
                                reload: 1,
                              },
                            }).then(() => setViewLoadingListItems(false));
                          } else {
                            setViewLoadingListItems(false);
                          }
                        }}
                      >
                        {t('button.close')}
                      </Button>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Modal>
      )}
    </>
  );
}
