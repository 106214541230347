import {
  Button,
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DangerIcon from '@mui/icons-material/Report';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkedIcon from '@mui/icons-material/Link';
import { Box } from '@mui/system';
import { FieldArray, useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DangerousGoodsClassification, Maybe } from '../generated/graphql';
import { newLoadingListItem } from '../lib/formHelpers/loadingList';
import { CheckboxIconField } from './form/CheckboxIconField';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { SimpleMessageDialog } from './SimpleMessageDialog';
import { SelectEnumFieldSmall } from './form/SelectEnumFieldSmall';
import { FastTextFieldPackage } from './form/FastTextFieldPackage';
import { FastTextFieldNote } from './form/FastTextFieldNote';
import { FastTextFieldPallets } from './form/FastTextFieldPallets';
import { FastTextFieldPalletSpace } from './form/FastTextFieldPalletSpace';
import { FastTextFieldWeight } from './form/FastTextFieldWeight';
import { FastCheckbox } from './form/FastCheckbox';
import { FastTextFieldUnNumber } from './form/FastTextFieldUnNumber';
import { SelectLocation } from './form/SelectLocation';

export interface LoadingListItemInterface {
  id?: number;
  packages?: Maybe<number | string>;
  pallets?: Maybe<number | string>;
  palletSpace?: Maybe<number | string>;
  weight?: Maybe<number | string>;
  note: string;
  locationId?: number;
  checked: boolean;
  isDangerous: boolean;
  unNumber?: Maybe<number> | undefined;
  isLimitedQty: boolean;
  isImportedFromShipment?: boolean;
  customWaybillNo?: Maybe<string> | undefined;
  classification?: Maybe<DangerousGoodsClassification> | undefined;
  location?: {
    name: string;
    address: string;
    postalCode: string;
    country: string;
    city: string;
  } | null;
}

interface LoadingListProps {
  load: boolean;
  readOnly: boolean;
  orders: LoadingListItemInterface[];
  formikPathName: string;
  terminal?: boolean;
  newRow?: boolean;
  isCreateTerminalRoute?: boolean;
  isFromCreate?: boolean;
  carOrTrailer?: string;
}

export const useStylesForLoadingList = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      margin: theme.spacing(0.5, 0, 0.5),
      display: 'flex',
      justifyContent: 'space-between',
    },
    greenChecked: {
      backgroundColor: '#d3f8d3',
    },
    redBorder: {
      border: '1px solid red',
    },
    greyBackFocused: {
      backgroundColor: '#f1f5eb',
      paddingTop: 2,
      paddingBottom: 2,
    },
    blueBackReadOnly: {
      backgroundColor: '#d1e7fc',
      paddingTop: 2,
      paddingBottom: 2,
    },
    root: {
      margin: 0,
      padding: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
    noPadding: {
      padding: '0 !important',
    },
    deleteButton: {
      padding: '0 !important',
      marginRight: 1,
    },
    listItem: {
      '&.MuiListItem': {
        padding: 0,
      },
      '&.MuiListItem-gutters': {
        padding: 0,
      },
      '.MuiListItem-root': {
        padding: 0,
      },
    },
    numberFields: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    selecLocationField: {
      width: '98%',
      fontSize: '10px',
      margin: 0,
      padding: 0,
    },
    field: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
  }),
);

export function LoadingList(props: LoadingListProps) {
  const {
    load,
    readOnly,
    orders,
    formikPathName,
    isCreateTerminalRoute = false,
    isFromCreate = false,
    carOrTrailer = '',
  } = props;
  const { t } = useTranslation();
  const { getFieldProps } = useFormikContext();

  const [currentFocusedIndex, setCurrentFocusedIndex] = useState<number>(-1);
  const [openLimitedQtyInfoDialog, setOpenLimitedQtyInfoDialog] =
    useState<boolean>(false);
  const classes = useStylesForLoadingList();

  function getChecked(index: number): string {
    if (getFieldProps(`${formikPathName}.${index}.checked`).value) {
      return classes.greenChecked;
    }
    return '';
  }

  function getDangerous(index: number): string {
    if (getFieldProps(`${formikPathName}.${index}.isDangerous`).value) {
      return classes.redBorder;
    }
    return '';
  }

  const handleBorderVisibility = (index: number) => {
    if (index !== currentFocusedIndex) setCurrentFocusedIndex(index);
  };

  if (!load) {
    return <>Loading...</>;
  }

  return (
    <FieldArray
      name={`${formikPathName}`}
      render={(arrayHelpers) => (
        <>
          <Grid item xs={12} spacing={2} container className={classes.root}>
            <SimpleMessageDialog
              open={openLimitedQtyInfoDialog}
              setOpen={setOpenLimitedQtyInfoDialog}
              title={t('validation.limitedQtyInfoTitle')}
              contentText={t('validation.limitedQtyInfo')}
              isMultiLine={true}
            />
            <Grid item xs={10}>
              <Typography
                variant="subtitle1"
                align="left"
                style={{
                  color: '#1c1c1c',
                  fontWeight: 'bold',
                  alignContent: 'center',
                }}
              >
                {carOrTrailer
                  ? `${carOrTrailer}`
                  : t('resource.loadingList.capitalized')}
              </Typography>
            </Grid>
            {!readOnly && (
              <Grid item xs={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      arrayHelpers.push(newLoadingListItem());
                    }}
                    style={{ marginBottom: 2, marginTop: 2 }}
                  >
                    {t('button.add', { item: t('order') })}
                  </Button>
                </Box>
              </Grid>
            )}
            {orders.length > 0 && (
              <Grid
                xs={12}
                item
                container
                className={classes.noPadding}
                style={{ marginLeft: 15 }}
              >
                <Grid item xs={4}>
                  <Typography variant="body2" color="textPrimary">
                    {'Customer'}
                  </Typography>
                </Grid>
                <Grid container item xs={3}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textPrimary">
                      {'PCS'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textPrimary">
                      {'PAL'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textPrimary">
                      {'PPL'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="textPrimary">
                      {'KG'}
                    </Typography>
                  </Grid>
                </Grid>
                {isFromCreate === false && (
                  <Grid item xs={2}>
                    <Typography variant="body2" color="textPrimary">
                      {'WayBill.No'}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={2}>
                  <Typography variant="body2" color="textPrimary">
                    {'Note'}
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            )}
            <Grid item xs={12} style={{ marginLeft: 15 }}>
              <List className={classes.noPadding}>
                {orders.map((_order, index) => {
                  const isLinkedOrder = _order.isImportedFromShipment;
                  return (
                    <ListItem
                      key={index}
                      divider
                      className={`${getChecked(index)} ${getDangerous(index)} ${
                        classes.noPadding
                      }`}
                    >
                      <Grid
                        xs={12}
                        item
                        container
                        key={index}
                        style={{ width: '100%' }}
                        onFocus={(event) => {
                          if (
                            !event.currentTarget.contains(
                              event.relatedTarget as Node,
                            )
                          ) {
                            handleBorderVisibility(index);
                          }
                        }}
                        onBlur={(event) => {
                          if (
                            !event.currentTarget.contains(
                              event.relatedTarget as Node,
                            )
                          ) {
                            setCurrentFocusedIndex(-1);
                          }
                        }}
                        className={`${
                          currentFocusedIndex === index && !readOnly
                            ? classes.greyBackFocused
                            : ''
                        } ${classes.noPadding}`}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          style={{ width: '100%' }}
                        >
                          <Grid item container xs={4} direction={'row'}>
                            {isLinkedOrder && (
                              <Grid item xs={1}>
                                <Tooltip
                                  title={`${t('attributes.orderIsLinked')}`}
                                >
                                  <LinkedIcon
                                    style={{
                                      color: 'grey',
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            )}
                            <Grid item xs={isLinkedOrder ? 11 : 12}>
                              <SelectLocation
                                key={`${formikPathName}_${index}`}
                                name={`${formikPathName}.${index}.locationId`}
                                className={classes.selecLocationField}
                                readOnly={readOnly || isLinkedOrder}
                                size="small"
                                smallFontSize
                                noLabel
                                smallPadding
                                shortAddress={true}
                              />
                            </Grid>
                          </Grid>

                          <Grid container item xs={3}>
                            <Grid item xs={3}>
                              <FastTextFieldPackage
                                noLabel
                                size="small"
                                smallFontSize
                                name={`${formikPathName}.${index}.packages`}
                                className={classes.numberFields}
                                type="number"
                                readOnly={readOnly || isLinkedOrder}
                                smallPadding
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FastTextFieldPallets
                                noLabel
                                size="small"
                                smallFontSize
                                name={`${formikPathName}.${index}.pallets`}
                                className={classes.numberFields}
                                type="number"
                                readOnly={readOnly || isLinkedOrder}
                                smallPadding
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FastTextFieldPalletSpace
                                noLabel
                                size="small"
                                smallFontSize
                                smallPadding
                                name={`${formikPathName}.${index}.palletSpace`}
                                className={classes.numberFields}
                                type="number"
                                readOnly={readOnly || isLinkedOrder}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FastTextFieldWeight
                                noLabel
                                size="small"
                                smallFontSize
                                smallPadding
                                name={`${formikPathName}.${index}.weight`}
                                className={classes.numberFields}
                                type="number"
                                readOnly={readOnly || isLinkedOrder}
                              />
                            </Grid>
                          </Grid>
                          {isFromCreate === false && (
                            <Grid item xs={2} style={{ marginRight: 2 }}>
                              <FastTextFieldNote
                                noLabel
                                size="small"
                                multiline
                                smallFontSize
                                name={`${formikPathName}.${index}.customWaybillNo`}
                                readOnly={true}
                                className={classes.field}
                                smallPadding
                              />
                            </Grid>
                          )}
                          <Grid item xs={2} style={{ marginRight: 2 }}>
                            <FastTextFieldNote
                              noLabel
                              size="small"
                              multiline
                              smallFontSize
                              name={`${formikPathName}.${index}.note`}
                              readOnly={readOnly || isLinkedOrder}
                              className={classes.field}
                              smallPadding
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box
                              width={'100%'}
                              display="flex"
                              justifyContent="space-evenly"
                              alignItems="center"
                            >
                              {!readOnly && !isLinkedOrder && (
                                <FastCheckbox
                                  name={`${formikPathName}.${index}.checked`}
                                  label={''}
                                  readOnly={readOnly || isLinkedOrder}
                                  size="small"
                                />
                              )}
                              <Tooltip
                                title={
                                  readOnly || isLinkedOrder
                                    ? `${t(
                                        'attributes.tooltipDangerousGoodsIconReadOnly',
                                      )}`
                                    : `${t(
                                        'attributes.tooltipDangerousGoodsIcon',
                                      )}`
                                }
                              >
                                <div>
                                  <CheckboxIconField
                                    name={`${formikPathName}.${index}.isDangerous`}
                                    icon={
                                      <DangerIcon
                                        style={{
                                          color: readOnly ? '#bdbdbd' : 'grey',
                                        }}
                                      />
                                    }
                                    checkedIcon={
                                      <DangerIcon
                                        style={{
                                          color:
                                            readOnly || isLinkedOrder
                                              ? '#f97048'
                                              : 'red',
                                        }}
                                      />
                                    }
                                    readOnly={readOnly || isLinkedOrder}
                                    size="small"
                                  />
                                </div>
                              </Tooltip>
                              {!readOnly && !isLinkedOrder && (
                                <>
                                  {index === 0 && isCreateTerminalRoute ? (
                                    <></>
                                  ) : (
                                    <IconButton
                                      className={classes.deleteButton}
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </>
                              )}
                            </Box>
                          </Grid>
                        </Box>
                        {_order.isDangerous && (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start"
                            style={{ width: '100%', marginTop: 1 }}
                          >
                            {/* new for dangerous goods info */}
                            <Grid
                              item
                              xs={5}
                              container
                              direction="row"
                              alignItems="center"
                            >
                              <Grid item xs={2}>
                                <Typography
                                  style={{
                                    fontSize: 12,
                                  }}
                                  color="textPrimary"
                                >{`Goods Info -`}</Typography>
                              </Grid>
                              <Grid item xs={10}>
                                <SelectEnumFieldSmall
                                  name={`${formikPathName}.${index}.classification`}
                                  readOnly={readOnly || isLinkedOrder}
                                  enumObject={DangerousGoodsClassification}
                                  translationPath={'enums.dangerousGoodsClass'}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              container
                              direction="row"
                              alignItems="center"
                            >
                              <Grid item xs={2}>
                                <Typography
                                  style={{
                                    fontSize: 12,
                                  }}
                                  color="textPrimary"
                                >{`UN-`}</Typography>
                              </Grid>
                              <Grid item xs={10}>
                                <FastTextFieldUnNumber
                                  noLabel
                                  size="small"
                                  smallFontSize
                                  name={`${formikPathName}.${index}.unNumber`}
                                  className={classes.numberFields}
                                  type="number"
                                  placeholder="4 digit number"
                                  readOnly={readOnly || isLinkedOrder}
                                  smallPadding
                                  autoComplete="off"
                                  onInput={(e) => {
                                    (e.target as HTMLInputElement).value =
                                      Math.max(
                                        0,
                                        parseInt(
                                          (e.target as HTMLInputElement).value,
                                        ),
                                      )
                                        .toString()
                                        .slice(0, 4);
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={2} container direction="row">
                              <Grid item xs={10}>
                                <FastCheckbox
                                  name={`${formikPathName}.${index}.isLimitedQty`}
                                  label={t('attributes.limitedQty')}
                                  readOnly={readOnly || isLinkedOrder}
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={2}>
                                {!readOnly && (
                                  <Tooltip
                                    title={`${t(
                                      'attributes.tooltipLimitedQtyInfo',
                                    )}`}
                                  >
                                    <IconButton
                                      className={classes.deleteButton}
                                      onClick={() =>
                                        setOpenLimitedQtyInfoDialog(true)
                                      }
                                    >
                                      <InfoOutlined />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item xs={3}></Grid>
                          </Box>
                        )}
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </>
      )}
    />
  );
}
