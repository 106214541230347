import {
  Grid,
  IconButton,
  ListItem,
  createStyles,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import LinkIcon from '@mui/icons-material/Link';
import UnLinkIcon from '@mui/icons-material/LinkOff';
import OkIcon from '@mui/icons-material/CheckCircle';
import NotOkIcon from '@mui/icons-material/Cancel';
import { ReactComponent as NotesIcon } from '../svgIcons/notesicon.svg';
import { ReactComponent as TerminalIcon } from '../svgIcons/terminalicon.svg';
import { ReactComponent as CustomsIcon } from '../svgIcons/customsicon.svg';
import { ReactComponent as DangerIcon } from '../svgIcons/dangericon.svg';
//import { ReactComponent as FilesIcon } from '../svgIcons/filesicon.svg';
import { Box } from '@mui/system';
import { useState } from 'react';
import { DangerousGoodsClassification, Maybe } from '../generated/graphql';
import { useFormContext } from 'react-hook-form';
import { SelectLocationCLRHF } from './form/SelectLocationCLRHF';
import { FastTextPalletSpaceCLRHF } from './form/FastTextPalletSpaceCLRHF';
import { FastTextNoteCLRHF } from './form/FastTextNoteCLRHF';
import { FastTextPackageCLRHF } from './form/FastTextPackageCLRHF';
import { FastTextPalletsCLRHF } from './form/FastTextPalletsCLRHF';
import { FastTextWeightCLRHF } from './form/FastTextWeightCLRHF';
import { ImportShipmentFormInput } from '../views/ImportShipment';
import { SelectEnumFieldSmallRHF } from './form/SelectEnumFieldSmallRHF';
import { FastCheckboxRHF } from './form/FastCheckboxRHF';
import { FastTextFieldUnNumberRHF } from './form/FastTextFieldUnNumberRHF';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { LinkShipmentModal } from './modal/LinkShipmentModal';
import { DialogConfirmationModal } from './DialoConfirmationgModal';

export interface LoadingListItemInterface {
  id?: number;
  packages?: Maybe<number | string>;
  pallets?: Maybe<number | string>;
  palletSpace?: Maybe<number | string>;
  weight?: Maybe<number | string>;
  note: string;
  locationId?: number;
  checked: boolean;
  isDangerous: boolean;
  unNumber?: Maybe<number> | undefined;
  isLimitedQty: boolean;
  classification?: Maybe<DangerousGoodsClassification> | undefined;
}

interface ShipmentOrderProps {
  linkNow: (
    routeId: number,
    legId: number,
    orderID: number,
    loadInCar: boolean,
    loadInTrailer: boolean,
  ) => void;
  unLinkNow: (orderID: number) => void;
  readOnly: boolean;
  shipmentIndex: number;
  isPastDate: boolean;
  orderIndex: number;
  orderId: number;
  key: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    greenChecked: {
      backgroundColor: '#d3f8d3',
    },
    redBorder: {
      border: '1px solid red',
    },
    greyBackFocused: {
      backgroundColor: '#f1f5eb',
      paddingTop: 2,
      paddingBottom: 2,
    },
    root: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 6,
      padding: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
    noPadding: {
      padding: '0 !important',
    },
    iconButton: {
      padding: '0 !important',
      marginRight: 10,
    },
    iconDivError: {
      paddingBottom: 2,
      marginRight: 10,
      borderBottom: '2px solid #ff0000',
      height: 26,
      width: 24,
    },
    iconDiv: {
      padding: '0 !important',
      marginRight: 10,
      height: 24,
      width: 24,
    },
    iconButtonSelected: {
      padding: '0 !important',
      marginRight: 5,
      backgroundColor: '#91f768',
    },
    numberFields: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    selecLocationField: {
      width: '98%',
      fontSize: '10px',
      margin: 0,
      padding: 0,
    },
    field: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
  }),
);

export function ShipmentOrderRHF(props: ShipmentOrderProps) {
  const {
    linkNow,
    unLinkNow,
    readOnly,
    shipmentIndex,
    orderIndex,
    key,
    orderId,
    isPastDate,
  } = props;
  const classes = useStyles();
  const selectedDarkGreen = '#3c9317';
  const dangerRed = '#ff0000';
  const normalGreen = '#92d376';
  const { getValues: getFieldProps } =
    useFormContext<ImportShipmentFormInput>();
  const { t } = useTranslation();
  const [noteUI, setNoteUI] = useState<boolean>(false);
  const [dangerGoodsUI, setDangerGoodsUI] = useState<boolean>(false);
  const [customsUI, setCustomsUI] = useState<boolean>(false);
  const [terminalUI, setTerminalUI] = useState<boolean>(false);
  const [unlinkDialogVisible, setUnlinkDialogVisible] =
    useState<boolean>(false);

  const [isLinkModalOpen, setLinkModalOpen] = useState<boolean>(false);

  const toLocationId = getFieldProps(`shipments.${shipmentIndex}.toLocationId`);
  const fromLocationId = getFieldProps(
    `shipments.${shipmentIndex}.fromLocationId`,
  );
  const transportDate = getFieldProps(
    `shipments.${shipmentIndex}.shipmentDate`,
  );

  const isImportedFromShipment = getFieldProps(
    `shipments.${shipmentIndex}.loadingListItems.${orderIndex}.isImportedFromShipment`,
  );
  const isCustomClearedForExport = getFieldProps(
    `shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customCleared`,
  );
  const isCustomClearedForImport = getFieldProps(
    `shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customClearedForImport`,
  );
  const isTerminalCleared = getFieldProps(
    `shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalCleared`,
  );
  const notClearedFromTerminal = getFieldProps(
    `shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalNotCleared`,
  );
  const isCustomCleared = isCustomClearedForExport && isCustomClearedForImport;
  const allOK =
    isCustomClearedForExport && isCustomClearedForImport && isTerminalCleared;

  const routeNameLinked = isImportedFromShipment
    ? getFieldProps(
        `shipments.${shipmentIndex}.loadingListItems.${orderIndex}.routeDetail.routeName`,
      )
    : 'Not Linked';

  const isActualLinked = isImportedFromShipment && routeNameLinked != '';
  function getChecked(index: number): string {
    if (
      getFieldProps(
        `shipments.${shipmentIndex}.loadingListItems.${index}.checked`,
      )
    ) {
      return classes.greenChecked;
    }
    return '';
  }

  function getDangerous(index: number): string {
    if (
      getFieldProps(
        `shipments.${shipmentIndex}.loadingListItems.${index}.isDangerous`,
      )
    ) {
      return classes.redBorder;
    }
    return '';
  }

  return (
    <>
      {
        <DialogConfirmationModal
          open={unlinkDialogVisible}
          setOpen={setUnlinkDialogVisible}
          contentText={`${t('validation.confirmation', {
            action: t('actions.unlink'),
            item: ' this order item',
          })}`}
          doAction={() => {
            unLinkNow(orderId);
          }}
          cancelAction={() => {
            setUnlinkDialogVisible(false);
          }}
          buttonText={t('button.unlink')}
        />
      }
      {isLinkModalOpen && (
        <LinkShipmentModal
          open={isLinkModalOpen}
          fromLocationId={fromLocationId}
          toLocationId={toLocationId}
          transportDate={transportDate ? transportDate : ''}
          handleClose={() => {
            setLinkModalOpen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onSelectConfirm={(routeId, legId, loadInCar, loadInTrailer) => {
            //Link now
            linkNow(routeId, legId, orderId, loadInCar, loadInTrailer);
          }}
        />
      )}
      <Grid item xs={12} spacing={2} container className={classes.root}>
        <ListItem
          key={key}
          divider
          className={`${getChecked(orderIndex)} ${getDangerous(orderIndex)} ${
            classes.noPadding
          }`}
        >
          <Grid
            xs={12}
            item
            container
            key={orderIndex}
            style={{ width: '100%' }}
            className={classes.noPadding}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              <Grid item container xs={4}>
                <Grid item xs={1}>
                  <IconButton className={classes.iconButton}>
                    {allOK ? (
                      <OkIcon htmlColor={selectedDarkGreen} />
                    ) : (
                      <NotOkIcon htmlColor={dangerRed} />
                    )}
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <SelectLocationCLRHF
                    key={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}O`}
                    name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.locationId`}
                    controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.locationId`}
                    className={classes.selecLocationField}
                    readOnly={readOnly}
                    size="small"
                    smallFontSize
                    noLabel
                    smallPadding
                    shortAddress={true}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={2}>
                <Grid item xs={3}>
                  <FastTextPackageCLRHF
                    name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.packages`}
                    controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.packages`}
                    className={classes.numberFields}
                    type="number"
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FastTextPalletsCLRHF
                    name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.pallets`}
                    controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.pallets`}
                    className={classes.numberFields}
                    type="number"
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FastTextPalletSpaceCLRHF
                    name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.palletSpace`}
                    controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.palletSpace`}
                    className={classes.numberFields}
                    type="number"
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FastTextWeightCLRHF
                    name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.weight`}
                    controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.weight`}
                    className={classes.numberFields}
                    type="number"
                    readOnly={readOnly}
                  />
                </Grid>
              </Grid>

              <Grid item xs={2}>
                <FastTextNoteCLRHF
                  name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customWaybillNo`}
                  controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customWaybillNo`}
                  readOnly={readOnly}
                  className={classes.field}
                />
              </Grid>
              <Grid item container xs={2} style={{ marginRight: 2 }}>
                <Grid item xs={9}>
                  <Box
                    width={'96%'}
                    display="flex"
                    alignItems="center"
                    style={{
                      backgroundColor: 'lightgrey',
                    }}
                  >
                    <Tooltip title={`${routeNameLinked}`}>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '1',
                          WebkitBoxOrient: 'vertical',
                        }}
                        style={{
                          fontSize: 13,
                          padding: 1,
                        }}
                      >
                        {routeNameLinked == '' ? 'Not Linked' : routeNameLinked}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      if (isActualLinked === false) setLinkModalOpen(true);
                      else setUnlinkDialogVisible(true);
                    }}
                  >
                    {isActualLinked && !isPastDate ? (
                      <UnLinkIcon height={24} width={24} />
                    ) : allOK && !isPastDate ? (
                      <LinkIcon height={24} width={24} />
                    ) : null}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item container xs={1}>
                <Grid item xs={6}>
                  <FastCheckboxRHF
                    name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.isVoecCustomer`}
                    controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.isVoecCustomer`}
                    readOnly={readOnly}
                    size="small"
                    label=""
                  />
                </Grid>
                <Grid item xs={6}>
                  <FastCheckboxRHF
                    name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.isRemainingGoods`}
                    controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.isRemainingGoods`}
                    readOnly={readOnly}
                    size="small"
                    label=""
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="center"
              >
                <Box width={'100%'} display="flex" alignItems="center">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setNoteUI(!noteUI);
                    }}
                  >
                    <NotesIcon
                      height={24}
                      width={24}
                      fill={noteUI ? selectedDarkGreen : normalGreen}
                    />
                  </IconButton>

                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setDangerGoodsUI(!dangerGoodsUI);
                    }}
                  >
                    <DangerIcon
                      height={24}
                      width={24}
                      fill={dangerGoodsUI ? selectedDarkGreen : normalGreen}
                    />
                  </IconButton>
                  <div
                    className={
                      isCustomCleared ? classes.iconDiv : classes.iconDivError
                    }
                  >
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        setCustomsUI(!customsUI);
                      }}
                    >
                      <CustomsIcon
                        height={24}
                        width={24}
                        fill={customsUI ? selectedDarkGreen : normalGreen}
                      />
                    </IconButton>
                  </div>
                  <div
                    className={
                      isTerminalCleared ? classes.iconDiv : classes.iconDivError
                    }
                  >
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        setTerminalUI(!terminalUI);
                      }}
                    >
                      <TerminalIcon
                        height={24}
                        width={24}
                        fill={terminalUI ? selectedDarkGreen : normalGreen}
                      />
                    </IconButton>
                  </div>
                </Box>
              </Grid>
            </Box>

            {noteUI ? (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ width: '100%', marginLeft: 28 }}
              >
                <Grid item xs={8}>
                  <Grid container xs={12}>
                    <Grid item xs={1}>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                        color="textPrimary"
                      >{`Note -`}</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <FastTextNoteCLRHF
                        multiline
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.note`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.note`}
                        readOnly={readOnly}
                        className={classes.field}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
            {dangerGoodsUI ? (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ width: '100%', marginTop: 1, marginLeft: 28 }}
              >
                <Grid item xs={6} container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontSize: 12,
                      }}
                      color="textPrimary"
                    >{`Goods Info -`}</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <SelectEnumFieldSmallRHF
                      name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.classification`}
                      controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.classification`}
                      readOnly={readOnly}
                      enumObject={DangerousGoodsClassification}
                      translationPath={'enums.dangerousGoodsClass'}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontSize: 12,
                      }}
                      color="textPrimary"
                    >{`UN-`}</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <FastTextFieldUnNumberRHF
                      noLabel
                      size="small"
                      smallFontSize
                      name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.unNumber`}
                      controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.unNumber`}
                      className={classes.numberFields}
                      type="number"
                      readOnly={readOnly}
                      smallPadding
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} container direction="row">
                  <Grid item xs={10}>
                    <FastCheckboxRHF
                      name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.isLimitedQty`}
                      controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.isLimitedQty`}
                      label={t('attributes.limitedQty')}
                      readOnly={readOnly}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={5}></Grid>
                </Grid>
              </Box>
            ) : null}
            {customsUI ? (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ width: '100%', marginTop: 1, marginLeft: 28 }}
              >
                {/* for 1-2 column fields */}
                <Grid item xs={3}>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                        color="textPrimary"
                      >{`VOEC ID:`}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FastTextNoteCLRHF
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customVOECId`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customVOECId`}
                        readOnly={readOnly}
                        className={classes.field}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                        color="textPrimary"
                      >{`Invoice:`}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FastTextNoteCLRHF
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customInvoiceNo`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customInvoiceNo`}
                        readOnly={readOnly}
                        className={classes.field}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* for 3-4 column fields */}
                <Grid item xs={2}>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                        color="textPrimary"
                      >{`MRN No:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <FastTextNoteCLRHF
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customMrnNo`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customMrnNo`}
                        readOnly={readOnly}
                        className={classes.field}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                        color="textPrimary"
                      >{`CMR No:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <FastTextNoteCLRHF
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customCmrNo`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customCmrNo`}
                        readOnly={readOnly}
                        className={classes.field}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* for 5-6 column fields */}
                <Grid item xs={3}>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                        color="textPrimary"
                      >{`Waybill:`}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FastTextNoteCLRHF
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customWaybillNo`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customWaybillNo`}
                        readOnly={readOnly}
                        className={classes.field}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                        color="textPrimary"
                      >{`Note:`}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FastTextNoteCLRHF
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customNote`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customNote`}
                        readOnly={readOnly}
                        className={classes.field}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* for 7-8 column fields */}
                <Grid item xs={3}>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                        color="textPrimary"
                      >{`Connected No:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <FastTextNoteCLRHF
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.connectedNB`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.connectedNB`}
                        readOnly={readOnly}
                        className={classes.field}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      {/* for export */}
                      <FastCheckboxRHF
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customCleared`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customCleared`}
                        label={t('attributes.clearedFromCustom')}
                        readOnly={readOnly}
                        size="small"
                      />
                      <FastCheckboxRHF
                        name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customClearedForImport`}
                        controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.customClearedForImport`}
                        label={t('attributes.clearedFromCustomImport')}
                        readOnly={readOnly}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
            {terminalUI ? (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ width: '100%', marginTop: 1, marginLeft: 28 }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <FastCheckboxRHF
                      name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalCleared`}
                      controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalCleared`}
                      label={t('attributes.terminalCleared')}
                      readOnly={readOnly}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FastCheckboxRHF
                      name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalNotCleared`}
                      controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalNotCleared`}
                      label={t('attributes.terminalNotCleared')}
                      readOnly={readOnly}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2} style={{ marginRight: 4 }}>
                    <FastCheckboxRHF
                      name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalShipmentNotArrived`}
                      controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalShipmentNotArrived`}
                      label={t('attributes.shipmentNotArrived')}
                      readOnly={readOnly}
                      size="small"
                    />
                  </Grid>

                  {notClearedFromTerminal ? (
                    <Grid
                      xs={12}
                      container
                      direction="row"
                      alignItems="center"
                      style={{ marginTop: 4 }}
                    >
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            fontSize: 12,
                          }}
                          color="textPrimary"
                        >{`Packages`}</Typography>
                        <FastTextPackageCLRHF
                          name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevPackages`}
                          controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevPackages`}
                          className={classes.numberFields}
                          type="number"
                          readOnly={readOnly}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            fontSize: 12,
                          }}
                          color="textPrimary"
                        >{`Pallets`}</Typography>
                        <FastTextPalletsCLRHF
                          name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevPallets`}
                          controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevPallets`}
                          className={classes.numberFields}
                          type="number"
                          readOnly={readOnly}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            fontSize: 12,
                          }}
                          color="textPrimary"
                        >{`PalletPlace`}</Typography>
                        <FastTextPalletSpaceCLRHF
                          name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevPalletSpace`}
                          controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevPalletSpace`}
                          className={classes.numberFields}
                          type="number"
                          readOnly={readOnly}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            fontSize: 12,
                          }}
                          color="textPrimary"
                        >{`Weight`}</Typography>
                        <FastTextWeightCLRHF
                          name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevWeight`}
                          controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevWeight`}
                          className={classes.numberFields}
                          type="number"
                          readOnly={readOnly}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ marginRight: 2 }}>
                        <Typography
                          style={{
                            fontSize: 12,
                          }}
                          color="textPrimary"
                        >{`Information`}</Typography>
                        <FastTextNoteCLRHF
                          multiline
                          name={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevInfo`}
                          controllerName={`shipments.${shipmentIndex}.loadingListItems.${orderIndex}.terminalDevInfo`}
                          readOnly={readOnly}
                          className={classes.field}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
            ) : null}
          </Grid>
        </ListItem>
      </Grid>
    </>
  );
}
