import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetHolidaysDocument,
  useDeleteHolidayMutation,
  useGetHolidaysQuery,
} from '../generated/graphql';
import { COUNTRIES_MAP } from '../lib/constants';
import { DeleteActionCell } from './datagrid/DeleteActionCell';
import { SelectCountryField } from './form/SelectCountryField';

export const useStyles = makeStyles((theme) => ({
  componentTitle: {
    marginBottom: theme.spacing(2),
  },
  selectCountryRoot: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    minWidth: 300,
    marginBottom: theme.spacing(1),
    '& *': {
      marginBottom: theme.spacing(1),
    },
  },
}));

interface ListHolidayProps {
  country: string;
  setCountry: (country: string) => void;
}

export function ListHoliday(props: ListHolidayProps) {
  const { country, setCountry } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const { data, loading, refetch } = useGetHolidaysQuery({
    variables: {
      country,
    },
  });
  const [deleteHoliday] = useDeleteHolidayMutation({
    refetchQueries: [
      {
        query: GetHolidaysDocument,
        variables: {
          country,
        },
      },
    ],
  });
  useEffect(() => {
    refetch({
      country,
    });
  }, [country]);

  const holidays = data?.holidays;

  if (loading) {
    return <h1>loading...</h1>;
  }

  if (holidays == null) {
    return <h1>Something went wrong...</h1>;
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      hide: true,
      sortingOrder: ['asc'],
      disableColumnMenu: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      valueFormatter(params) {
        const value = params.value;
        return value != null && typeof value === 'string'
          ? format(new Date(value), 'yyyy-MM-dd')
          : '';
      },
    },
    {
      field: 'alternateGenerateDate',
      headerName: 'Alternate Date',
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      valueFormatter(params) {
        const value = params.value;
        return value != null && typeof value === 'string'
          ? format(new Date(value), 'yyyy-MM-dd')
          : '';
      },
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 1,
      disableColumnMenu: true,
      valueGetter(params) {
        return COUNTRIES_MAP[params.value as keyof typeof COUNTRIES_MAP];
      },
    },
    {
      field: '',
      headerName: '',
      flex: 1,
      editable: false,
      type: 'action',
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <DeleteActionCell
            itemTitle={params.row.title}
            deleteAction={() => {
              deleteHoliday({ variables: { id: params.row.id } });
            }}
          />
        );
      },
    },
  ];

  const onCountrySubmit = (values: { country: string }) => {
    setCountry(values.country);
  };

  return (
    <Box>
      <Box>
        <Typography
          className={classes.componentTitle}
          align="left"
          variant="subtitle1"
        >
          {t('pages.holidays.upcomingComponentTitle', {
            country: COUNTRIES_MAP[country as keyof typeof COUNTRIES_MAP],
          })}
        </Typography>
      </Box>
      <Formik
        enableReinitialize
        initialValues={{ country }}
        onSubmit={onCountrySubmit}
      >
        {() => (
          <Box className={classes.selectCountryRoot}>
            <Form>
              <Box flexDirection={'row'}>
                <SelectCountryField name="country" />
                <Button type="submit" variant="contained" color="primary">
                  {t('button.change', { item: t('attributes.country') })}
                </Button>
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
      <DataGridPro
        rowHeight={40}
        autoHeight
        rows={holidays}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </Box>
  );
}
