import AssessmentIcon from '@material-ui/icons/Assessment';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import GridOnIcon from '@material-ui/icons/GridOn';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import TimelineIcon from '@material-ui/icons/Timeline';
import FeedIcon from '@mui/icons-material/Feed';
import { TFunction, useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { AddContact } from '../components/AddContact';
import { AddCustomerAccount } from '../components/AddCustomerAccount';
import { AddLocation } from '../components/AddLocation';
import BreadCrumbs from '../components/BreadCrumbs';
import { Layout } from '../components/Layout';
import { configuration } from '../configuration';
import { CardType, iconForType } from '../lib/iconForType';
import { PdfWrapper } from '../pdf/PdfWrapper';
import { CreateCheckpoint } from './CreateCheckpoint';
import { CreateCustomer } from './CreateCustomer';
import { CreateMultipleSporadicRouteRHF } from './CreateMultipleSporadicRouteRHF';
import { CreateOrder } from './CreateOrder';
import { CreateRouteTemplate } from './CreateRouteTemplate';
import { CreateSporadicRoute } from './CreateSporadicRoute';
import { CreateSporadicRouteRHF } from './CreateSporadicRouteRHF';
import { CreateSporadicRouteTemplate } from './CreateSporadicRouteTemplate';
import { CreateSubcontractor } from './CreateSubcontractor';
import { CreateTerminal } from './CreateTerminal';
import { CreateTourTemplate } from './CreateTourTemplate';
import { Deviations } from './Deviations';
import { DocumentBank } from './DocumentBank';
import { FixedRoutes } from './FixedRoutes';
import { GenerateFixed } from './GenerateFixed';
import { Holidays } from './Holidays';
import { Locations } from './Locations';
import { ManualCMR } from './ManualCMR';
import { Orders } from './Orders';
import { PeakPlanning } from './PeakPlanning';
import { PeakPlanningDebit } from './PeakPlanningDebit';
import { ReportToTerminal } from './ReportToTerminal';
import { SporadicRoutes } from './SporadicRoutes';
import { TourTemplates } from './TourTemplates';
import { TrafficDashboard } from './TrafficDashboard';
import { ViewCheckpoint } from './ViewCheckpoint';
import { ViewCustomer } from './ViewCustomer';
import { ViewOrder } from './ViewOrder';
import { ViewRouteRHF } from './ViewRouteRHF';
import { ViewSporadicRouteTemplate } from './ViewSporadicRouteTemplate';
import { ViewSubcontractor } from './ViewSubcontractor';
import { ViewTerminal } from './ViewTerminal';
import { ViewTourTemplate } from './ViewTourTemplate';
import { Card } from './Cards';
import { TrafficTFT } from './TrafficTFT';
import { Shipment } from './Shipment';
import { useAccess } from '../lib/useAccess';
import { Button, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import AccessDeniedIcon from '@mui/icons-material/DoNotDisturb';
import { ShipmentLoadingList } from '../components/datagrid/ShipmentLoadingListGrid';

const links = (t: TFunction<'translation'>) => {
  return [
    {
      text: t('pages.dashboard.title'),
      path: '/traffic',
      Icon: HomeIcon,
    },
    {
      text: `TFT Overview`,
      path: '/traffic/tft',
      Icon: LocalShippingIcon,
    },
    configuration.production
      ? null
      : {
          text: t('resource.location.plural'),
          path: '/traffic/locations',
          Icon: LocationOnIcon,
        },
    {
      text: t('resource.card.plural'),
      path: '/traffic/cards',
      Icon: iconForType(CardType.Cards),
    },
    {
      text: t('pages.documentBank.title'),
      path: '/traffic/documentbank',
      Icon: DescriptionIcon,
    },
    {
      text: t('resource.shipment.lowercased'),
      path: '/traffic/shipment',
      Icon: LocalShippingIcon,
    },
    {
      text: t('resource.plannedRoute.plural'),
      path: '/traffic/create-planned',
      Icon: LocalShippingIcon,
    },
    {
      text: t('resource.sporadicRoute.plural'),
      path: '/traffic/sporadic-routes',
      Icon: LocalShippingIcon,
    },
    {
      text: t('resource.dispatchedRoute.plural'),
      path: '/traffic/dispatched-routes',
      Icon: LocalShippingIcon,
    },
    // {
    //   text: t('resource.checklistRoute.plural'),
    //   path: '/traffic/checklist-routes',
    //   Icon: ChecklistTruckIcon,
    // },
    {
      text: t('resource.tour.plural'),
      path: '/traffic/tours',
      Icon: TimelineIcon,
    },
    {
      text: t('resource.reportToTerminal.capitalized'),
      path: '/traffic/report-to-terminal',
      Icon: FeedIcon,
    },
    {
      text: t('resource.holiday.plural'),
      path: '/traffic/holidays',
      Icon: EventIcon,
    },
    {
      text: t('resource.cmr.plural'),
      path: '/traffic/cmr',
      Icon: GridOnIcon,
    },
    // {
    //   text: t('resource.peakPlanning.capitalized'),
    //   path: '/traffic/peakplanning',
    //   Icon: AssessmentIcon,
    // },
    // {
    //   text: t('resource.peakDebit.capitalized'),
    //   path: '/traffic/peakplanning/debit',
    //   Icon: CreditCardIcon,
    // },
    {
      text: 'Deviations',
      path: '/traffic/deviations',
      Icon: SentimentDissatisfiedIcon,
    },
  ];
};

export function TrafficPage() {
  const { path } = useRouteMatch();
  const { traffic } = useAccess();
  const { t } = useTranslation();
  const router = useHistory();

  if (!traffic) {
    return (
      <Stack alignItems={'center'}>
        <div
          style={{
            maxHeight: 250,
            minWidth: 300,
            border: '1px solid red',
            alignItems: 'center',
            marginTop: 20,
            padding: 10,
          }}
        >
          <AccessDeniedIcon style={{ minHeight: 50, minWidth: 50 }} />
          <Typography style={{ margin: 10, fontWeight: 'bold' }}>
            'No Traffic page access!'
          </Typography>
          <Button
            onClick={() => {
              router.push(`${path.split('/')[0]}`);
            }}
            variant="contained"
            color="primary"
          >
            {'Go to home'}
          </Button>
        </div>
      </Stack>
    );
  }
  return (
    <Layout pageName="Traffic" drawerLinks={links(t)}>
      <BreadCrumbs />
      <Switch>
        {/* Document bank */}
        <Route path={`${path}/documentbank/`}>
          <DocumentBank />
        </Route>

        <Route path={`${path}/locations`}>
          <Locations />
        </Route>
        {/* Create loading list */}
        <Route path={`${path}/shipment`} exact>
          <Shipment from="Traffic" />
        </Route>
        <Route path={`${path}/shipment/:id`} exact>
          <ShipmentLoadingList updatedFrom="Traffic" />
        </Route>
        {/* Sporadic routes */}
        <Route path={`${path}/sporadic-routes/create/:template`}>
          <CreateSporadicRoute />
        </Route>
        <Route path={`${path}/sporadic-routes/create`}>
          <CreateSporadicRouteRHF />
        </Route>
        <Route path={`${path}/sporadic-routes/routeTemplate/create`}>
          <CreateSporadicRouteTemplate />
        </Route>
        <Route path={`${path}/sporadic-routes/routeTemplate/:id/edit`}>
          <ViewSporadicRouteTemplate />
        </Route>
        <Route exact path={`${path}/sporadic-routes/:id`}>
          <ViewRouteRHF />
        </Route>
        <Route exact path={`${path}/sporadic-routes/:id/pdf`}>
          <PdfWrapper />
        </Route>
        <Route path={`${path}/sporadic-routes`}>
          <SporadicRoutes />
        </Route>
        <Route path={`${path}/create-planned`}>
          <CreateMultipleSporadicRouteRHF />
        </Route>
        {/* Fixed Routes */}
        <Route exact path={`${path}/dispatched-routes/:id`}>
          <ViewRouteRHF />
        </Route>
        {/* <Route exact path={`${path}/checklist-routes`}>
          <CheckListRoutesTraffic />
        </Route> */}
        <Route path={`${path}/dispatched-routes`}>
          <FixedRoutes />
        </Route>

        {/* Route Template */}
        <Route path={`${path}/routes`}>
          <CreateRouteTemplate />
        </Route>

        {/* Tour Template */}
        <Route exact path={`${path}/tours/create/:id`}>
          <CreateTourTemplate />
        </Route>
        <Route path={`${path}/tours/create`}>
          <CreateTourTemplate />
        </Route>
        <Route path={`${path}/tours/:id`}>
          <ViewTourTemplate />
        </Route>
        <Route path={`${path}/tours`}>
          <TourTemplates />
        </Route>

        {/* Order */}
        <Route exact path={`${path}/orders/create`}>
          <CreateOrder />
        </Route>
        <Route path={`${path}/orders/:id`}>
          <ViewOrder />
        </Route>
        <Route path={`${path}/orders`}>
          <Orders />
        </Route>

        {/* Subcontractors */}
        {/* main Subcontractors component is being rendered inside Card.tsx */}

        <Route path={`${path}/cards/subcontractors/create`}>
          <CreateSubcontractor />
        </Route>
        <Route exact path={`${path}/cards/subcontractors/:id`}>
          <ViewSubcontractor />
        </Route>
        <Route exact path={`${path}/cards/subcontractors/:id/createcontact`}>
          <AddContact next={'subcontractors'} />
        </Route>
        <Route path={`${path}/cards/subcontractors/:id/create`}>
          <AddLocation next={'subcontractors'} />
        </Route>

        {/* Customers */}
        {/* main Customers component is being rendered inside Card.tsx */}
        <Route path={`${path}/cards/customers/create`}>
          <CreateCustomer />
        </Route>
        <Route exact path={`${path}/cards/customers/:id`}>
          <ViewCustomer />
        </Route>
        <Route exact path={`${path}/cards/customers/:id/createcontact`}>
          <AddContact next={'customers'} />
        </Route>
        <Route path={`${path}/cards/customers/:id/create`}>
          <AddLocation next={'customers'} />
        </Route>
        <Route path={`${path}/cards/customers/:id/createaccount`}>
          <AddCustomerAccount next={'customers'} />
        </Route>

        {/* Terminals */}
        {/* main Terminals component is being rendered inside Card.tsx */}
        <Route path={`${path}/cards/terminals/create`}>
          <CreateTerminal />
        </Route>
        <Route exact path={`${path}/cards/terminals/:id`}>
          <ViewTerminal />
        </Route>
        <Route exact path={`${path}/cards/terminals/:id/createcontact`}>
          <AddContact next={'terminals'} />
        </Route>
        <Route path={`${path}/cards/terminals/:id/create`}>
          <AddLocation next={'terminals'} />
        </Route>

        {/* Checkpoints */}
        {/* main Checkpoints component is being rendered inside Card.tsx */}
        <Route path={`${path}/cards/checkpoints/create`}>
          <CreateCheckpoint />
        </Route>
        <Route exact path={`${path}/cards/checkpoints/:id`}>
          <ViewCheckpoint />
        </Route>
        <Route exact path={`${path}/cards/checkpoints/:id/createcontact`}>
          <AddContact next={'checkpoints'} />
        </Route>
        <Route path={`${path}/cards/checkpoints/:id/create`}>
          <AddLocation next={'checkpoints'} />
        </Route>

        {/* {cards} */}
        <Route path={`${path}/cards/`}>
          <Card />
        </Route>
        <Route path={`${path}/`} exact>
          <TrafficDashboard
            isInTerminal={false}
            terminal={{
              id: 0,
              terminal: {
                locations: [
                  {
                    id: 0,
                    country: '',
                  },
                ],
              },
            }}
          />
        </Route>
        <Route path={`${path}/tft`}>
          <TrafficTFT />
        </Route>

        {/* Holidays */}
        <Route path={`${path}/holidays`}>
          <Holidays />
        </Route>

        {/* Report to terminal */}
        <Route path={`${path}/report-to-terminal`}>
          <ReportToTerminal />
        </Route>

        {/* CMR */}
        <Route path={`${path}/cmr`}>
          <ManualCMR />
        </Route>

        {/* Peak Planning */}
        {/* <Route exact path={`${path}/peakplanning`}>
          <PeakPlanning />
        </Route> */}
        {/* <Route exact path={`${path}/peakplanning/debit`}>
          <PeakPlanningDebit />
        </Route> */}

        {/* Deviations */}
        <Route path={`${path}/deviations`}>
          <Deviations />
        </Route>

        {/* Generate */}
        {/* TODO: Remove when job fixed  */}
        <Route path={`${path}/generate`}>
          <GenerateFixed />
        </Route>
      </Switch>
    </Layout>
  );
}
