import {
  Box,
  Checkbox,
  createStyles,
  Link,
  makeStyles,
  Tooltip,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import DangerIcon from '@material-ui/icons/Report';
import {
  GridCellEditCommitParams,
  GridCellParams,
  GridFilterModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { format, parse } from 'date-fns';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../App.css';
import {
  DocumentFile,
  RouteType,
  useTruckFillAndTimeMutation,
} from '../generated/graphql';
import { COUNTRIES_MAP, TABLE_NAMES } from '../lib/constants';
import { DATE_FORMAT, timeOrNull } from '../lib/date_time';
import { EditableCell } from './datagrid/EditableCell';
import { UserConfiguredDataGridPro } from './datagrid/UserConfiguredDataGridPro';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DownloadFileIcon from '@mui/icons-material/DownloadOutlined';
import RefreshIcon from '@material-ui/icons/CachedOutlined';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import CancelledIcon from '@mui/icons-material/HighlightOff';
import { QRModal } from './datagrid/QRModal';
import ToysIcon from '@mui/icons-material/Toys';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useHttpClient } from '../providers/HttpClientProvider';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { TerminalTimeInfo } from './datagrid/TerminalTimeInfoModal';

const DISABLED = true;
const TABLE_NAME = 'truckFillAndTimeReadOnly';

const useStylesIncomingRoutes = makeStyles(() =>
  createStyles({
    editableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& p:first-child': {
        maxWidth: '75%',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        inlineSize: '75%',
      },
    },
    muiOdd: {
      backgroundColor: '#e7e7e7',
      '&.Mui-selected': {
        border: '1px solid #66bb6a',
      },
    },
    muiEven: {
      backgroundColor: '#fff',
      '&.Mui-selected': {
        border: '1px solid #66bb6a',
      },
    },
  }),
);

const RegisteredChip = withStyles({
  root: {
    color: '#12de63',
    borderColor: '#12de63',
  },
})(Chip);

const UnregisteredChip = withStyles({
  root: {
    color: '#f07267',
    borderColor: '#f07267',
  },
})(Chip);

const CancelledChip = withStyles({
  root: {
    color: '#fc3926',
    borderColor: '#fc3926',
  },
})(Chip);

const PartialRegisteredChip = withStyles({
  root: {
    color: '#eda528',
    borderColor: '#eda528',
  },
})(Chip);

interface ListIncomingRoutesProps {
  gridTop: number;
  //filters: Filters;
  isHideRegistered: boolean;
  onGetTFTExcelClicked: () => void;
  legs?: {
    id: number;
    arrivalTime: string;
    departureTime?: Maybe<string>;
    actualArrivalTime?: Maybe<string>;
    actualArrivalDate?: Maybe<string>;
    arrivalStatus?: Maybe<string> | undefined;
    actualDepartureTime?: Maybe<string>;
    actualDepartureDate?: Maybe<string>;
    productionDate: string;
    transportationDate: string;
    actualCapacity?: Maybe<number>;
    loadCar?: Maybe<number>;
    loadCages?: Maybe<number>;
    loadTrailer?: Maybe<number>;
    unloadCar?: Maybe<number>;
    unloadCages?: Maybe<number>;
    timeObj?: {
      originalArrTime: string;
      actualArrTime: string;
      originalDepartTime: string;
      actualDepartTime: string;
      terminalName: string;
      legId: number;
      transportationDate: string;
      arrivingPallets: number;
      arrivingPalletSpace: number;
    }[];
    unloadTrailer?: Maybe<number>;
    loadCarPPL?: Maybe<number>;
    loadTrailerPPL?: Maybe<number>;
    unloadCarPPL?: Maybe<number>;
    unloadTrailerPPL?: Maybe<number>;
    note: string;
    unload: boolean;
    load: boolean;
    gateNumber?: Maybe<string> | undefined;
    carRegistrationNumber?: Maybe<string> | undefined;
    trailerRegistrationNumber?: Maybe<string> | undefined;
    terminalNickname?: Maybe<string> | undefined;
    terminalNote: string;
    lastUpdatedBy?: Maybe<string>;
    updatedAt: string;
    isNonArrivalMarked?: boolean;
    nonArrivalMarkedFrom?: Maybe<string>;
    isSealBroken?: Maybe<boolean>;
    sealNumber?: Maybe<string> | undefined;
    productionGroup?: Maybe<string> | undefined;
    isLoadingInCar?: boolean;
    isLoadingInTrailer?: boolean;
    carDetails?: {
      actualCapacity?: Maybe<number> | undefined;
      registrationNumber?: Maybe<string> | undefined;
      gateNumber?: Maybe<string> | undefined;
    };
    trailerDetails?: {
      actualCapacity?: Maybe<number> | undefined;
      registrationNumber?: Maybe<string> | undefined;
      gateNumber?: Maybe<string> | undefined;
    };
    files: Pick<
      DocumentFile,
      'id' | 'size' | 'path' | 'mimetype' | 'originalname'
    >[];
    loadingListItems: {
      pallets?: Maybe<number>;
      palletSpace?: Maybe<number>;
      isDangerous: boolean;
    }[];
    location?: Maybe<{
      id: number;
      city: string;
      country: string;
      name?: string;
    }>;
    route: {
      id: number;
      isCreatedFromTerminal: boolean;
      isCreatedFromPlanned: boolean;
      type: RouteType;
      routeId: string;
      capacity?: Maybe<number>;
      trailerCarrierId: string;
      carCarrierId: string;
      tourRoute?: Maybe<{
        tourId: number;
      }>;
      transportationDate: string;
      driverName: string;
      subcontractor: {
        id: number;
        name: string;
      };
      legs: {
        position?: Maybe<number>;
        location?: Maybe<{
          city: string;
          country: string;
          name: string;
        }>;
      }[];
    };
  }[];
}

export function DashboardTFTReadOnly(props: ListIncomingRoutesProps) {
  const classes = useStylesIncomingRoutes();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const { httpClient } = useHttpClient();
  const { legs, onGetTFTExcelClicked } = props;
  const [height, setHeight] = useState(
    window.innerHeight - (props.gridTop + props.gridTop / 5),
  );
  const rows = buildRows(legs);
  const [filteredRowsData, setFilteredRowsData] = useState({});
  const [totalObj, setTotalObj] = useState({
    totalLoadPallet: 0,
    totalLoadPalletSpace: 0,
    totalUnloadPallet: 0,
    totalUnloadPalletSpace: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'transportationDate',
      sort: 'asc',
    },
    {
      field: 'arrivalTime',
      sort: 'asc',
    },
  ]);
  const [truckFillAndTimeMutation] = useTruckFillAndTimeMutation();
  const { getFilterModel } = useUserConfiguration();
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>(
    getFilterModel(TABLE_NAME) ?? {
      items: [],
    },
  );
  const [dataGrid, setDataGrid] = useState(rows ?? []);
  React.useEffect(() => {
    setHeight(window.innerHeight - props.gridTop);
  }, [props.gridTop]);

  React.useEffect(() => {
    if (props.isHideRegistered === true && rows && rows.length > 0) {
      const rowWithStatus = rows.map((obj) => ({
        ...obj,
        statusStr: getChipStatus(obj).toString(),
      }));
      const withoutRegisteredValues = rowWithStatus.filter(
        (i) =>
          i.statusStr === t('attributes.unregisteredStatus').toString() ||
          i.statusStr === t('attributes.partialRegisteredStatus').toString() ||
          i.statusStr === 'N/A',
      );
      setDataGrid(withoutRegisteredValues);
    } else {
      setDataGrid(rows);
    }
  }, [legs, props.isHideRegistered]);

  React.useEffect(() => {
    if (_.isEmpty(filteredRowsData)) {
      //filter not applied
      if (rows && rows.length > 0) {
        const totalLoadPallets =
          (_.sumBy(rows, 'loadCar') ?? 0) + (_.sumBy(rows, 'loadTrailer') ?? 0);
        const totoalLoadPalletSpace =
          (_.sumBy(rows, 'loadCarPPL') ?? 0) +
          (_.sumBy(rows, 'loadTrailerPPL') ?? 0);
        const totalUnloadPallets =
          (_.sumBy(rows, 'unloadCar') ?? 0) +
          (_.sumBy(rows, 'unloadTrailer') ?? 0);
        const totalUnloadPalletSpace =
          (_.sumBy(rows, 'unloadCarPPL') ?? 0) +
          (_.sumBy(rows, 'unloadTrailerPPL') ?? 0);
        setTotalObj({
          totalLoadPallet: totalLoadPallets,
          totalLoadPalletSpace: totoalLoadPalletSpace,
          totalUnloadPallet: totalUnloadPallets,
          totalUnloadPalletSpace: totalUnloadPalletSpace,
        });
      } else {
        setTotalObj({
          totalLoadPallet: 0,
          totalLoadPalletSpace: 0,
          totalUnloadPallet: 0,
          totalUnloadPalletSpace: 0,
        });
      }
    } else {
      if (rows && rows.length > 0) {
        //filter is applied
        const visibleItems: any[] = [];
        for (const [id, value] of Object.entries(filteredRowsData)) {
          if (value === true) {
            visibleItems.push(id);
          }
        }
        const filteredRowsOnly = rows.filter((item) =>
          visibleItems.includes(item.id?.toString()),
        );
        if (filteredRowsOnly) {
          const totalLoadPallets =
            (_.sumBy(filteredRowsOnly, 'loadCar') ?? 0) +
            (_.sumBy(filteredRowsOnly, 'loadTrailer') ?? 0);
          const totoalLoadPalletSpace =
            (_.sumBy(filteredRowsOnly, 'loadCarPPL') ?? 0) +
            (_.sumBy(filteredRowsOnly, 'loadTrailerPPL') ?? 0);
          const totalUnloadPallets =
            (_.sumBy(filteredRowsOnly, 'unloadCar') ?? 0) +
            (_.sumBy(filteredRowsOnly, 'unloadTrailer') ?? 0);
          const totalUnloadPalletSpace =
            (_.sumBy(filteredRowsOnly, 'unloadCarPPL') ?? 0) +
            (_.sumBy(filteredRowsOnly, 'unloadTrailerPPL') ?? 0);
          setTotalObj({
            totalLoadPallet: totalLoadPallets,
            totalLoadPalletSpace: totoalLoadPalletSpace,
            totalUnloadPallet: totalUnloadPallets,
            totalUnloadPalletSpace: totalUnloadPalletSpace,
          });
        } else {
          setTotalObj({
            totalLoadPallet: 0,
            totalLoadPalletSpace: 0,
            totalUnloadPallet: 0,
            totalUnloadPalletSpace: 0,
          });
        }
      } else {
        setTotalObj({
          totalLoadPallet: 0,
          totalLoadPalletSpace: 0,
          totalUnloadPallet: 0,
          totalUnloadPalletSpace: 0,
        });
      }
    }
  }, [filteredRowsData]);

  const getBackColor = (percentage: number): string => {
    if (percentage) {
      if (percentage > 0 && percentage < 50) {
        return '#f07267';
      } else if (percentage >= 50 && percentage < 80) {
        return '#eda528';
      } else {
        return '#12de63';
      }
    } else {
      return '#ffffff';
    }
  };

  const handleTFTDownloadClick = React.useCallback(
    (extraData: { existingFiles: DocumentFile[] }) => {
      if (extraData?.existingFiles && extraData.existingFiles.length > 0) {
        const myFile = extraData.existingFiles[0];
        httpClient
          .downloadFile(
            {
              path: myFile.path,
            },
            'blob',
          )
          .then((res) => {
            //convert Blob to File and download
            const imageFile: any = res.data;
            imageFile.name = `${myFile.originalname}`;
            imageFile.originalName = `${myFile.originalname}`;
            imageFile.lastModifiedDate = new Date();

            const url = window.URL.createObjectURL(imageFile);
            const anchor = document.createElement('a');
            anchor.setAttribute('href', url);
            anchor.setAttribute('download', `${myFile.originalname}`);
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
      }
    },
    [],
  );

  const getChipStatus = (row: any): string => {
    if (row.isNonArrivalMarked) {
      return t('attributes.cancelledStatus');
    }

    if (row.load && row.unload === false) {
      if (
        (((row.loadCar === 0 || row.loadCar != null) && row.loadCar !== '') ||
          ((row.loadTrailer === 0 || row.loadTrailer != null) &&
            row.loadTrailer !== '')) &&
        row.actualDepartureTime &&
        row.actualDepartureTime !== '' &&
        (row.loadCages === 0 || row.loadCages != null) &&
        row.loadCages !== ''
      ) {
        return t('attributes.registeredStatus');
      } else if (
        row.loadCar == null &&
        row.loadTrailer == null &&
        (row.actualDepartureTime == null || row.actualDepartureTime === '') &&
        row.loadCages == null
      ) {
        return t('attributes.unregisteredStatus');
      } else {
        return t('attributes.partialRegisteredStatus');
      }
    } else if (row.unload && row.load === false) {
      if (
        (((row.unloadCar === 0 || row.unloadCar != null) &&
          row.unloadCar !== '') ||
          ((row.unloadTrailer === 0 || row.unloadTrailer != null) &&
            row.unloadTrailer !== '')) &&
        row.actualArrivalTime &&
        row.actualArrivalTime !== '' &&
        (row.unloadCages === 0 || row.unloadCages != null) &&
        row.unloadCages !== ''
      ) {
        return t('attributes.registeredStatus');
      } else if (
        row.unloadCar == null &&
        row.unloadTrailer == null &&
        (row.actualArrivalTime == null || row.actualArrivalTime === '') &&
        row.unloadCages == null
      ) {
        return t('attributes.unregisteredStatus');
      } else {
        return t('attributes.partialRegisteredStatus');
      }
    } else if (row.load && row.unload) {
      if (
        (((row.loadCar === 0 || row.loadCar != null) && row.loadCar !== '') ||
          ((row.loadTrailer === 0 || row.loadTrailer != null) &&
            row.loadTrailer !== '')) &&
        (((row.unloadCar === 0 || row.unloadCar != null) &&
          row.unloadCar !== '') ||
          ((row.unloadTrailer === 0 || row.unloadTrailer != null) &&
            row.unloadTrailer !== '')) &&
        row.actualDepartureTime &&
        row.actualDepartureTime !== '' &&
        row.actualArrivalTime &&
        row.actualArrivalTime !== '' &&
        (row.loadCages === 0 || row.loadCages != null) &&
        row.loadCages !== '' &&
        (row.unloadCages === 0 || row.unloadCages != null) &&
        row.unloadCages !== ''
      ) {
        return t('attributes.registeredStatus');
      } else if (
        row.loadCar == null &&
        row.loadTrailer == null &&
        row.unloadCar == null &&
        row.unloadTrailer == null &&
        (row.actualDepartureTime == null || row.actualDepartureTime === '') &&
        (row.actualArrivalTime == null || row.actualArrivalTime === '') &&
        row.unloadCages == null &&
        row.loadCages == null
      ) {
        return t('attributes.unregisteredStatus');
      } else {
        return t('attributes.partialRegisteredStatus');
      }
    }
    return 'N/A';
  };

  const handleCellEditCommit = React.useCallback(
    async ({ id, field, value }: GridCellEditCommitParams) => {
      const row = rows.find((x: { id: string }) => x.id == id);

      if (row == null) {
        return;
      }

      await truckFillAndTimeMutation({
        variables: {
          truckFillAndTimeInput: {
            id: row.legId,
            terminalId: -1, //for NO terminal (subscription)
            carRegistrationNumber:
              field === 'carRegistrationNumber' ? (value as string) : undefined,
            trailerRegistrationNumber:
              field === 'trailerRegistrationNumber'
                ? (value as string)
                : undefined,
          },
        },
      }).catch((err) => {
        console.error('@TFT RO err=', err.message);
      });
    },
    [rows],
  );

  return (
    <>
      <Grid container item xs={12} style={{ marginTop: 10, marginBottom: 5 }}>
        <Grid item xs={6} alignContent="flex-start">
          <Typography style={{ fontWeight: 'bolder', display: 'inline-block' }}>
            {`LOAD  >> Total PAL: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalObj.totalLoadPallet}
            </Typography>
            {` | Total PPL: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalObj.totalLoadPalletSpace}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={6} alignContent="flex-start">
          <Typography style={{ fontWeight: 'bolder', display: 'inline-block' }}>
            {`UNLOAD >> Total PAL: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalObj.totalUnloadPallet}
            </Typography>
            {` | Total PPL: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalObj.totalUnloadPalletSpace}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Box style={{ height: dataGrid.length > 0 ? height : 300 }}>
        <UserConfiguredDataGridPro
          tableName={TABLE_NAMES.TFTReadOnly}
          apiRef={apiRef}
          columnBuffer={10}
          pinnedColumns={{ left: ['routeId', 'loadType'] }}
          componentsProps={{
            columnMenu: {
              background: 'red',
              counter: rows.length,
            },
          }}
          isCellEditable={() => {
            return true;
          }}
          onCellEditCommit={handleCellEditCommit}
          disableSelectionOnClick
          sortModel={sortModel}
          filterModel={filterModel}
          onSortModelChange={(model) => setSortModel(model)}
          onFilterModelChange={(model) => setFilterModel(model)}
          onStateChange={(state) => {
            const visibleRows = state.filter.visibleRowsLookup;
            setFilteredRowsData(visibleRows);
          }}
          columns={[
            {
              field: 'id',
              headerName: 'id',
              disableColumnMenu: true,
            },
            {
              field: 'terminalName',
              headerName: 'Reporting Terminal',
              minWidth: 100,
            },
            {
              field: 'viewRoute',
              headerName: 'View Route',
              minWidth: 75,
              type: 'actions',
              sortable: false,
              renderCell: (params: GridCellParams) => {
                return (
                  <Tooltip
                    title={`${t('actions.viewItem', {
                      item: t('resource.route.capitalized'),
                    })}`}
                  >
                    <Link
                      variant="body2"
                      href={
                        params.row.routeType === RouteType.Fixed
                          ? `/traffic/dispatched-routes/${params.row.routeIdNum}`
                          : `/traffic/sporadic-routes/${params.row.routeIdNum}`
                      }
                      target={'_blank'}
                    >
                      <PageviewIcon color="action" />
                    </Link>
                  </Tooltip>
                );
              },
            },
            {
              headerName: 'Status',
              field: 'status',
              width: 140,
              sortable: false,
              renderCell: (params: GridCellParams) => {
                const { row } = params;
                const chipText = getChipStatus(row);
                if (
                  chipText.toString() ===
                  t('attributes.registeredStatus').toString()
                ) {
                  return (
                    <RegisteredChip
                      label={chipText}
                      variant="outlined"
                      color="primary"
                      key={row.id}
                      icon={<DoneIcon />}
                    />
                  );
                } else if (
                  chipText.toString() ===
                  t('attributes.unregisteredStatus').toString()
                ) {
                  return (
                    <UnregisteredChip
                      label={chipText}
                      variant="outlined"
                      color="primary"
                      key={row.id}
                      icon={<InfoIcon />}
                    />
                  );
                } else if (
                  chipText.toString() ===
                  t('attributes.partialRegisteredStatus').toString()
                ) {
                  return (
                    <PartialRegisteredChip
                      label={chipText}
                      variant="outlined"
                      color="primary"
                      key={row.id}
                      icon={<RefreshIcon />}
                    />
                  );
                } else if (
                  chipText.toString() ===
                  t('attributes.cancelledStatus').toString()
                ) {
                  return (
                    <CancelledChip
                      label={chipText}
                      variant="outlined"
                      color="primary"
                      key={row.id}
                      icon={<CancelledIcon />}
                    />
                  );
                } else {
                  return null;
                }
              },
            },
            {
              headerName: 'Non-arrival',
              field: 'nonArrival',
              minWidth: 100,
              type: 'actions',
              align: 'center',
              sortable: false,
              renderCell: (params: GridCellParams) => {
                const { row } = params;
                const { isProductionDayToday, isNonArrivalMarked } = row;
                if (isProductionDayToday === true) {
                  return (
                    <Checkbox
                      style={{ padding: 0, color: '#fc3926' }}
                      checked={isNonArrivalMarked}
                      size="small"
                      disabled={DISABLED}
                    />
                  );
                } else {
                  return null;
                }
              },
            },
            {
              headerName: 'Route ID',
              field: 'routeId',
              minWidth: 100,
              pinnable: true,
              disableColumnMenu: true,
            },
            {
              headerName: 'Route Type',
              field: 'type',
              minWidth: 100,
              valueGetter({ row }) {
                if (row.isCreatedFromTerminal) {
                  return 'Terminal';
                }
                return row.type;
              },
            },
            {
              headerName: 'Subcontractor',
              field: 'subcontractorName',
              minWidth: 100,
              align: 'left',
              renderCell: (params: GridCellParams) => {
                const { row } = params;
                const { subcontractorId } = row;
                if (!subcontractorId || subcontractorId == 0) {
                  return null;
                }
                return (
                  <Link
                    variant="body2"
                    href={`/traffic/cards/subcontractors/${subcontractorId}`}
                    target={'_blank'}
                    underline={'hover'}
                    color={'inherit'}
                  >
                    {row.subcontractorName}
                  </Link>
                );
              },
            },
            {
              headerName: 'Capacity',
              field: 'capacity',
              minWidth: 100,
              valueFormatter({ value }) {
                if (value == null) {
                  return '';
                }
                return value;
              },
            },
            {
              headerName: 'Actual Car Capacity',
              field: 'actualCarCapacity',
              minWidth: 100,
              editable: true,
              type: 'number',
              renderCell(params) {
                return (
                  <EditableCell
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Actual Trailer Capacity',
              field: 'actualTrailerCapacity',
              minWidth: 100,
              editable: true,
              type: 'number',
              renderCell(params) {
                return (
                  <EditableCell
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Total pallets',
              field: 'sumOfPallets',
              minWidth: 100,
              valueFormatter({ value }) {
                if (value == null || value == undefined || value === 0) {
                  return '';
                }
                return value;
              },
            },
            {
              field: 'isContainDangerousGoods',
              headerName: 'Dangerous Goods',
              minWidth: 100,
              type: 'actions',
              renderCell: (params: GridCellParams) => {
                if (params.row.isContainDangerousGoods) {
                  return (
                    <Tooltip
                      title={`${t('actions.viewItem', {
                        item: t('resource.route.capitalized'),
                      })}`}
                    >
                      <Link
                        variant="body2"
                        href={
                          params.row.routeType === RouteType.Fixed
                            ? `/traffic/dispatched-routes/${params.row.id}`
                            : `/traffic/sporadic-routes/${params.row.id}`
                        }
                        target={'_blank'}
                      >
                        <DangerIcon color="action" />
                      </Link>
                    </Tooltip>
                  );
                } else {
                  return <></>;
                }
              },
            },
            {
              headerName: 'Departure Terminal',
              field: 'departureTerminal',
              width: 100,
            },
            {
              headerName: 'Arriving Terminal',
              field: 'arrivingTerminal',
              width: 100,
            },
            {
              headerName: 'Departure City',
              field: 'departureCity',
              minWidth: 100,
            },
            {
              headerName: 'Arrival City',
              field: 'arrivalCity',
              minWidth: 100,
            },
            {
              headerName: 'Departure Country',

              field: 'departureCountry',
              minWidth: 100,
              valueFormatter({ value }) {
                if (typeof value !== 'string') {
                  return '';
                }
                return value.toUpperCase();
              },
            },
            {
              headerName: 'Arrival Country',

              field: 'arrivalCountry',
              minWidth: 100,
              valueFormatter({ value }) {
                if (typeof value !== 'string') {
                  return '';
                }
                return value.toUpperCase();
              },
            },
            {
              headerName: 'Transportation Date',
              field: 'transportationDate',
              minWidth: 110,
              type: 'date',
            },
            {
              headerName: 'Arrival time',
              field: 'arrivalTime',
              minWidth: 100,
              valueFormatter(params) {
                const { value } = params;
                if (typeof value === 'string') {
                  return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
                }
                return '';
              },
            },
            {
              headerName: 'Arrival Status',
              field: 'truckStatusBasedOnTime',
              minWidth: 110,
            },
            {
              headerName: 'Departure time',
              field: 'departureTime',
              minWidth: 100,
              valueFormatter(params) {
                const { value } = params;
                if (typeof value === 'string') {
                  return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
                }
                return '';
              },
            },
            {
              headerName: 'AAD',
              field: 'actualArrivalDate',
              minWidth: 120,
              type: 'date',
              description: 'Actual Arrival Date',
            },
            {
              headerName: 'AAT',
              field: 'actualArrivalTime',
              minWidth: 100,
              type: 'dateTime',
              description: 'Actual Arrival Time',
              renderCell(params) {
                const { value } = params;

                let time = '';
                if (typeof value === 'string') {
                  if (value.match(/\d{2}:\d{2}:\d{2}/)) {
                    time = format(
                      parse(value, 'HH:mm:ss', new Date()),
                      'HH:mm',
                    );
                  } else if (value.match(/\d{2}:\d{2}/)) {
                    time = format(parse(value, 'HH:mm', new Date()), 'HH:mm');
                  }
                }

                return (
                  <EditableCell
                    highlightField={true}
                    className={classes.editableCell}
                    apiRef={apiRef}
                    editable={!DISABLED}
                    {...params}
                    value={time}
                  />
                );
              },
            },
            {
              headerName: 'ADD',
              field: 'actualDepartureDate',
              minWidth: 120,
              type: 'date',
              description: 'Actual Departure Date',
            },
            {
              headerName: 'ADT ',
              field: 'actualDepartureTime',
              minWidth: 100,
              type: 'dateTime',
              description: 'Actual Departure Time',
              renderCell(params) {
                const { value } = params;
                if (typeof value === 'string') {
                  if (value.match(/\d{2}:\d{2}:\d{2}/)) {
                    return format(
                      parse(value, 'HH:mm:ss', new Date()),
                      'HH:mm',
                    );
                  } else if (value.match(/\d{2}:\d{2}/)) {
                    return format(parse(value, 'HH:mm', new Date()), 'HH:mm');
                  }
                } else '';
              },
            },
            {
              headerName: 'Load type',
              field: 'loadType',
              minWidth: 100,
              pinnable: true,
              disableColumnMenu: true,
            },
            {
              headerName: 'Car Carrier ID',
              field: 'carCarrierId',
              minWidth: 110,
            },
            {
              headerName: 'Car Carrier QR',
              field: 'carCarrierIdQR',
              minWidth: 100,
              type: 'actions',
              sortable: false,
              renderCell: (params: GridCellParams) => {
                const { carCarrierId, routeId, routeId2 } = params.row;
                return carCarrierId ? (
                  <QRModal
                    id={carCarrierId}
                    routeName={routeId}
                    routeId={routeId2}
                    Icon={<ToysIcon />}
                  />
                ) : null;
              },
            },
            {
              headerName: 'Trailer Carrier ID',
              field: 'trailerCarrierId',
              minWidth: 110,
            },
            {
              headerName: 'Trailer Carrier QR',
              field: 'trailerCarrierIdQR',
              minWidth: 100,
              type: 'actions',
              sortable: false,
              renderCell: (params: GridCellParams) => {
                const { trailerCarrierId, routeId, routeId2 } = params.row;
                return trailerCarrierId ? (
                  <QRModal
                    id={trailerCarrierId}
                    routeName={routeId}
                    routeId={routeId2}
                    Icon={<LocalShippingIcon />}
                  />
                ) : null;
              },
            },

            {
              headerName: 'Terminal time info',
              field: 'timeObj',
              editable: true,
              filterable: false,
              renderCell(params) {
                return (
                  <TerminalTimeInfo
                    timeObj={params.row.timeObj}
                    routeId={params.row.routeId}
                  />
                );
              },
            },
            {
              headerName: 'Car pallets',
              field: 'carPallets',
              minWidth: 100,
              editable: false,
              type: 'number',

              renderCell(params) {
                return (
                  <EditableCell
                    highlightField={true}
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Trailer pallets',
              field: 'trailerPallets',
              minWidth: 100,
              editable: false,
              type: 'number',

              renderCell(params) {
                return (
                  <EditableCell
                    highlightField={true}
                    apiRef={apiRef}
                    className={classes.editableCell}
                    editable={!DISABLED}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Pallets factor',
              field: 'palletsFactor',
              minWidth: 120,

              renderCell(params) {
                if (
                  (!params.row.load && !params.row.unload) ||
                  (params.row.load && params.row.unload)
                ) {
                  return null;
                } else if (params.row.palletsFactor === '') {
                  return (
                    <Box width={'100%'}>
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: '#ffffff',
                          border: '1px solid #000',
                          color: '#ffffff',
                        }}
                      >
                        <Typography align="left">
                          <label>{'0'}</label>
                        </Typography>
                      </div>
                    </Box>
                  );
                } else if (params.row.load) {
                  //in this else even if we replace loadLoadFactor with palletsFactor it is OK
                  return (
                    <Box width={'100%'}>
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: '#ffffff',
                          border: '1px solid #000',
                        }}
                      >
                        <div
                          style={{
                            width: `${
                              params.row.loadLoadFactor > 100
                                ? 100
                                : params.row.loadLoadFactor
                            }%`,
                            backgroundColor: `${getBackColor(
                              params.row.loadLoadFactor,
                            )}`,
                            paddingLeft: 4,
                          }}
                        >
                          <Typography align="left">
                            <label>{params.row.loadSumPPI}</label>
                            <label>{' | '}</label>
                            <label>
                              <strong>{params.row.loadLoadFactor}%</strong>
                            </label>
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  );
                } else {
                  return (
                    <Box width={'100%'}>
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: '#ffffff',
                          border: '1px solid #000',
                        }}
                      >
                        <div
                          style={{
                            width: `${
                              params.row.unloadLoadFactor > 100
                                ? 100
                                : params.row.unloadLoadFactor
                            }%`,
                            backgroundColor: `${getBackColor(
                              params.row.unloadLoadFactor,
                            )}`,
                            paddingLeft: 4,
                          }}
                        >
                          <Typography align="left">
                            <label>{params.row.unloadSumPPI}</label>
                            <label>{' | '}</label>
                            <label>
                              <strong>{params.row.unloadLoadFactor}%</strong>
                            </label>
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  );
                }
              },
            },
            {
              headerName: 'Car PPL',
              field: 'calPPL',
              minWidth: 100,
              editable: false,
              type: 'number',

              renderCell(params) {
                return (
                  <EditableCell
                    highlightField={true}
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Trailer PPL',
              field: 'trailerPPL',
              minWidth: 100,
              editable: true,
              type: 'number',

              renderCell(params) {
                return (
                  <EditableCell
                    highlightField={true}
                    apiRef={apiRef}
                    className={classes.editableCell}
                    editable={!DISABLED}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'PPL factor',
              field: 'PPLFactor',
              minWidth: 170,
              renderCell(params) {
                if (
                  (!params.row.load && !params.row.unload) ||
                  (params.row.load && params.row.unload)
                ) {
                  return null;
                } else if (params.row.PPLFactor === '') {
                  return (
                    <Box width={'100%'}>
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: '#ffffff',
                          border: '1px solid #000',
                          color: '#ffffff',
                        }}
                      >
                        <Typography align="left">
                          <label>{'0'}</label>
                        </Typography>
                      </div>
                    </Box>
                  );
                } else if (params.row.load) {
                  return (
                    <Box width={'100%'}>
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: '#ffffff',
                          border: '1px solid #000',
                        }}
                      >
                        <div
                          style={{
                            width: `${
                              params.row.PPLFactor > 100
                                ? 100
                                : params.row.PPLFactor
                            }%`,
                            backgroundColor: `${getBackColor(
                              params.row.PPLFactor,
                            )}`,
                            paddingLeft: 4,
                          }}
                        >
                          <Typography align="left">
                            <label>{params.row.loadPPLSum}</label>
                            <label>{' | '}</label>
                            <label>
                              <strong>{params.row.PPLFactor}%</strong>
                            </label>
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  );
                } else {
                  return (
                    <Box width={'100%'}>
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: '#ffffff',
                          border: '1px solid #000',
                        }}
                      >
                        <div
                          style={{
                            width: `${
                              params.row.PPLFactor > 100
                                ? 100
                                : params.row.PPLFactor
                            }%`,
                            backgroundColor: `${getBackColor(
                              params.row.PPLFactor,
                            )}`,
                            paddingLeft: 4,
                          }}
                        >
                          <Typography align="left">
                            <label>{params.row.unloadPPLSum}</label>
                            <label>{' | '}</label>
                            <label>
                              <strong>{params.row.PPLFactor}%</strong>
                            </label>
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  );
                }
              },
            },
            {
              headerName: 'Cages',
              field: 'cages',
              minWidth: 110,
              editable: true,
              type: 'number',
              renderCell(params) {
                return (
                  <EditableCell
                    highlightField={true}
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              field: 'downloadImage',
              headerName: 'Download File',
              minWidth: 75,
              type: 'actions',
              renderCell: (params: GridCellParams) => {
                if (params.row.files && params.row.files.length > 0) {
                  return (
                    <Tooltip title={`${t('actions.downloadImage')}`}>
                      <IconButton
                        onClick={() => {
                          handleTFTDownloadClick({
                            existingFiles: params.row.files,
                          });
                        }}
                      >
                        <DownloadFileIcon />
                      </IconButton>
                    </Tooltip>
                  );
                } else {
                  return null;
                }
              },
            },
            {
              headerName: 'Note from terminal',
              field: 'terminalNote',
              minWidth: 100,
              editable: true,
              renderCell(params) {
                return (
                  <EditableCell
                    apiRef={apiRef}
                    className={classes.editableCell}
                    editable={!DISABLED}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Leg note',
              field: 'note',
              minWidth: 100,
            },
            {
              headerName: 'Car Gate Number',
              field: 'carGateNumber',
              minWidth: 100,
              editable: true,
              renderCell(params) {
                return (
                  <EditableCell
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Trailer Gate Number',
              field: 'trailerGateNumber',
              minWidth: 100,
              editable: true,
              renderCell(params) {
                return (
                  <EditableCell
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Car Registration Number',
              field: 'carRegistrationNumber',
              minWidth: 100,
              editable: true,
              renderCell(params) {
                return (
                  <EditableCell
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Trailer Registration Number',
              field: 'trailerRegistrationNumber',
              minWidth: 100,
              editable: true,
              renderCell(params) {
                return (
                  <EditableCell
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Terminal Nickname',
              field: 'terminalNickname',
              minWidth: 100,
              editable: true,

              renderCell(params) {
                return (
                  <EditableCell
                    apiRef={apiRef}
                    editable={!DISABLED}
                    className={classes.editableCell}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: ' Seal Number',
              field: 'sealNumber',
              minWidth: 110,
              editable: true,
              renderCell(params) {
                if (
                  params.row.load === true ||
                  (params.row.unload === true &&
                    params.row.isSealBroken === true)
                ) {
                  return (
                    <EditableCell
                      apiRef={apiRef}
                      editable={!DISABLED}
                      className={classes.editableCell}
                      {...params}
                    />
                  );
                } else {
                  return null;
                }
              },
            },
            {
              headerName: 'Is Seal Broken',
              field: 'sealBroken',
              type: 'actions',
              align: 'center',
              renderCell: (params: GridCellParams) => {
                const { row } = params;
                const { unload, isSealBroken, load } = row;
                if (unload === true && load === false) {
                  return (
                    <Checkbox
                      style={{ padding: 0, color: '#fc3926' }}
                      checked={isSealBroken}
                      size="small"
                      disabled={DISABLED}
                    />
                  );
                } else {
                  return null;
                }
              },
            },
            {
              headerName: 'Production Group',
              field: 'productionGroup',
              minWidth: 100,
              editable: true,
              valueParser: (value) => {
                return value
                  ? value.toString().replaceAll(' ', '').toUpperCase()
                  : '';
              },
              renderCell(params) {
                return (
                  <EditableCell
                    apiRef={apiRef}
                    className={classes.editableCell}
                    editable={!DISABLED}
                    {...params}
                  />
                );
              },
            },
            {
              headerName: 'Updated By',
              field: 'updatedBy',
              minWidth: 100,
            },
            {
              headerName: 'Updated at',
              field: 'updatedAt',
              type: 'dateTime',
              minWidth: 100,
              valueGetter({ value }) {
                return new Date(value as string);
              },
              valueFormatter({ value }) {
                return format(value as Date, 'HH:mm:ss - dd-MM-yyyy');
              },
            },
          ]}
          rows={dataGrid}
          onGetTFTExcelClicked={() => onGetTFTExcelClicked()}
          checkboxSelection
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0
              ? classes.muiEven
              : classes.muiOdd
          }
        />
      </Box>
    </>
  );
}

function buildRows(legs: ListIncomingRoutesProps['legs']) {
  return (
    legs?.map((routeLeg) => {
      const { route } = routeLeg;
      const leg = routeLeg;

      const terminalName = leg.location ? leg.location?.name : '';
      const { id, routeId, capacity, type } = route;
      const carActCapacity = leg.carDetails
        ? leg.carDetails.actualCapacity
        : leg.actualCapacity
        ? leg.actualCapacity
        : 0;
      const trailerActCapacity = leg.trailerDetails
        ? leg.trailerDetails.actualCapacity
        : 0;
      const routeCapacity =
        carActCapacity || trailerActCapacity
          ? (carActCapacity || 0) + (trailerActCapacity || 0)
          : capacity;
      const isProductionDayToday =
        leg.transportationDate &&
        leg.transportationDate === format(new Date(), DATE_FORMAT);
      const firstLegLocation = route.legs.find((leg) => leg.position === 0);
      const lastLegLocation = route.legs.find(
        (leg) => leg.position === route.legs.length - 1,
      );
      const departureCity = firstLegLocation?.location?.city;
      const departureCountry =
        firstLegLocation?.location && firstLegLocation?.location.country
          ? COUNTRIES_MAP[
              firstLegLocation?.location.country as keyof typeof COUNTRIES_MAP
            ].toLocaleUpperCase()
          : '';
      const arrivalCity = lastLegLocation?.location?.city;
      const arrivalCountry =
        lastLegLocation?.location && lastLegLocation?.location.country
          ? COUNTRIES_MAP[
              lastLegLocation?.location.country as keyof typeof COUNTRIES_MAP
            ].toLocaleUpperCase()
          : '';
      const departureTerminal =
        firstLegLocation?.location && firstLegLocation?.location.name
          ? firstLegLocation?.location.name
          : '';
      const arrivingTerminal =
        lastLegLocation?.location && lastLegLocation.location.name
          ? lastLegLocation.location.name
          : '';
      const subcontractorId = route.subcontractor.id;
      const subcontractorName = route.subcontractor.name;
      const loadSumPPI = (leg.loadCar || 0) + (leg.loadTrailer || 0);
      const unloadSumPPI = (leg.unloadCar || 0) + (leg.unloadTrailer || 0);

      const loadLoadFactor =
        routeCapacity == null || loadSumPPI === 0 || routeCapacity === 0
          ? ''
          : Math.floor((loadSumPPI / routeCapacity) * 100);

      const unloadLoadFactor =
        routeCapacity == null || unloadSumPPI === 0 || routeCapacity === 0
          ? ''
          : Math.floor((unloadSumPPI / routeCapacity) * 100);

      //for PPL
      const loadPPLSum = (leg.loadCarPPL || 0) + (leg.loadTrailerPPL || 0);
      const unloadPPLSum =
        (leg.unloadCarPPL || 0) + (leg.unloadTrailerPPL || 0);

      const loadPPLFactor =
        routeCapacity == null || loadPPLSum === 0 || routeCapacity === 0
          ? ''
          : Math.floor((loadPPLSum / routeCapacity) * 100);

      const unloadPPLFactor =
        routeCapacity == null || unloadPPLSum === 0 || routeCapacity === 0
          ? ''
          : Math.floor((unloadPPLSum / routeCapacity) * 100);

      let sumOfPallets = 0;
      let isContainDangerousGoods = false;
      if (leg.load && leg.loadingListItems && leg.loadingListItems.length > 0) {
        sumOfPallets = _.sumBy(leg.loadingListItems, 'pallets');
        isContainDangerousGoods =
          leg.loadingListItems.findIndex((ll) => ll.isDangerous === true) !==
          -1;
      }

      return {
        id: `${id}-${leg.arrivalTime}`,
        legId: leg.id,
        routeId: routeId,
        routeIdNum: id,
        terminalName,
        type,
        capacity,
        carGateNumber: leg.carDetails?.gateNumber
          ? leg.carDetails?.gateNumber
          : leg.gateNumber
          ? leg.gateNumber
          : '',
        trailerGateNumber: leg.trailerDetails?.gateNumber
          ? leg.trailerDetails?.gateNumber
          : '',
        trailerRegistrationNumber: leg.trailerDetails?.registrationNumber
          ? leg.trailerDetails?.registrationNumber
          : leg.trailerRegistrationNumber,
        carRegistrationNumber: leg.carDetails?.registrationNumber
          ? leg.carDetails?.registrationNumber
          : leg.carRegistrationNumber ?? '',
        terminalNickname: leg.terminalNickname,
        actualCapacity: leg.actualCapacity,
        load: leg.load,
        unload: leg.unload,
        departureTerminal,
        arrivingTerminal,
        departureCity,
        arrivalCity,
        departureCountry,
        arrivalCountry,
        arrivalTime: leg.arrivalTime,
        departureTime: timeOrNull(leg.departureTime),
        subcontractorId,
        subcontractorName,
        actualArrivalTime: leg.actualArrivalTime ?? '',
        truckStatusBasedOnTime: leg.arrivalStatus ?? '',
        actualDepartureTime: leg.actualDepartureTime ?? '',
        loadCar: leg.loadCar,
        loadCages: leg.loadCages,
        loadTrailer: leg.loadTrailer,
        loadSumPPI,
        loadLoadFactor,
        loadPPLSum,
        loadPPLFactor,
        loadCarPPL: leg.loadCarPPL,
        unloadCarPPL: leg.unloadCarPPL,
        loadTrailerPPL: leg.loadTrailerPPL,
        unloadTrailerPPL: leg.unloadTrailerPPL,
        unloadCar: leg.unloadCar,
        unloadCages: leg.unloadCages,
        unloadTrailer: leg.unloadTrailer,
        unloadSumPPI,
        unloadLoadFactor,
        unloadPPLSum,
        unloadPPLFactor,
        carCarrierId: route.carCarrierId ?? '',
        trailerCarrierId: route.trailerCarrierId ?? '',
        note: leg.note,
        tourId: route?.tourRoute?.tourId,
        isCreatedFromTerminal: route?.isCreatedFromTerminal,
        isCreatedFromPlanned: route?.isCreatedFromPlanned,
        terminalNote: leg.terminalNote,
        timeObj: leg.timeObj,
        updatedBy: leg.lastUpdatedBy,
        updatedAt: leg.updatedAt,
        sumOfPallets,
        isContainDangerousGoods,
        productionDate: leg.productionDate,
        transportationDate: leg.transportationDate,
        isProductionDayToday: isProductionDayToday,
        isNonArrivalMarked: leg.isNonArrivalMarked ?? false,
        nonArrivalMarkedFrom: leg.nonArrivalMarkedFrom ?? '',
        sealNumber: leg.sealNumber ?? '',
        isSealBroken: leg.isSealBroken ?? false,
        files: leg.files,
        productionGroup: leg.productionGroup,
        loadType:
          leg.load && leg.unload
            ? 'Load/Unload'
            : leg.load
            ? 'Load'
            : leg.unload
            ? 'Unload'
            : 'Checkpoint',
        actualDepartureDate: leg.actualDepartureDate ?? '',
        actualArrivalDate: leg.actualArrivalDate ?? '',
        carPallets:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadCar
            : leg.unload
            ? leg.unloadCar
            : undefined,
        trailerPallets:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadTrailer
            : leg.unload
            ? leg.unloadTrailer
            : undefined,
        palletsFactor:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? loadLoadFactor
            : leg.unload
            ? unloadLoadFactor
            : undefined,
        calPPL:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadCarPPL
            : leg.unload
            ? leg.unloadCarPPL
            : undefined,
        trailerPPL:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadTrailerPPL
            : leg.unload
            ? leg.unloadTrailerPPL
            : undefined,
        PPLFactor:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? loadPPLFactor
            : leg.unload
            ? unloadPPLFactor
            : undefined,
        cages:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadCages
            : leg.unload
            ? leg.unloadCages
            : undefined,
        isLoadingInCar: leg.isLoadingInCar,
        isLoadingInTrailer: leg.isLoadingInTrailer,
        actualCarCapacity: carActCapacity ?? '',
        actualTrailerCapacity: trailerActCapacity ?? '',
      };
    }) || []
  );
}
