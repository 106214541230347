import { v4 as uuidv4 } from 'uuid';
import { LoadingListItemInterface } from '../../components/LoadingList';
import {
  FuelType,
  LocationEntity,
  Maybe,
  TourRouteLegCarTask,
  TourRouteLegLoadingListItemTemplate,
  TourRouteLegTemplate,
  TourRouteLegTrailerTask,
} from '../../generated/graphql';

type LegType = { __typename?: 'TourRouteLegTemplate' } & Pick<
  TourRouteLegTemplate,
  | 'id'
  | 'fuel'
  | 'locationId'
  | 'gateNumber'
  | 'carRegistrationNumber'
  | 'trailerRegistrationNumber'
  | 'routeDriverName'
  | 'routeDriverPhoneNumber'
  | 'note'
  | 'arrivalTime'
  | 'departureTime'
  | 'position'
  | 'load'
  | 'unload'
  | 'transportationDateOffset'
  | 'isNewLegVariant'
  | 'isLoadingInCar'
  | 'isLoadingInTrailer'
> & {
    location: { __typename?: 'LocationEntity' } & Pick<
      LocationEntity,
      'name' | 'city' | 'country' | 'postalCode' | 'address' | 'deletedBy'
    >;
    carDetails?: Maybe<
      { __typename?: 'TourRouteLegCarTask' } & Pick<
        TourRouteLegCarTask,
        'id' | 'actualCapacity' | 'registrationNumber' | 'gateNumber'
      > & {
          loadingListItems: Array<
            { __typename?: 'TourRouteLegLoadingListItemTemplate' } & Pick<
              TourRouteLegLoadingListItemTemplate,
              | 'id'
              | 'locationId'
              | 'packages'
              | 'pallets'
              | 'palletSpace'
              | 'weight'
              | 'checked'
              | 'isDangerous'
              | 'classification'
              | 'unNumber'
              | 'isLimitedQty'
              | 'note'
              | 'lastUpdatedBy'
              | 'createdBy'
              | 'updatedAt'
            >
          >;
        }
    >;
    trailerDetails?: Maybe<
      { __typename?: 'TourRouteLegTrailerTask' } & Pick<
        TourRouteLegTrailerTask,
        'id' | 'actualCapacity' | 'registrationNumber' | 'gateNumber'
      > & {
          loadingListItems: Array<
            { __typename?: 'TourRouteLegLoadingListItemTemplate' } & Pick<
              TourRouteLegLoadingListItemTemplate,
              | 'id'
              | 'locationId'
              | 'packages'
              | 'pallets'
              | 'palletSpace'
              | 'weight'
              | 'checked'
              | 'isDangerous'
              | 'classification'
              | 'unNumber'
              | 'isLimitedQty'
              | 'note'
              | 'lastUpdatedBy'
              | 'createdBy'
              | 'updatedAt'
            >
          >;
        }
    >;
    loadingListItems: Array<
      { __typename?: 'TourRouteLegLoadingListItemTemplate' } & Pick<
        TourRouteLegLoadingListItemTemplate,
        | 'id'
        | 'locationId'
        | 'packages'
        | 'pallets'
        | 'palletSpace'
        | 'weight'
        | 'note'
        | 'checked'
        | 'isDangerous'
        | 'classification'
        | 'unNumber'
        | 'isLimitedQty'
        | 'lastUpdatedBy'
        | 'createdBy'
        | 'updatedAt'
      >
    >;
  };

type CopyLegType = {
  id?: number;
  key: string;
  fuel: FuelType;
  gateNumber?: string;
  routeDriverName?: string;
  routeDriverPhoneNumber?: string;
  carRegistrationNumber?: string;
  trailerRegistrationNumber?: string;
  load: boolean;
  unload: boolean;
  note?: string;
  arrivalTime?: Maybe<string>;
  departureTime?: Maybe<string>;
  position: number;
  locationId?: number;
  loadingListItems: LoadingListItemInterface[];
  productionDate: string;
  transportationDate: string;
  transportationDateOffset: number;
  transportDay?: number;
  isNewLegVariant: boolean;
  isLoadingInCar?: Maybe<boolean> | undefined;
  isLoadingInTrailer?: Maybe<boolean> | undefined;
  carDetails?: {
    id?: number | undefined;
    loadingListItems: LoadingListItemInterface[];
    actualCapacity?: Maybe<number> | undefined;
    registrationNumber?: Maybe<string> | undefined;
    gateNumber?: Maybe<string> | undefined;
  };
  trailerDetails?: {
    id?: number | undefined;
    loadingListItems: LoadingListItemInterface[];
    actualCapacity?: Maybe<number> | undefined;
    registrationNumber?: Maybe<string> | undefined;
    gateNumber?: Maybe<string> | undefined;
  };
};

export function newLeg(
  productionDate = '',
  copiedLegs?: Array<LegType>,
  driverInfoToCopy?: {
    routeDriverName: string;
    routeDriverPhoneNumber: string;
    carRegistrationNumber: string;
    trailerRegistrationNumber: string;
  },
  legsLength = 0,
): [
  {
    id?: number;
    fuel: FuelType;
    key: string;
    gateNumber?: string;
    route?: string;
    routeDriverName?: string;
    routeDriverPhoneNumber?: string;
    carRegistrationNumber?: string;
    trailerRegistrationNumber?: string;
    load: boolean;
    unload: boolean;
    note?: string;
    arrivalTime?: Maybe<string>;
    departureTime?: Maybe<string>;
    position: number;
    locationId?: number;
    loadingListItems: LoadingListItemInterface[];
    productionDate: string;
    transportationDate: string;
    transportationDateOffset: number;
    transportDay?: number;
    isLoadingInCar?: boolean;
    isLoadingInTrailer?: boolean;
    isNewLegVariant: boolean;
    carDetails?: {
      registrationNumber?: string;
      loadingListItems: LoadingListItemInterface[];
    };
    trailerDetails?: {
      registrationNumber?: string;
      loadingListItems: LoadingListItemInterface[];
    };
  },
] {
  if (copiedLegs?.length) {
    let resLegArray: [CopyLegType] | undefined = undefined;
    copiedLegs.forEach((copiedLeg) => {
      const resLeg: CopyLegType = {
        key: uuidv4(),
        arrivalTime: copiedLeg.arrivalTime ?? undefined,
        departureTime: copiedLeg.departureTime ?? undefined,
        locationId: copiedLeg.locationId ?? undefined,
        position: copiedLeg.position ?? 0,
        gateNumber: copiedLeg.gateNumber ?? '',
        routeDriverName: copiedLeg.routeDriverName ?? '',
        routeDriverPhoneNumber: copiedLeg.routeDriverPhoneNumber ?? '',
        carRegistrationNumber: copiedLeg.carRegistrationNumber ?? '',
        trailerRegistrationNumber: copiedLeg.trailerRegistrationNumber ?? '',
        fuel: copiedLeg.fuel,
        note: copiedLeg.note ?? undefined,
        load: copiedLeg.load ?? false,
        unload: copiedLeg.unload ?? false,
        loadingListItems: copiedLeg.loadingListItems ?? [],
        transportationDate: productionDate,
        productionDate,
        transportationDateOffset: copiedLeg.transportationDateOffset ?? 0,
        transportDay: 0,
        isNewLegVariant: copiedLeg.isNewLegVariant,
        isLoadingInCar: copiedLeg.isLoadingInCar,
        isLoadingInTrailer: copiedLeg.isLoadingInTrailer,
        carDetails: copiedLeg.carDetails ? copiedLeg.carDetails : undefined,
        trailerDetails: copiedLeg.trailerDetails
          ? copiedLeg.trailerDetails
          : undefined,
      };
      if (!resLegArray) {
        resLegArray = [resLeg];
      } else {
        resLegArray.push(resLeg);
      }
    });
    if (resLegArray) {
      return resLegArray;
    }
  }
  return [
    {
      key: uuidv4(),
      arrivalTime: undefined,
      departureTime: undefined,
      locationId: undefined,
      position: legsLength,
      note: undefined,
      gateNumber: '',
      routeDriverName:
        driverInfoToCopy != null ? driverInfoToCopy.routeDriverName : '',
      routeDriverPhoneNumber:
        driverInfoToCopy != null ? driverInfoToCopy.routeDriverPhoneNumber : '',
      carRegistrationNumber:
        driverInfoToCopy != null ? driverInfoToCopy.carRegistrationNumber : '',
      trailerRegistrationNumber:
        driverInfoToCopy != null
          ? driverInfoToCopy.trailerRegistrationNumber
          : '',
      carDetails: {
        registrationNumber:
          driverInfoToCopy != null
            ? driverInfoToCopy.carRegistrationNumber
            : '',
        loadingListItems: [],
      },
      trailerDetails: {
        registrationNumber:
          driverInfoToCopy != null
            ? driverInfoToCopy.trailerRegistrationNumber
            : '',
        loadingListItems: [],
      },
      load: false,
      unload: false,
      loadingListItems: [],
      transportationDate: productionDate,
      productionDate,
      transportationDateOffset: 0,
      fuel: FuelType.Diesel,
      transportDay: 0,
      isNewLegVariant: true,
      isLoadingInCar: false,
      isLoadingInTrailer: false,
    },
  ];
}

export function newLegAppend(
  transportationDate = '',
  legPosition = 0,
  driverInfoToCopy?: {
    routeDriverName: string;
    routeDriverPhoneNumber: string;
    carRegistrationNumber: string;
    trailerRegistrationNumber: string;
  },
): [
  {
    id?: number;
    key: string;
    gateNumber?: string;
    routeDriverName?: string;
    routeDriverPhoneNumber?: string;
    carRegistrationNumber?: string;
    trailerRegistrationNumber?: string;
    load: boolean;
    unload: boolean;
    note?: string;
    arrivalTime?: Maybe<string>;
    departureTime?: Maybe<string>;
    position: number;
    locationId?: number;
    loadingListItems: LoadingListItemInterface[];
    productionDate: string;
    transportationDate: string;
    transportationDateOffset: number;
    fuel: FuelType;
    isNewLegVariant: boolean;
    carDetails?: {
      registrationNumber?: string;
      loadingListItems: LoadingListItemInterface[];
    };
    trailerDetails?: {
      registrationNumber?: string;
      loadingListItems: LoadingListItemInterface[];
    };
  },
] {
  return [
    {
      key: uuidv4(),
      arrivalTime: undefined,
      departureTime: undefined,
      locationId: undefined,
      position: legPosition,
      note: undefined,
      gateNumber: '',
      routeDriverName:
        driverInfoToCopy != null ? driverInfoToCopy.routeDriverName : '',
      routeDriverPhoneNumber:
        driverInfoToCopy != null ? driverInfoToCopy.routeDriverPhoneNumber : '',
      carRegistrationNumber:
        driverInfoToCopy != null ? driverInfoToCopy.carRegistrationNumber : '',
      trailerRegistrationNumber:
        driverInfoToCopy != null
          ? driverInfoToCopy.trailerRegistrationNumber
          : '',
      carDetails: {
        registrationNumber:
          driverInfoToCopy != null
            ? driverInfoToCopy.carRegistrationNumber
            : '',
        loadingListItems: [],
      },
      trailerDetails: {
        registrationNumber:
          driverInfoToCopy != null
            ? driverInfoToCopy.trailerRegistrationNumber
            : '',
        loadingListItems: [],
      },
      load: false,
      unload: false,
      loadingListItems: [],
      transportationDate: transportationDate,
      productionDate: transportationDate,
      transportationDateOffset: 0,
      fuel: FuelType.Diesel,
      isNewLegVariant: true,
    },
  ];
}
