import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import RoomIcon from '@mui/icons-material/Room';
import { Alert } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  RouteType,
  useDispatchRouteMutation,
  useGetHolidayForCountryOnDateLazyQuery,
} from '../generated/graphql';
import { COUNTRIES_MAP, TABLE_NAMES } from '../lib/constants';
import { UserConfiguredDataGridPro } from './datagrid/UserConfiguredDataGridPro';
import { useHttpClient } from '../providers/HttpClientProvider';
import { formatTime } from '../lib/format';
import { format, parse } from 'date-fns';

interface RouteModalData {
  id: number;
  routeId: string;
  subcontractor: string;
  departureCountryCode: string;
}

interface LegType {
  id: number;
  locationId: number;
  locationName: string;
  locationCity: string;
  locationCountry: string;
  note: string;
  arrivalTime: string;
  departureTime: string | undefined;
  position: number;
  load: boolean;
  unload: boolean;
  transportationDateOffset: number;
  transportDay: number;
}

interface TourRoutesResponse {
  id: number;
  routeId: string;
  price: number;
  days: string;
  note: string;
  externalNote: string;
  position: number;
  departureCity: string;
  departureCountry: string;
  arrivalCity: string;
  arrivalCountry: string;
  fromToToCountry?: string;
  routeStartDate: string;
  routeEndDate?: string;
  routeCapacity?: number | undefined;
  startExceptionDate?: string;
  endExceptionDate?: string;
  routeIsActive: boolean;
  isNewVersion: boolean;
  tour: {
    id: number;
    name: string;
    capacity?: number | undefined;
    fuel: string;
    price: number;
    currency: string;
    startDate: string;
    endDate?: string;
    originalStartDate?: string | undefined;
    isActive: boolean;
    createdBy: string;
    lastUpdatedBy: string;
    updatedAt?: string | undefined;
    startExceptionDate?: string | undefined;
    endExceptionDate?: string | undefined;
    responsiblePerson?: string | undefined;
    subcontractorName: string;
    dispatcherName: string;
  };
  legs: LegType[];
  firstLegTransportDay: number;
  lastLegTransportDay: number;
  firstLegArrivalTime: string;
  lastLegArrivalTime: string;
  firstLegDepartureTime: string;
  lastLegDepartureTime: string;
}

export function TourTemplatesRouteView() {
  const { t } = useTranslation();

  const { url } = useRouteMatch();
  const { httpClient } = useHttpClient();
  const [gridHeight, setGridHeight] = useState(0);
  const [apiData, setApiData] = useState<TourRoutesResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorObj, setErrorObj] = useState<{
    error: boolean;
    message: string;
  }>({
    error: false,
    message: '',
  });

  const [dispatchModalData, setDispatchModalData] = useState<
    RouteModalData | undefined
  >();

  const handleDispatchRouteClicked = useCallback(
    (routeModalData: RouteModalData) => {
      setDispatchModalData(routeModalData);
    },
    [],
  );

  useEffect(() => {
    const element = document.getElementById('tourRouteView');
    const positions = element?.getBoundingClientRect();
    if (positions) {
      setGridHeight(window.innerHeight - (positions.top + positions.top / 5));
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    httpClient
      .getAllTourRoutes()
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setApiData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setApiData([]);
          setErrorObj({
            error: true,
            message: `${res.data.message}`,
          });
          console.error('# TourRoutes Res error=', res.data.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.error('# TourRoutes exception error=', e);
        setLoading(false);
      });
  }, []);

  if (errorObj.error) {
    return <div>{errorObj.message}</div>;
  }

  if (loading || apiData == null) {
    return <div>loading...</div>;
  }

  const columns: GridColDef[] = [
    {
      headerName: 'View Tour',
      field: 'viewTour',
      type: 'action',
      width: 75,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={`${t('actions.view')}`}>
            <Button
              to={`${url}/${row.tour.id}?route=${row.position}`}
              component={Link}
            >
              <PageviewIcon color="action" />
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: 'id',
      headerName: 'Id',
    },
    {
      field: 'routeId',
      headerName: 'Route Id',
    },
    {
      field: 'routeStartDate',
      type: 'string',
      headerName: 'StartDate',
      valueGetter({ row }) {
        //2020-01-01 is default and dummy date in DB
        return row.isNewVersion && row.routeStartDate != '2020-01-01'
          ? row.routeStartDate
          : 'N/A';
      },
      minWidth: 100,
    },
    {
      field: 'routeEndDate',
      type: 'string',
      headerName: 'EndDate',
      valueGetter({ row }) {
        return row.routeEndDate != null ? row.routeEndDate : '';
      },
      minWidth: 100,
    },
    {
      field: 'firstLegTransportDay',
      type: 'number',
      headerName: 'First leg transport day',
      minWidth: 100,
    },
    {
      field: 'lastLegTransportDay',
      type: 'number',
      headerName: 'Last leg transport day',
      minWidth: 100,
    },
    {
      field: 'firstLegArrivalTime',
      headerName: 'First leg arrival time',
      minWidth: 100,
      valueFormatter: formatTime,
    },
    {
      field: 'firstLegDepartureTime',
      headerName: 'First leg departure time',
      minWidth: 100,
      valueFormatter(params) {
        const { value } = params;
        if (typeof value === 'string' && value) {
          return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
        }
        return 'N/A';
      },
    },
    {
      field: 'lastLegArrivalTime',
      headerName: 'Last leg arrival time',
      minWidth: 100,
      valueFormatter: formatTime,
    },
    {
      field: 'lastLegDepartureTime',
      headerName: 'Last leg departure time',
      minWidth: 100,
      valueFormatter(params) {
        const { value } = params;
        if (typeof value === 'string' && value) {
          return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
        }
        return 'N/A';
      },
    },
    {
      field: 'routeIsActive',
      type: 'boolean',
      headerName: 'Active',
      valueGetter({ row }) {
        return row.routeIsActive;
      },
      minWidth: 80,
    },
    {
      field: 'subcontractor',
      headerName: 'Subcontractor',
      valueGetter({ row }) {
        return row.tour.subcontractorName;
      },
    },
    {
      field: 'capacity',
      headerName: 'Capacity',
      valueGetter({ row }) {
        return row.isNewVersion
          ? row.routeCapacity
            ? row.routeCapacity
            : ''
          : 'N/A';
      },
    },
    {
      field: 'responsiblePerson',
      headerName: 'Responsible Person',
      valueGetter({ row }) {
        return row.tour.responsiblePerson;
      },
    },
    {
      field: 'departureCity',
      headerName: 'Departure City',
      valueGetter({ row }) {
        return row.departureCity;
      },
    },
    {
      field: 'arrivalCity',
      headerName: 'Arrival City',
      valueGetter({ row }) {
        return row.arrivalCity;
      },
    },
    {
      field: 'departureCountry',
      headerName: 'Departure Country',
      valueGetter({ row }) {
        return row.departureCountry && row.departureCountry !== ''
          ? COUNTRIES_MAP[row.departureCountry as keyof typeof COUNTRIES_MAP]
          : '';
      },
      valueFormatter({ value }) {
        return typeof value === 'string' ? value.toUpperCase() : '';
      },
    },
    {
      field: 'arrivalCountry',
      headerName: 'Arrival Country',
      valueGetter({ row }) {
        return row.arrivalCountry && row.arrivalCountry !== ''
          ? COUNTRIES_MAP[row.arrivalCountry as keyof typeof COUNTRIES_MAP]
          : '';
      },
      valueFormatter({ value }) {
        return typeof value === 'string' ? value.toUpperCase() : '';
      },
    },
    {
      field: 'fromToToCountry',
      headerName: t('attributes.fromToToCountry'),
      minWidth: 100,
    },
    {
      field: 'price',
      headerName: 'Route price',
      valueGetter({ value, row }) {
        const currency =
          typeof row.tour.currency === 'string'
            ? row.tour.currency.toUpperCase()
            : '';
        return `${value} ${currency}`;
      },
    },
    {
      field: 'tourName',
      headerName: 'Tour name',
      valueGetter({ row }) {
        return row.tour.name;
      },
    },
    {
      field: 'dispatchRoute',
      headerName: 'Dispath route',
      renderCell({ row }) {
        return (
          <IconButton
            onClick={() => {
              handleDispatchRouteClicked({
                id: row.id,
                routeId: row.routeId as string,
                subcontractor: row.tour.subcontractorName as string,
                departureCountryCode:
                  row.departureCountry && row.departureCountry !== ''
                    ? (row.departureCountry as string)
                    : '',
              });
            }}
          >
            <RoomIcon />
          </IconButton>
        );
      },
    },
    {
      field: 'note',
      headerName: 'Route note',
      valueGetter({ row }) {
        return row.note ?? '';
      },
    },
    {
      field: 'externalNote',
      headerName: 'External note',
      valueGetter({ row }) {
        return row.externalNote ?? '';
      },
    },
    {
      field: 'days',
      headerName: 'Days',
      valueGetter({ row }) {
        return row.days;
      },
    },
    {
      field: 'id',
      type: 'string',
      headerName: t(`attributes.agreementNumber`),
      minWidth: 100,
      valueGetter({ row }) {
        return row.tour.id;
      },
    },
    {
      field: 'createdBy',
      type: 'string',
      headerName: t('attributes.createdBy'),
      minWidth: 100,
      valueGetter({ row }) {
        return row.tour.createdBy;
      },
    },
    {
      field: 'lastUpdatedBy',
      type: 'string',
      headerName: t('attributes.lastUpdatedBy'),
      minWidth: 100,
      valueGetter({ row }) {
        return row.tour.lastUpdatedBy;
      },
    },
    {
      field: 'lastUpdated',
      type: 'string',
      headerName: t('attributes.updatedAt'),
      minWidth: 100,
      valueGetter({ row }) {
        return row.tour.updatedAt;
      },
    },
    {
      field: 'fuel',
      type: 'string',
      headerName: 'Fuel',
      minWidth: 100,
      valueGetter({ row }) {
        return row.tour.fuel;
      },
    },
    {
      field: 'dispatcher',
      type: 'string',
      headerName: 'Dispatcher',
      minWidth: 100,
      valueGetter({ row }) {
        return row.tour.dispatcherName ? row.tour.dispatcherName : '';
      },
    },

    {
      field: 'startExceptionDate',
      type: 'date',
      headerName: 'Exception date start',
      minWidth: 100,
      valueGetter({ row }) {
        return row.isNewVersion ? row.startExceptionDate : 'N/A';
      },
    },
    {
      field: 'endExceptionDate',
      type: 'date',
      headerName: 'Exception date end',
      minWidth: 100,
      valueGetter({ row }) {
        return row.isNewVersion ? row.endExceptionDate : 'N/A';
      },
    },
    {
      field: 'isNewVersion',
      type: 'boolean',
      headerName: 'NewVersion',
      valueGetter({ row }) {
        return row.isNewVersion;
      },
      minWidth: 80,
    },
  ];
  return (
    <>
      <Box
        id="tourRouteView"
        style={{
          height: gridHeight ? gridHeight : 500,
          width: '100%',
        }}
      >
        <UserConfiguredDataGridPro
          tableName={TABLE_NAMES.TourRouteTemplates}
          rows={apiData || []}
          loading={loading}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowHeight={50}
        />
      </Box>

      <DispatchRouteModal
        data={dispatchModalData}
        handleClose={() => setDispatchModalData(undefined)}
      />
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  actionRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    width: '100%',

    '& > *': {
      padding: theme.spacing(0, 1, 1),
    },
  },
}));

interface DispathRouteModalProps {
  data?: RouteModalData;
  handleClose: () => void;
}

function DispatchRouteModal(props: DispathRouteModalProps) {
  const { t } = useTranslation();
  const { data, handleClose } = props;
  const [isHolidayDate, setIsHolidayDate] = useState<boolean>(false);
  const [date, setDate] = useState<string | null>(null);
  const [text, setText] = useState<{
    type: 'success' | 'warning';
    text: string;
    link: string;
  } | null>(null);
  const [dispatchRoute] = useDispatchRouteMutation();
  const [fetchHoliday] = useGetHolidayForCountryOnDateLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    setDate(null);
    setIsHolidayDate(false);
  }, [data?.id]);

  const onChangeDate = useCallback(
    (e) => {
      setDate(e.target.value);
      setText(null);
      //Check for holiday date
      fetchHoliday({
        variables: {
          date: e.target.value,
          country: data?.departureCountryCode ?? '',
        },
      }).then((res) => {
        setIsHolidayDate(res.data?.holidayForCountryOnDate === true);
      });
    },
    [data?.departureCountryCode],
  );

  const onSubmit = useCallback(async () => {
    if (date != null && data?.id != null) {
      const response = await dispatchRoute({
        variables: {
          input: {
            dateString: date,
            routeId: data.id,
          },
        },
      });

      if (response.data != null) {
        const statusResponse = response.data?.generateFixedTour.status;
        switch (statusResponse) {
          case 'Success':
            setText({
              type: 'success',
              text: t(
                `info.manualDispath.${
                  response.data.generateFixedTour.routeType === RouteType.Fixed
                    ? 'createdFixed'
                    : 'createdSporadic'
                }`,
                { date: response.data.generateFixedTour.date },
              ),
              link:
                response.data.generateFixedTour.routeType === RouteType.Fixed
                  ? `/traffic/dispatched-routes/${response.data.generateFixedTour.createdRouteId}`
                  : `/traffic/sporadic-routes/${response.data.generateFixedTour.createdRouteId}`,
            });
            break;
          case 'RouteAlreadyDispatched':
            setText({
              type: 'warning',
              text: t('info.manualDispath.alreadyCreated'),
              link:
                response.data.generateFixedTour.routeType === RouteType.Fixed
                  ? `/traffic/dispatched-routes/${response.data.generateFixedTour.createdRouteId}`
                  : `/traffic/sporadic-routes/${response.data.generateFixedTour.createdRouteId}`,
            });
            break;
        }
      }
    }
  }, [date]);

  return (
    <Dialog
      open={data != null}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('actions.dispatchRoute')}
      </DialogTitle>
      <DialogContent
        style={{
          padding: theme.spacing(2),
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <DialogContentText id="alert-dialog-description">
          <Typography color="textPrimary" variant="body2">
            <strong>Route:</strong> {data?.routeId}
          </Typography>
          <Typography color="textPrimary" variant="body2">
            <strong>Subcontractor:</strong> {data?.subcontractor}
          </Typography>
        </DialogContentText>

        <TextField
          id="dispatchRoute"
          size="small"
          type="date"
          variant="outlined"
          label={t(`attributes.date`)}
          onChange={onChangeDate}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {isHolidayDate && text == null ? (
          <Typography
            style={{
              color: 'red',
              fontSize: 13,
            }}
          >{`Selected date is holiday in ${
            COUNTRIES_MAP[
              data?.departureCountryCode as keyof typeof COUNTRIES_MAP
            ]
          }! | Be cautious while dispatching!`}</Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Box className={classes.actionRoot}>
          <Box>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              fullWidth
              disabled={date == null}
              style={
                isHolidayDate
                  ? {
                      border: '2px solid red',
                    }
                  : undefined
              }
            >
              {t('actions.create.capitalized')}
            </Button>
          </Box>
          {text && (
            <Box>
              <Alert
                severity={text.type}
                action={
                  <Button
                    to={text.link}
                    component={Link}
                    variant="contained"
                    color="secondary"
                  >
                    {t('actions.open')}
                  </Button>
                }
              >
                {text.text}
              </Alert>
            </Box>
          )}
          <Box>
            <Typography variant="body2" style={{ fontSize: 12 }}>
              <strong>{t('info.note')}:</strong> {t('info.manualDispatchNote')}
            </Typography>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
