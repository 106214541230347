import {
  GridCellParams,
  GridDensityTypes,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { TABLE_NAMES } from '../../lib/constants';
import { UserConfiguredDataGridPro } from './UserConfiguredDataGridPro';
import { Maybe } from '../../generated/graphql';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import OkIcon from '@mui/icons-material/CheckCircle';
import NotOkIcon from '@mui/icons-material/Cancel';
import { ViewLocation } from './ViewLocation';
import { format } from 'date-fns';
import _ from 'lodash';
import { Link, useRouteMatch } from 'react-router-dom';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useTranslation } from 'react-i18next';

interface gridProps {
  rows: {
    id: number;
    name: string;
    shipmentDate?: string | null;
    createdFrom?: Maybe<string>;
    updatedFrom?: Maybe<string>;
    createdBy: string;
    lastUpdatedBy: string;
    loadingListItems?: {
      customCleared: boolean;
      terminalCleared: boolean;
      customClearedForImport: boolean;
      isImportedFromShipment: boolean;
    }[];
  }[];
  listUpdated: () => void;
  from: string;
}

export function ShipmentGrid(props: gridProps) {
  const { rows } = props;
  const { path } = useRouteMatch();
  const apiRef = useGridApiRef();
  const selectedDarkGreen = '#3c9317';
  const dangerRed = '#ff0000';
  const { t } = useTranslation();

  return (
    <UserConfiguredDataGridPro
      apiRef={apiRef}
      tableName={TABLE_NAMES.ShipmentList}
      rows={rows || []}
      loading={false}
      disableMultipleSelection
      disableSelectionOnClick
      columns={[
        {
          field: 'id',
          headerName: 'ID',
          type: 'number',
        },
        {
          field: 'viewLoadingList',
          headerName: 'View shipment',
          minWidth: 100,
          type: 'actions',
          sortable: false,
          renderCell: (params: GridCellParams) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Tooltip title={`${t('actions.view')}`}>
                  <Link
                    to={`${path}/${params.id}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    target={'_blank'}
                  >
                    <PageviewIcon color="action" />
                  </Link>
                </Tooltip>
              </Box>
            );
          },
        },
        {
          field: 'name',
          headerName: 'Name',
          type: 'string',
          width: 100,
        },
        {
          field: 'shipmentDate',
          headerName: 'Date',
          type: 'date',
        },

        {
          field: 'terminalCleared',
          headerName: 'Terminal status',
          minWidth: 100,
          type: 'boolean',
          renderCell: (params: GridCellParams) => {
            const { row } = params;
            const { loadingListItems } = row;
            const loadingList: {
              terminalCleared: boolean;
            }[] = loadingListItems;
            const notClear = loadingList.some((ll) => !ll.terminalCleared);
            return (
              <IconButton>
                {notClear ? (
                  <NotOkIcon htmlColor={dangerRed} />
                ) : (
                  <OkIcon htmlColor={selectedDarkGreen} />
                )}
              </IconButton>
            );
          },
        },
        {
          field: 'customCleared',
          headerName: 'Custom status',
          minWidth: 100,
          type: 'boolean',
          renderCell: (params: GridCellParams) => {
            const { row } = params;
            const { loadingListItems } = row;
            const loadingList: {
              customCleared: boolean;
              customClearedForImport: boolean;
            }[] = loadingListItems;
            const notClear = loadingList.some(
              (ll) => !(ll.customCleared && ll.customClearedForImport),
            );
            return (
              <IconButton>
                {notClear ? (
                  <NotOkIcon htmlColor={dangerRed} />
                ) : (
                  <OkIcon htmlColor={selectedDarkGreen} />
                )}
              </IconButton>
            );
          },
        },
        {
          field: 'totalPCS',
          headerName: 'Total PCS',
          minWidth: 100,
          type: 'number',
          renderCell: (params: GridCellParams) => {
            const { row } = params;
            const { loadingListItems } = row;
            return _.sumBy(loadingListItems, 'packages');
          },
        },
        {
          field: 'totalPAL',
          headerName: 'Total PAL',
          minWidth: 100,
          type: 'number',
          renderCell: (params: GridCellParams) => {
            const { row } = params;
            const { loadingListItems } = row;
            return _.sumBy(loadingListItems, 'pallets');
          },
        },
        {
          field: 'totalPPL',
          headerName: 'Total PPL',
          minWidth: 100,
          type: 'number',
          renderCell: (params: GridCellParams) => {
            const { row } = params;
            const { loadingListItems } = row;
            return _.sumBy(loadingListItems, 'palletSpace');
          },
        },
        {
          field: 'weight',
          headerName: 'KG',
          minWidth: 100,
          type: 'number',
          renderCell: (params: GridCellParams) => {
            const { row } = params;
            const { loadingListItems } = row;
            return _.sumBy(loadingListItems, 'weight');
          },
        },
        {
          field: 'fromLocation',
          headerName: 'From terminal',
          width: 150,
          renderCell: (params: GridCellParams) => {
            const { row } = params;
            const { fromLocationId } = row;
            return <ViewLocation locationId={fromLocationId} />;
          },
        },
        {
          field: 'toLocation',
          headerName: 'To terminal',
          width: 150,
          renderCell: (params: GridCellParams) => {
            const { row } = params;
            const { toLocationId } = row;
            return <ViewLocation locationId={toLocationId} />;
          },
        },
        {
          field: 'updatedFrom',
          headerName: 'Updated from',
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          type: 'dateTime',
          width: 150,
          valueGetter({ value }) {
            return new Date(value as string);
          },
          valueFormatter({ value }) {
            return format(value as Date, 'dd-MM-yyyy HH:mm:ss');
          },
        },
        {
          field: 'createdFrom',
          headerName: 'Created from',
        },
        {
          field: 'createdBy',
          headerName: 'Created by',
          width: 150,
        },
        {
          field: 'lastUpdatedBy',
          headerName: 'Updated by',
          width: 150,
        },
      ]}
      density={GridDensityTypes.Compact}
      autoHeight={true}
    />
  );
}
