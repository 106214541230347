import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import '../src/i18n/i18n';
import './license';

if (window.location.hash !== '') {
  console.warn('##Login WLH=', window.location.hash);
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
reportWebVitals();
