import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { format, parse } from 'date-fns';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FuelType, Maybe } from '../../generated/graphql';
import { DATE_FORMAT } from '../../lib/date_time';
import { LoadingList, LoadingListItemInterface } from '../LoadingList';
import { TransportationDateOffsetField } from '../ProductionDateOffsetField';
import { CheckboxField } from './CheckboxField';
import { CommonTextField } from './CommonTextField';
import { SelectLocation } from './SelectLocation';
import { TimePickerField } from './TimePickerField';
import clsx from 'clsx';
import { SelectFuel } from '../SelectFuel';
import { calculateTourTransportationDates } from '../../lib/tour_leg_dates';
import { CommonNumberField } from './CommonNumberField';

export interface LegFieldInput {
  key: string;
  isAnyLinkedOrder?: boolean;
  id?: number;
  fuel: FuelType;
  load: boolean;
  unload: boolean;
  note?: string;
  arrivalTime?: Maybe<string>;
  departureTime?: Maybe<string>;
  actualArrivalDate?: string;
  actualDepartureDate?: string;
  position: number;
  productionDate?: string;
  transportationDate?: string;
  transportationDateOffset: number;
  daysDiff?: number;
  locationId?: number;
  isNonArrivalMarked?: boolean;
  nonArrivalMarkedByUserName?: string;
  nonArrivalMarkedFrom?: string;
  nonArrivalMarkedAt?: string;
  loadingListItems: LoadingListItemInterface[];
  location?: { deletedBy?: string };
  gateNumber?: string;
  carRegistrationNumber?: string;
  trailerRegistrationNumber?: string;
  routeDriverName?: string;
  routeDriverPhoneNumber?: string;
  isNewLegVariant?: boolean;
  isLoadingInCar?: Maybe<boolean> | undefined;
  isLoadingInTrailer?: Maybe<boolean> | undefined;
  actualCapacity?: number;
  carDetails?: {
    id?: number;
    loadingListItems: LoadingListItemInterface[];
    actualCapacity?: number;
    registrationNumber?: string;
    gateNumber?: string;
    tripStartDateTime?: string;
    tripEndDateTime?: string;
  };
  trailerDetails?: {
    id?: number;
    loadingListItems: LoadingListItemInterface[];
    actualCapacity?: number;
    registrationNumber?: string;
    gateNumber?: string;
    tripStartDateTime?: string;
    tripEndDateTime?: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(0.5, 0),
  },
  legField: {
    width: '100%',
    fontSize: '10px',
    marginRight: 20,
    marginTop: 18,
    marginBottom: 18,
  },
  field: {
    width: '100%',
    fontSize: '10px',
    margin: 0,
  },
  checkboxLabel: {
    fontSize: theme.typography.fontSize,
  },
  smallCheckboxLabel: {
    color: '#fc3926',
    fontSize: 12,
  },
  fieldsRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    marginTop: -8,
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: 500,
      marginTop: 4,
    },
    '& > *:first-child': {
      width: '100%',
    },
    '& > *:last-child': {
      minWidth: 625,
      flex: 2,
      justifyContent: 'space-between',
    },
  },
  fieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
  transportationWrapper: {
    margin: '0 8px 0 25px',

    '& input': {
      padding: '8px 0',
      width: 90,
      textAlign: 'center',
    },
  },
  noteCheckbox: {
    margin: '0 2px',
  },
  loadingListButton: {
    padding: 0,
  },
  pushRight: {
    marginLeft: 14,
  },
  buttonWrapper: {
    display: 'flex',

    '& > *': {
      width: '24px',
      height: '24px',
      padding: '2px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  divider: {
    height: '5px',
    margin: '2px 0',
    backgroundColor: theme.palette.primary.light,
  },
}));

interface LegFieldsProps {
  leg: LegFieldInput;
  readOnly: boolean;
  legsPath: string;
  trafficReadOnly: boolean;
  isFromTerminal: boolean;
  index: number;
  legsLength: number;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  onRemove: (index: number) => void;
  from: string;
  fromCreateRoute?: string;
  isCreateTerminalRoute?: boolean;
  enableEditMode?: boolean;
  isTruckLoading?: boolean;
}

export function LegFields(props: LegFieldsProps) {
  const { t } = useTranslation();
  const {
    leg,
    legsPath,
    readOnly,
    trafficReadOnly, //terminalORreadonly
    index,
    legsLength,
    onMoveUp,
    onMoveDown,
    onRemove,
    from,
    fromCreateRoute = '',
    isCreateTerminalRoute = false,
    isFromTerminal = false,
    enableEditMode = false,
    isTruckLoading = false,
  } = props;
  const deletedBy = leg.location ? leg.location.deletedBy : '';
  const classes = useStyles();

  const { setFieldValue, getFieldProps, getFieldMeta, setFieldTouched } =
    useFormikContext<{ transportationDate: string; legs: LegFieldInput[] }>();
  const legs = getFieldProps(`${legsPath}`).value;
  const legsWithDays = calculateTourTransportationDates(legs);

  const [loadingListOpen, setLoadingListOpen] = useState<boolean>(
    leg.loadingListItems.length >= 0,
  );
  const legPath = `${legsPath}.${index}`;
  const isLegLoad = getFieldProps(`${legPath}.load`).value;
  const isLegUnload = getFieldProps(`${legPath}.unload`).value;
  const colors = ['#e0ae75', '#ed91c8', '#9aafe6', '#a8eda1', '#e6a39a'];
  let lastColorIndex = -1; // Initialize with an invalid index

  const getBackgroundColor = (daysDiff: number) => {
    let newColorIndex = daysDiff % colors.length;
    if (newColorIndex === lastColorIndex) {
      newColorIndex = (newColorIndex + 1) % colors.length;
    }
    lastColorIndex = newColorIndex;
    return colors[newColorIndex];
  };
  const isLoadingInCar = getFieldProps(`${legPath}.isLoadingInCar`).value;
  const isLoadingInTrailer = getFieldProps(
    `${legPath}.isLoadingInTrailer`,
  ).value;

  useEffect(() => {
    if (!readOnly) {
      setFieldTouched(`${legPath}.load`, true);
      setFieldTouched(`${legPath}.unload`, true);
    }
  }, [readOnly]);
  // //for one selection

  useEffect(() => {
    if (isLegLoad && getFieldMeta(`${legPath}.load`).touched) {
      setFieldValue(`${legPath}.unload`, false);
    }
  }, [isLegLoad]);

  useEffect(() => {
    if (isLegUnload && getFieldMeta(`${legPath}.unload`).touched) {
      setFieldValue(`${legPath}.load`, false);
    }
  }, [isLegUnload]);
  const isMarkedAsNonArrival = getFieldProps(
    `${legPath}.isNonArrivalMarked`,
  ).value;
  const nonArrivalMarkedFrom = getFieldProps(
    `${legPath}.nonArrivalMarkedFrom`,
  ).value;
  const handleClearNote = useCallback(() => {
    setFieldValue(`${legPath}.note`, undefined);
  }, []);

  return (
    <Box className={classes.root}>
      <Box display="flex">
        <ViewDateField
          label={`${t('attributes.transportationDate')}:`}
          value={leg.transportationDate}
        />
        {leg.daysDiff != undefined && readOnly && (
          <Typography
            style={{
              color: '#1c1c1c',
              backgroundColor: '#e2e2e2',
              fontSize: 13,
              paddingLeft: 4,
              paddingRight: 4,
              fontWeight: 'bold',
              marginBottom: 10,
            }}
          >{`Transport Day-${leg.daysDiff}`}</Typography>
        )}
      </Box>
      <Box className={classes.fieldsRoot}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={9}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={'flex-start'}
            >
              <SelectLocation
                name={`${legPath}.locationId`}
                className={classes.field}
                readOnly={
                  trafficReadOnly ||
                  (isCreateTerminalRoute && isFromTerminal
                    ? isTruckLoading
                      ? legPath === 'legs.0'
                      : legPath === 'legs.1'
                    : false)
                }
                size="small"
                smallFontSize={true}
                fullWidth
                withLink
              />
              <Box
                sx={{
                  marginLeft: 6,
                }}
              >
                <SelectFuel
                  fieldName={`${legPath}.fuel`}
                  readOnly={trafficReadOnly}
                />
              </Box>
              <Box className={classes.fieldsWrapper}>
                <CheckboxField
                  name={`${legPath}.load`}
                  label={t('attributes.load')}
                  readOnly={trafficReadOnly || isCreateTerminalRoute}
                  size="small"
                />
                <CheckboxField
                  name={`${legPath}.unload`}
                  label={t('attributes.unload')}
                  readOnly={trafficReadOnly || isCreateTerminalRoute}
                  size="small"
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={3}>
            {fromCreateRoute === 'ViewRoute' && leg.id != undefined && (
              <Box
                display="flex"
                alignItems="flex-start"
                sx={{
                  marginLeft: 4,
                }}
              >
                {!readOnly && !isFromTerminal ? (
                  <FormControlLabel
                    className={classes.noteCheckbox}
                    value={isMarkedAsNonArrival}
                    onChange={(e, c) => {
                      setFieldValue(`${legPath}.isNonArrivalMarked`, c);
                    }}
                    control={
                      <Checkbox
                        style={{ padding: 0, color: '#fc3926' }}
                        checked={isMarkedAsNonArrival}
                        size="small"
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        className={classes.smallCheckboxLabel}
                      >
                        {'Non-arrival'}
                      </Typography>
                    }
                  />
                ) : isMarkedAsNonArrival ? (
                  <Typography
                    variant="body2"
                    className={classes.smallCheckboxLabel}
                  >
                    {`Non-arrival ${
                      nonArrivalMarkedFrom
                        ? `[Marked from ${nonArrivalMarkedFrom}]`
                        : ''
                    }`}
                  </Typography>
                ) : null}
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          {deletedBy ? (
            <Typography variant="body2" className={classes.smallCheckboxLabel}>
              {t('validation.deletedLocation')}
            </Typography>
          ) : null}
        </Grid>

        {!readOnly && from === 'TourRouteLeg' ? (
          <Grid item container>
            <Typography
              style={{
                color: '#1c1c1c',
                backgroundColor:
                  legsWithDays[index].daysDiff === 0
                    ? '#e2e2e2'
                    : getBackgroundColor(legsWithDays[index].daysDiff),
                width: 60,
                fontSize: 13,
                paddingLeft: 4,
                paddingRight: 4,
                fontWeight: 'bold',
                alignContent: 'center',
              }}
            >{`Day: ${legsWithDays[index].daysDiff}`}</Typography>
          </Grid>
        ) : null}

        <Box display="flex" alignItems="centre" justifyContent="space-between">
          <Box display="flex" justifyContent="space-between" flexWrap>
            <Box display="flex" flexWrap alignItems="center">
              <TimePickerField
                name={`${legPath}.arrivalTime`}
                readOnly={trafficReadOnly}
              />
              {!isCreateTerminalRoute && (
                <TimePickerField
                  name={`${legPath}.departureTime`}
                  readOnly={trafficReadOnly}
                />
              )}

              {index !== 0 && !isCreateTerminalRoute && (
                <Box className={classes.transportationWrapper}>
                  <TransportationDateOffsetField
                    name={`${legPath}.transportationDateOffset`}
                    readOnly={trafficReadOnly}
                  />
                </Box>
              )}
              {!trafficReadOnly && (
                <Box ml={2}>
                  <FormControlLabel
                    className={classes.noteCheckbox}
                    value={leg.note != null}
                    disabled={readOnly}
                    onChange={() => {
                      if (leg.note == null) {
                        setFieldValue(`${legPath}.note`, '');
                      }
                      if (leg.note === '') {
                        setFieldValue(`${legPath}.note`, undefined);
                      }
                    }}
                    control={
                      <Checkbox
                        style={{ padding: 0 }}
                        checked={leg.note != null}
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        className={classes.checkboxLabel}
                        color="textPrimary"
                      >
                        {'Add note'}
                      </Typography>
                    }
                  />
                </Box>
              )}
              {leg.load && !isCreateTerminalRoute && (
                <Tooltip
                  title={`${t('button.view', {
                    item: t('resource.loadingList.lowercased'),
                  })}`}
                >
                  <IconButton
                    className={clsx(classes.loadingListButton, {
                      [classes.pushRight]: index === 0,
                    })}
                    color={loadingListOpen ? 'primary' : undefined}
                    disabled={readOnly && leg.loadingListItems.length === 0}
                    onClick={() => {
                      setLoadingListOpen(!loadingListOpen);
                    }}
                  >
                    <ListAltIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box
              ml={2}
              display="flex"
              alignItems="centre"
              justifyContent="flex-end"
            >
              <CommonTextField
                name={`${legPath}.routeDriverName`}
                type="string"
                className={classes.legField}
                readOnly={trafficReadOnly}
              />

              <CommonTextField
                name={`${legPath}.routeDriverPhoneNumber`}
                type="string"
                className={classes.legField}
                readOnly={trafficReadOnly}
              />
            </Box>
            {!trafficReadOnly &&
            !isCreateTerminalRoute &&
            !isFromTerminal &&
            legsLength > 1 ? (
              <Box className={classes.buttonWrapper}>
                <IconButton
                  disabled={index === 0}
                  onClick={() => {
                    onMoveUp(index);
                  }}
                  size="small"
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  disabled={index === legs.length - 1}
                  onClick={() => {
                    onMoveDown(index);
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    onRemove(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      {leg.note != null && (
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          marginTop={1}
        >
          <CommonTextField
            name={`${legPath}.note`}
            readOnly={trafficReadOnly}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: !readOnly && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear note"
                    onClick={() => handleClearNote()}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      <Box
        width="100%"
        sx={{ bgcolor: enableEditMode ? '#fff3e0' : '' }}
        display={!loadingListOpen ? 'none' : 'block'}
      >
        {leg.load ? (
          <>
            {/* for car */}
            <>
              <Box display="flex">
                <CheckboxField
                  name={`${legPath}.isLoadingInCar`}
                  label={t('attributes.isLoadingInCar')}
                  readOnly={trafficReadOnly || isCreateTerminalRoute}
                  size="small"
                />
              </Box>
              <Grid container justifyContent="flex-start">
                <Grid item xs={5}>
                  <CommonTextField
                    name={`${legPath}.carDetails.gateNumber`}
                    className={classes.legField}
                    readOnly={trafficReadOnly}
                    style={{ maxWidth: 140 }}
                  />

                  <CommonTextField
                    name={`${legPath}.carDetails.registrationNumber`}
                    type="string"
                    className={classes.legField}
                    readOnly={trafficReadOnly}
                    style={{ maxWidth: 240 }}
                  />
                </Grid>

                {isLoadingInCar && (
                  <>
                    <CommonNumberField
                      name={`${legPath}.carDetails.actualCapacity`}
                      className={classes.legField}
                      readOnly={trafficReadOnly}
                      style={{ maxWidth: 100 }}
                    />

                    {/* ll car */}
                    <LoadingList
                      load={leg.load}
                      formikPathName={`${legPath}.carDetails.loadingListItems`}
                      readOnly={readOnly}
                      orders={leg.carDetails?.loadingListItems ?? []}
                      isCreateTerminalRoute={isCreateTerminalRoute}
                      isFromCreate={
                        isCreateTerminalRoute ||
                        fromCreateRoute === 'CreateSporadicRoute' ||
                        fromCreateRoute === 'TourRouteLeg'
                      }
                    />
                  </>
                )}
              </Grid>
            </>
            {/* for trailer */}
            <>
              <Box
                display="flex"
                alignItems="centre"
                justifyContent="flex-start"
              >
                <CheckboxField
                  name={`${legPath}.isLoadingInTrailer`}
                  label={t('attributes.isLoadingInTrailer')}
                  readOnly={trafficReadOnly || isCreateTerminalRoute}
                  size="small"
                />
              </Box>
              {isLoadingInTrailer && (
                <>
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={5}>
                      <CommonTextField
                        name={`${legPath}.trailerDetails.gateNumber`}
                        className={classes.legField}
                        readOnly={trafficReadOnly}
                        style={{ maxWidth: 140 }}
                      />

                      <CommonTextField
                        name={`${legPath}.trailerDetails.registrationNumber`}
                        type="string"
                        className={classes.legField}
                        readOnly={trafficReadOnly}
                        style={{ maxWidth: 240 }}
                      />
                    </Grid>
                    <CommonNumberField
                      name={`${legPath}.trailerDetails.actualCapacity`}
                      className={classes.legField}
                      readOnly={trafficReadOnly}
                      style={{ maxWidth: 100 }}
                    />
                  </Grid>
                  {/* ll trailer */}
                  <LoadingList
                    load={leg.load}
                    formikPathName={`${legPath}.trailerDetails.loadingListItems`}
                    readOnly={readOnly}
                    orders={leg.trailerDetails?.loadingListItems ?? []}
                    isCreateTerminalRoute={isCreateTerminalRoute}
                    isFromCreate={
                      isCreateTerminalRoute ||
                      fromCreateRoute === 'CreateSporadicRoute' ||
                      fromCreateRoute === 'TourRouteLeg'
                    }
                  />
                </>
              )}
            </>
          </>
        ) : isLegUnload ? (
          // for unload
          <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item xs={6}>
              <Box
                width="100%"
                style={{
                  border: '1px solid #ebebeb',
                  borderRadius: 5,
                  paddingLeft: 5,
                }}
                component={'fieldset'}
              >
                <legend>CAR</legend>
                <CommonNumberField
                  name={`${legPath}.carDetails.gateNumber`}
                  className={classes.legField}
                  readOnly={trafficReadOnly}
                  style={{ maxWidth: 120 }}
                />

                <CommonTextField
                  name={`${legPath}.carDetails.registrationNumber`}
                  type="string"
                  className={classes.legField}
                  readOnly={trafficReadOnly}
                  style={{ maxWidth: 200 }}
                />
                <CommonNumberField
                  name={`${legPath}.carDetails.actualCapacity`}
                  className={classes.legField}
                  readOnly={trafficReadOnly}
                  style={{ maxWidth: 90 }}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                width="100%"
                style={{
                  border: '1px solid #ebebeb',
                  borderRadius: 5,
                  paddingLeft: 5,
                }}
                component={'fieldset'}
              >
                <legend>TRAILER</legend>
                <CommonNumberField
                  name={`${legPath}.trailerDetails.gateNumber`}
                  className={classes.legField}
                  readOnly={trafficReadOnly}
                  style={{ maxWidth: 120 }}
                />

                <CommonTextField
                  name={`${legPath}.trailerDetails.registrationNumber`}
                  type="string"
                  className={classes.legField}
                  readOnly={trafficReadOnly}
                  style={{ maxWidth: 200 }}
                />
                <CommonNumberField
                  name={`${legPath}.trailerDetails.actualCapacity`}
                  className={classes.legField}
                  readOnly={trafficReadOnly}
                  style={{ maxWidth: 90 }}
                />
              </Box>
            </Grid>
          </Grid>
        ) : null}
      </Box>

      {leg.load &&
        leg.loadingListItems &&
        leg.loadingListItems.length > 0 &&
        from === 'LegsFields' &&
        leg.loadingListItems.findIndex((ll) => ll.isDangerous === true) !==
          -1 && (
          <Typography
            style={{
              marginTop: 6,
              marginBottom: 2,
              fontSize: 12,
              fontWeight: 'bolder',
              color: 'red',
            }}
          >
            {`${t('attributes.warningOfDangerousGoods')}`}
          </Typography>
        )}
      {legsLength - 1 !== index && <Divider className={classes.divider} />}
    </Box>
  );
}
const useStyleViewDate = makeStyles(() => ({
  root: {
    display: 'flex',

    '& p': {
      fontSize: 12,
      marginRight: 5,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}));

interface ViewDateFieldProps {
  label: string;
  value?: string;
}

function ViewDateField(props: ViewDateFieldProps) {
  const { label, value } = props;
  const classes = useStyleViewDate();

  if (value == null || value === '') {
    return null;
  }

  const date = parse(value, DATE_FORMAT, new Date());
  const dateString = format(date, DATE_FORMAT);

  return (
    <Box className={classes.root}>
      <Typography className={classes.bold}>{label}</Typography>
      <Typography>{dateString}</Typography>
    </Box>
  );
}
