import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import { COUNTRIES_MAP } from '../lib/constants';
import { CommonTextField } from './form/CommonTextField';
import { SelectCountryField } from './form/SelectCountryField';
import * as yup from 'yup';
import { DATE_FORMAT } from '../lib/date_time';
import { add, format } from 'date-fns';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { CommonDateField } from './form/CommonDateField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
  formRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      padding: theme.spacing(1),
      width: '100%',
    },

    '& input': {
      width: '100%',
    },
  },
  field: {
    width: '100%',
  },
}));
const validationSchema = (t: TFunction) =>
  yup.object({
    title: yup
      .string()
      .min(
        1,
        t('validation.atLeastLen', {
          name: t('attributes.title'),
          len: 5,
        }),
      )
      .required(t('validation.isRequired', { name: t('attributes.title') })),
    date: yup.date().required(
      t('validation.isRequired', {
        name: t('attributes.date'),
      }),
    ),
    alternateGenerateDate: yup.date().required(
      t('validation.isRequired', {
        name: t('attributes.alternateGenerateDate'),
      }),
    ),
  });

export interface HolidayFormValues {
  title: string;
  date: string;
  alternateGenerateDate: string;
  country: string;
}

interface HolidayFormProps {
  onSubmit: (values: HolidayFormValues) => void;
  initialValues?: HolidayFormValues;
}

export function HolidayForm(props: HolidayFormProps) {
  const { department } = useUserConfiguration();
  //Should be same also in fixedTour.service.ts line 48
  const ROUTES_GEN_DAYS = 2;
  const {
    onSubmit,
    initialValues = {
      title: '',
      date: '',
      alternateGenerateDate: '',
      country: department?.id ?? Object.keys(COUNTRIES_MAP)[0],
    },
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm({
            values: {
              title: '',
              date: '',
              alternateGenerateDate: '',
              country: values.country,
            },
          });
        }}
        validationSchema={validationSchema(t)}
      >
        {(prop) => (
          <Form>
            <Box className={classes.formRoot}>
              <Box>
                <CommonTextField name="title" fullWidth />
              </Box>
              <Box>
                <CommonDateField
                  name="date"
                  fullWidth
                  variant="outlined"
                  minDate={true}
                  minDateFormated={format(
                    add(new Date(), { days: ROUTES_GEN_DAYS }),
                    DATE_FORMAT,
                  )}
                  linkName="alternateGenerateDate"
                />
              </Box>
              <Box>
                <CommonDateField
                  name="alternateGenerateDate"
                  fullWidth
                  variant="outlined"
                  minDate={true}
                  minDateFormated={
                    prop.values.date != ''
                      ? format(
                          add(new Date(prop.values.date), { days: 1 }),
                          DATE_FORMAT,
                        )
                      : format(
                          add(new Date(), { days: ROUTES_GEN_DAYS }),
                          DATE_FORMAT,
                        )
                  }
                />
              </Box>
              <Box>
                <SelectCountryField name="country" fullWidth />
              </Box>
              <Button variant="contained" color="primary" type="submit">
                {t('button.add', { item: t('resource.holiday.capitalized') })}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
