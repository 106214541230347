import { usePDF } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import { downloadPDF } from '../lib/pdf/downloadPdf';
import { useHttpClient } from '../providers/HttpClientProvider';
import DownloadIcon from '@mui/icons-material/Download';
import { TftPdfReport } from '../pdf/ReportFromTerminal/TrafficTftPdf';
import { ListIncomingRoutesLegs } from '../views/TrafficTFT';
import {
  InternalTerminalReportInfo,
  useReportMailMutation,
} from '../generated/graphql';
import { Box, Button } from '@material-ui/core';
import { PdfReportBoxType } from '../pdf/ReportFromTerminal/PdfReportFromTerminal';
import { useEffect, useState } from 'react';
import { DeviationAlert } from './ViewDeviation';
import { Alert, Collapse, IconButton, Modal } from '@mui/material';

const styleForMailStatus = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  height: '30vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface TftPdfProps {
  reportValueBoxData: PdfReportBoxType[];
  reportInfoData: Pick<
    InternalTerminalReportInfo,
    'id' | 'info' | 'infoType' | 'dateForAddInfo'
  >[];
  startDateTime: string;
  routeLegs: ListIncomingRoutesLegs[];
  endDateTime: string;
  terminalName: string;
  excelSheetData?: Blob;
  mailIds?: string[];
  viewSendReport?: boolean;
}

//Create Document Component
export function DownloadTftPdf(props: TftPdfProps) {
  const {
    startDateTime,
    routeLegs,
    endDateTime,
    terminalName,
    reportValueBoxData,
    reportInfoData,
    excelSheetData,
    mailIds,
    viewSendReport = true,
  } = props;
  const [alert, setAlert] = useState<DeviationAlert | null>(null);
  const [sendReportMail] = useReportMailMutation();

  const [instance, update] = usePDF({
    document: (
      <TftPdfReport
        reportInfoData={reportInfoData}
        reportValueBoxData={reportValueBoxData}
        routeLegs={routeLegs}
        selectedDateRange={`${startDateTime} | ${endDateTime}`}
        terminalName={terminalName}
      />
    ),
  });

  useEffect(() => {
    update(
      <TftPdfReport
        reportInfoData={reportInfoData}
        reportValueBoxData={reportValueBoxData}
        routeLegs={routeLegs}
        selectedDateRange={`${startDateTime} | ${endDateTime}`}
        terminalName={terminalName}
      />,
    );
  }, [reportInfoData, reportValueBoxData]);

  const { httpClient } = useHttpClient();
  function asyncBlobToBase64(blob: Blob) {
    return new Promise((resolve) => {
      const pdfReader = new FileReader();
      pdfReader.readAsDataURL(blob);
      pdfReader.onloadend = function () {
        return resolve(pdfReader.result);
      };
    });
  }

  return (
    <>
      {alert && (
        <Modal
          open={alert.type ? true : false}
          onClose={() => setAlert(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableScrollLock
        >
          <Box sx={styleForMailStatus}>
            <Collapse in={alert != null}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlert(null);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                severity={alert?.type}
              >
                {alert?.message}
              </Alert>
            </Collapse>
          </Box>
        </Modal>
      )}
      <Button
        variant="contained"
        startIcon={<DownloadIcon />}
        color="primary"
        style={{ marginTop: 5 }}
        onClick={() => {
          downloadPDF(
            instance.blob,
            [],
            httpClient,
            `TftReport_${terminalName}_${startDateTime}${endDateTime}.pdf`,
          );
        }}
      >
        {`GET PDF`}
      </Button>
      {viewSendReport && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: 2, marginTop: 5, marginLeft: 2 }}
          disabled={mailIds && mailIds?.length > 0 ? false : true}
          size="small"
          onClick={() => {
            if (instance.blob && excelSheetData && mailIds) {
              Promise.all([
                asyncBlobToBase64(instance.blob),
                asyncBlobToBase64(excelSheetData),
              ]).then((res) => {
                const pdfBase64: string = res[0] as string;
                const excelSheetBase64: string = res[1] as string;
                sendReportMail({
                  variables: {
                    terminalName: terminalName ?? '',
                    mailIds: mailIds,
                    pdfAttachment: pdfBase64,
                    excelSheetAttachment: excelSheetBase64,
                  },
                }).then((res) => {
                  if (res.data?.reportMail === 'Success') {
                    setAlert({
                      type: 'success',
                      message: 'Email sent',
                    });
                  } else {
                    setAlert({
                      type: 'error',
                      message: 'Failed',
                    });
                  }
                });
              });
            } else if (instance.blob && mailIds) {
              asyncBlobToBase64(instance.blob).then((res) => {
                const pdfBase64: string = res as string;
                sendReportMail({
                  variables: {
                    terminalName: terminalName,
                    mailIds: mailIds,
                    pdfAttachment: pdfBase64,
                  },
                }).then((res) => {
                  if (res.data?.reportMail === 'Success') {
                    setAlert({
                      type: 'success',
                      message: 'Email sent',
                    });
                  } else {
                    setAlert({
                      type: 'error',
                      message: 'Failed',
                    });
                  }
                });
              });
            }
          }}
        >
          {'SEND REPORTS'}
        </Button>
      )}
    </>
  );
}
