import {
  createStyles,
  Grid,
  IconButton,
  ListItem,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DangerIcon from '@mui/icons-material/Report';
import LinkedIcon from '@mui/icons-material/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DangerousGoodsClassification, Maybe } from '../generated/graphql';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { SimpleMessageDialog } from './SimpleMessageDialog';
import { useFormContext, useWatch } from 'react-hook-form';
import { SporadicRouteFormInput } from './RouteFormRHF';
import { SelectLocationRHF } from './form/SelectLocationRHF';
import { FastTextFieldPackageRHF } from './form/FastTextFieldPackageRHF';
import { FastTextFieldPalletsRHF } from './form/FastTextFieldPalletsRHF';
import { FastTextFieldPalletSpaceRHF } from './form/FastTextFieldPalletSpaceRHF';
import { FastTextFieldNoteRHF } from './form/FastTextFieldNoteRHF';
import { FastCheckboxRHF } from './form/FastCheckboxRHF';
import { FastTextFieldWeightRHF } from './form/FastTextFieldWeightRHF';
import { FastTextFieldUnNumberRHF } from './form/FastTextFieldUnNumberRHF';
import { CheckboxIconFieldRHF } from './form/CheckboxIconFieldRHF';
import { SelectEnumFieldSmallRHF } from './form/SelectEnumFieldSmallRHF';

export interface LoadingListItemInterface {
  id?: number;
  packages?: Maybe<number | string>;
  pallets?: Maybe<number | string>;
  palletSpace?: Maybe<number | string>;
  weight?: Maybe<number | string>;
  note: string;
  locationId?: number;
  checked: boolean;
  isDangerous: boolean;
  unNumber?: Maybe<number> | undefined;
  isLimitedQty: boolean;
  classification?: Maybe<DangerousGoodsClassification> | undefined;
}

interface LoadOrderProps {
  removeOrder: (index: number) => void;
  readOnly: boolean;
  legIndex: number;
  orderIndex: number;
  key: string;
  forTask: string;
  isCreateTerminalRoute?: boolean;
  isFromCreate?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    greenChecked: {
      backgroundColor: '#d3f8d3',
    },
    redBorder: {
      border: '1px solid red',
    },
    greyBackFocused: {
      backgroundColor: '#f1f5eb',
      paddingTop: 2,
      paddingBottom: 2,
    },
    root: {
      margin: 0,
      padding: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
    noPadding: {
      padding: '0 !important',
    },
    deleteButton: {
      padding: '0 !important',
      marginRight: 1,
    },
    listItem: {
      '&.MuiListItem': {
        padding: 0,
      },
      '&.MuiListItem-gutters': {
        padding: 0,
      },
      '.MuiListItem-root': {
        padding: 0,
      },
    },
    numberFields: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    selecLocationField: {
      width: '98%',
      fontSize: '10px',
      margin: 0,
      padding: 0,
    },
    field: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
  }),
);

export function LoadOrderRHF(props: LoadOrderProps) {
  const {
    removeOrder,
    readOnly,
    legIndex,
    orderIndex,
    isCreateTerminalRoute = false,
    isFromCreate = false,
    key,
    forTask,
  } = props;
  const { t } = useTranslation();

  const { control, getValues: getFieldProps } =
    useFormContext<SporadicRouteFormInput>();

  const carORtrailer = forTask === 'CAR' ? 'carDetails' : 'trailerDetails';

  const [isDangerous] = useWatch({
    control,
    name: [
      `legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.isDangerous`,
    ],
  });

  const [currentFocusedIndex, setCurrentFocusedIndex] = useState<number>(-1);
  const [openLimitedQtyInfoDialog, setOpenLimitedQtyInfoDialog] =
    useState<boolean>(false);
  const classes = useStyles();

  function getChecked(index: number): string {
    if (
      getFieldProps(
        `legs.${legIndex}.${carORtrailer}.loadingListItems.${index}.checked`,
      )
    ) {
      return classes.greenChecked;
    }
    return '';
  }
  const isImportedFromShipment = getFieldProps(
    `legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.isImportedFromShipment`,
  );
  function getDangerous(index: number): string {
    if (
      getFieldProps(
        `legs.${legIndex}.${carORtrailer}.loadingListItems.${index}.isDangerous`,
      )
    ) {
      return classes.redBorder;
    }
    return '';
  }

  const handleBorderVisibility = (index: number) => {
    if (index !== currentFocusedIndex) setCurrentFocusedIndex(index);
  };

  return (
    <>
      <Grid item xs={12} spacing={2} container className={classes.root}>
        <SimpleMessageDialog
          open={openLimitedQtyInfoDialog}
          setOpen={setOpenLimitedQtyInfoDialog}
          title={t('validation.limitedQtyInfoTitle')}
          contentText={t('validation.limitedQtyInfo')}
          isMultiLine={true}
        />

        <ListItem
          key={key}
          divider
          className={`${getChecked(orderIndex)} ${getDangerous(orderIndex)} ${
            classes.noPadding
          }`}
        >
          <Grid
            xs={12}
            item
            container
            key={orderIndex}
            style={{ width: '100%' }}
            onFocus={(event) => {
              if (!event.currentTarget.contains(event.relatedTarget as Node)) {
                handleBorderVisibility(orderIndex);
              }
            }}
            onBlur={(event) => {
              if (!event.currentTarget.contains(event.relatedTarget as Node)) {
                setCurrentFocusedIndex(-1);
              }
            }}
            className={`${
              currentFocusedIndex === orderIndex && !readOnly
                ? classes.greyBackFocused
                : ''
            } ${classes.noPadding}`}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              <Grid item container xs={4} direction={'row'}>
                {isImportedFromShipment && (
                  <Grid item xs={1}>
                    <Tooltip title={`${t('attributes.orderIsLinked')}`}>
                      <LinkedIcon
                        style={{
                          color: 'grey',
                        }}
                      />
                    </Tooltip>
                  </Grid>
                )}
                <Grid item xs={isImportedFromShipment ? 11 : 12}>
                  <SelectLocationRHF
                    key={`legs.${carORtrailer}.${legIndex}_${orderIndex}l`}
                    name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.locationId`}
                    controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.locationId`}
                    className={classes.selecLocationField}
                    readOnly={readOnly || isImportedFromShipment}
                    size="small"
                    smallFontSize
                    noLabel
                    smallPadding
                    shortAddress={true}
                    locationPath={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.location`}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={3}>
                <Grid item xs={3}>
                  <FastTextFieldPackageRHF
                    key={`legs.${carORtrailer}.${legIndex}_${orderIndex}pc`}
                    noLabel
                    size="small"
                    smallFontSize
                    name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.packages`}
                    controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.packages`}
                    className={classes.numberFields}
                    type="number"
                    readOnly={readOnly || isImportedFromShipment}
                    smallPadding
                  />
                </Grid>
                <Grid item xs={3}>
                  <FastTextFieldPalletsRHF
                    key={`legs.${carORtrailer}.${legIndex}_${orderIndex}pl`}
                    noLabel
                    size="small"
                    smallFontSize
                    name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.pallets`}
                    controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.pallets`}
                    className={classes.numberFields}
                    type="number"
                    readOnly={readOnly || isImportedFromShipment}
                    smallPadding
                  />
                </Grid>
                <Grid item xs={3}>
                  <FastTextFieldPalletSpaceRHF
                    key={`legs.${carORtrailer}.${legIndex}_${orderIndex}ppl`}
                    noLabel
                    size="small"
                    smallFontSize
                    smallPadding
                    name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.palletSpace`}
                    controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.palletSpace`}
                    className={classes.numberFields}
                    type="number"
                    readOnly={readOnly || isImportedFromShipment}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FastTextFieldWeightRHF
                    key={`legs.${carORtrailer}.${legIndex}_${orderIndex}wt`}
                    noLabel
                    size="small"
                    smallFontSize
                    smallPadding
                    name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.weight`}
                    controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.weight`}
                    className={classes.numberFields}
                    type="number"
                    readOnly={readOnly || isImportedFromShipment}
                  />
                </Grid>
              </Grid>
              {isFromCreate === false && (
                <Grid item xs={2} style={{ marginRight: 2 }}>
                  <FastTextFieldNoteRHF
                    key={`legs.${carORtrailer}.${legIndex}_${orderIndex}n`}
                    noLabel
                    size="small"
                    multiline
                    smallFontSize
                    name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.customWaybillNo`}
                    controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.customWaybillNo`}
                    readOnly={true}
                    className={classes.field}
                    smallPadding
                  />
                </Grid>
              )}
              <Grid item xs={2} style={{ marginRight: 2 }}>
                <FastTextFieldNoteRHF
                  noLabel
                  size="small"
                  multiline
                  smallFontSize
                  name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.note`}
                  controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.note`}
                  readOnly={readOnly || isImportedFromShipment}
                  className={classes.field}
                  smallPadding
                />
              </Grid>
              <Grid
                item
                xs={1}
                container
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  width={'100%'}
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  {!readOnly && (
                    <FastCheckboxRHF
                      name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.checked`}
                      controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.checked`}
                      label={''}
                      readOnly={readOnly || isImportedFromShipment}
                      size="small"
                    />
                  )}
                  <Tooltip
                    title={
                      readOnly || isImportedFromShipment
                        ? `${t('attributes.tooltipDangerousGoodsIconReadOnly')}`
                        : `${t('attributes.tooltipDangerousGoodsIcon')}`
                    }
                  >
                    <div>
                      <CheckboxIconFieldRHF
                        name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.isDangerous`}
                        controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.isDangerous`}
                        icon={
                          <DangerIcon
                            style={{
                              color: readOnly ? '#bdbdbd' : 'grey',
                            }}
                          />
                        }
                        checkedIcon={
                          <DangerIcon
                            style={{
                              color:
                                readOnly || isImportedFromShipment
                                  ? '#f97048'
                                  : 'red',
                            }}
                          />
                        }
                        readOnly={readOnly || isImportedFromShipment}
                        size="small"
                      />
                    </div>
                  </Tooltip>
                  {!readOnly && !isImportedFromShipment && (
                    <>
                      {orderIndex === 0 && isCreateTerminalRoute ? (
                        <></>
                      ) : (
                        <IconButton
                          className={classes.deleteButton}
                          onClick={() => {
                            removeOrder(orderIndex);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </Box>
            {isDangerous && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                style={{ width: '100%', marginTop: 1 }}
              >
                {/* new for dangerous goods info */}
                <Grid item xs={5} container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontSize: 12,
                      }}
                      color="textPrimary"
                    >{`Goods Info -`}</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <SelectEnumFieldSmallRHF
                      name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.classification`}
                      controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.classification`}
                      readOnly={readOnly || isImportedFromShipment}
                      enumObject={DangerousGoodsClassification}
                      translationPath={'enums.dangerousGoodsClass'}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontSize: 12,
                      }}
                      color="textPrimary"
                    >{`UN-`}</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <FastTextFieldUnNumberRHF
                      noLabel
                      size="small"
                      smallFontSize
                      name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.unNumber`}
                      controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.unNumber`}
                      className={classes.numberFields}
                      type="number"
                      placeholder="4 digit number"
                      readOnly={readOnly || isImportedFromShipment}
                      smallPadding
                      autoComplete="off"
                      onInput={(e) => {
                        (e.target as HTMLInputElement).value = Math.max(
                          0,
                          parseInt((e.target as HTMLInputElement).value),
                        )
                          .toString()
                          .slice(0, 4);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} container direction="row">
                  <Grid item xs={10}>
                    <FastCheckboxRHF
                      name={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.isLimitedQty`}
                      controllerName={`legs.${legIndex}.${carORtrailer}.loadingListItems.${orderIndex}.isLimitedQty`}
                      label={t('attributes.limitedQty')}
                      readOnly={readOnly || isImportedFromShipment}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {!readOnly && (
                      <Tooltip
                        title={`${t('attributes.tooltipLimitedQtyInfo')}`}
                      >
                        <IconButton
                          className={classes.deleteButton}
                          onClick={() => setOpenLimitedQtyInfoDialog(true)}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={3}></Grid>
              </Box>
            )}
          </Grid>
        </ListItem>
      </Grid>
    </>
  );
}
