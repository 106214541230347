import { Box, Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useState } from 'react';
import { useHttpClient } from '../providers/HttpClientProvider';

enum CurrentState {
  Waiting,
  Requesting,
  Failed,
  Done,
}

interface State {
  state: CurrentState;
  error?: string;
  stats?: {
    generatedFrom: Date;
    generatedTo: Date;
    newRoutesCreated: number;
  };
}

export function GenerateFixed() {
  const { httpClient } = useHttpClient();
  const [skipCronCheck, setSkipCronCheck] = useState<boolean>(false);
  const [skipDBEntry, setSkipDBEntry] = useState<boolean>(false);
  const [response, setResponse] = useState<State>({
    state: CurrentState.Waiting,
  });

  const onButtonClicked = async () => {
    setResponse({
      state: CurrentState.Requesting,
    });
    // const res = await httpClient.generateFixedRoutes({
    //   skipCronCheck,
    //   skipDBEntry,
    // });

    // if (res.status === 201) {
    //   setResponse({
    //     state: CurrentState.Done,
    //     stats: res.data.stats,
    //   });
    // } else {
    //   setResponse({
    //     state: CurrentState.Failed,
    //   });
    // }
  };

  return (
    <Grid
      container
      justifyContent="center"
      spacing={4}
      alignItems="center"
      direction={'column'}
    >
      <Box
        justifyContent="start"
        sx={{ margin: 2 }}
        display="flex"
        flexDirection="row"
      >
        <Checkbox
          title="SkipCronCheck"
          style={{ padding: 0, color: 'orange' }}
          checked={skipCronCheck}
          size="small"
          onChange={(_, checked) => {
            setSkipCronCheck(checked);
          }}
        />
        <Typography
          style={{ fontSize: 14, marginLeft: 2 }}
        >{`Testing - Skip Cron Check`}</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="start"
        sx={{ margin: 2 }}
      >
        <Checkbox
          title="SkipDBEntry"
          style={{ padding: 0, color: 'orange' }}
          checked={skipDBEntry}
          size="small"
          onChange={(_, checked) => {
            setSkipDBEntry(checked);
          }}
        />
        <Typography
          style={{ fontSize: 14, marginLeft: 2 }}
        >{`Testing - Skip DB Entry`}</Typography>
      </Box>
      <Grid item sm={3}>
        <Button
          variant="outlined"
          style={{
            border: '2px solid red',
            marginBottom: 10,
            boxShadow:
              '0 4px 12px 0 rgba(0,0,0,0.2), 0 4px 16px 0 rgba(0,0,0,0.19)',
          }}
          onClick={onButtonClicked}
        >
          Generate Fixed Routes
        </Button>

        <StatsView
          state={response.state}
          stats={response.stats}
          error={response.error}
        />
      </Grid>
    </Grid>
  );
}

interface StatsViewProps {
  state: CurrentState;
  error?: string;
  stats?: {
    generatedFrom: Date;
    generatedTo: Date;
    newRoutesCreated: number;
  };
}

function StatsView(props: StatsViewProps) {
  switch (props.state) {
    case CurrentState.Waiting:
      return null;
    case CurrentState.Requesting:
      return <Typography>Requesting...</Typography>;
    case CurrentState.Failed:
      return <Typography>Something went wrong: {props.error}</Typography>;
    case CurrentState.Done:
      const from =
        props.stats?.generatedFrom != null
          ? format(new Date(props.stats.generatedFrom), 'dd-MM-yy')
          : '';
      const to =
        props.stats?.generatedTo != null
          ? format(new Date(props.stats.generatedTo), 'dd-MM-yy')
          : '';
      return (
        <Box>
          <Typography variant="subtitle1">Done</Typography>
          <Box mt="20px">
            <Typography variant="subtitle2">Fixed routes stats:</Typography>
            <Typography>- Generated from: {from}</Typography>
            <Typography>- Generated to: {to}</Typography>
            <Typography>
              - New routes created: {props.stats?.newRoutesCreated}
            </Typography>
          </Box>
        </Box>
      );
  }
}
