import { Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { HelmetComponent } from '../components/HelmetComponent';
import {
  TourTemplateForm,
  TourTemplateFormInput,
} from '../components/TourTemplateForm';
import {
  LoadingListItemInput,
  useCreateTourTemplateMutation,
  useGetDepartmentsQuery,
  useGetTourTemplateLazyQuery,
} from '../generated/graphql';
import { timeOrNull } from '../lib/date_time';
import { changeToDaysArray } from '../lib/formHelpers/days';
import {
  numberOrNull,
  _toNumber,
  _toNumberOrUndefined,
} from '../lib/formHelpers/number';
import { copyTourRoutesArray, newTourRoute } from '../lib/formHelpers/route';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { getCurrencyFromDepartment } from '../utils/GetCurrencyFromDepartment';

export function CreateTourTemplate() {
  const { id } = useParams<{ id: string }>();
  const router = useHistory();
  const { t } = useTranslation();
  const { department } = useUserConfiguration();
  const [
    getTourTemplateLazyQuery,
    { data: copyTourData, loading: copyTourLoading, error: copyTourError },
  ] = useGetTourTemplateLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (id) {
      getTourTemplateLazyQuery({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, []);

  const { data: departmentsData, loading } = useGetDepartmentsQuery();
  const [createTourTemplate, { error: createTourError }] =
    useCreateTourTemplateMutation();

  if (id && (copyTourLoading || !copyTourData) && !copyTourError) {
    return <div>Loading ..</div>;
  }

  if (id && copyTourError) {
    return <div>{copyTourError.message}</div>;
  }

  if ((!id && loading) || departmentsData == null) {
    return <div>Loading ..</div>;
  }
  const copyTourDataTourTemplate = copyTourData?.tourTemplate;
  const onSubmit = async (
    values: Omit<TourTemplateFormInput, 'subcontractorId'> & {
      subcontractorId?: number;
    },
  ) => {
    const { data } = await createTourTemplate({
      variables: {
        TourTemplateInput: {
          currency: values.currency,
          dispatcherId:
            typeof values.dispatcherId === 'number'
              ? values.dispatcherId
              : null,
          note: values.note,
          driverName: values.driverName,
          driverPhoneNumber: values.driverPhoneNumber,
          licensePlate: values.licensePlate,
          price: values.price ?? 0,
          responsiblePerson: values.responsiblePerson,
          groupingTag: values.groupingTag,
          isPriceEquallyDivided: values.isPriceEquallyDivided,
          routes: values.routes.map((route, index) => {
            return {
              files: route.files.map((file) => {
                return {
                  id: file.id,
                };
              }),
              ftl: route.ftl,
              capacity: numberOrNull(route.capacity),
              kilometer: _toNumberOrUndefined(route.kilometer),
              weight: _toNumberOrUndefined(route.weight),
              startDate: route.startDate,
              endDate:
                route.endDate === '' || route.endDate == null
                  ? null
                  : route.endDate,
              startExceptionDate: route.hasExceptionDates
                ? route.startExceptionDate
                : undefined,
              endExceptionDate: route.hasExceptionDates
                ? route.endExceptionDate
                : undefined,
              exceptionDates: route.exceptionDates,
              routeDateType: route.routeDateType,
              tourRouteType: route.tourRouteType,
              position: index,
              price: route.price ?? 0,
              note: route.note,
              externalNote: route.externalNote ?? '',
              routeId: route.routeId,
              routeDriverName: route.routeDriverName,
              routeDriverPhoneNumber: route.routeDriverPhoneNumber,
              routeCarRegNumber: route.routeCarRegNumber,
              routeTrailerRegNumber: route.routeTrailerRegNumber,
              isCopyDriverPlateInfo: route.isCopyDriverPlateInfo,
              costAllocations: route.costAllocations.map((ca) => ({
                departmentId: ca.departmentId,
                type: ca.type,
                includedKpi: ca.includedKpi,
                includedPrice: ca.includedPrice,
                bringCost: ca.bringCost,
                additionalCost: ca.additionalCost,
                additionalDiscount: ca.additionalDiscount,
                cost: ca.cost,
                comment: ca.comment,
              })),
              days: changeToDaysArray(route.days),
              legs: route.legs.map((leg, index) => {
                return {
                  locationId: leg.locationId as number,
                  position: index,
                  gateNumber: leg.gateNumber ?? '',
                  routeDriverName: leg.routeDriverName ?? '',
                  routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
                  carRegistrationNumber: leg.carRegistrationNumber ?? '',
                  trailerRegistrationNumber:
                    leg.trailerRegistrationNumber ?? '',
                  load: leg.load,
                  unload: leg.unload,
                  fuel: leg.fuel,
                  arrivalTime: leg.arrivalTime ?? '00:00',
                  departureTime: timeOrNull(leg.departureTime),
                  note: leg.note ?? '',
                  transportationDateOffset: leg.transportationDateOffset,
                  isNewLegVariant: true,
                  isLoadingInCar: leg.isLoadingInCar ?? false,
                  isLoadingInTrailer: leg.isLoadingInTrailer ?? false,
                  carDetails: {
                    gateNumber:
                      leg.carDetails && leg.carDetails.gateNumber != null
                        ? `${leg.carDetails.gateNumber}`
                        : '',
                    actualCapacity: leg.carDetails?.actualCapacity
                      ? _toNumber(leg.carDetails?.actualCapacity)
                      : null,
                    loadingListItems:
                      leg.load &&
                      leg.carDetails &&
                      leg.isLoadingInCar &&
                      leg.carDetails.loadingListItems
                        ? leg.carDetails.loadingListItems.map(
                            (loadingListItem): LoadingListItemInput => ({
                              locationId: _toNumber(loadingListItem.locationId),
                              packages: _toNumber(loadingListItem.packages),
                              pallets: _toNumber(loadingListItem.pallets),
                              palletSpace: _toNumber(
                                loadingListItem.palletSpace,
                              ),
                              weight: _toNumber(loadingListItem.weight),
                              note: loadingListItem.note,
                              checked: loadingListItem.checked,
                              isDangerous: loadingListItem.isDangerous,
                              classification: loadingListItem.classification,
                              unNumber: _toNumberOrUndefined(
                                loadingListItem.unNumber,
                              ),
                              isLimitedQty: loadingListItem.isLimitedQty,
                            }),
                          )
                        : [],
                    registrationNumber: leg.carDetails?.registrationNumber,
                  },
                  trailerDetails: {
                    gateNumber:
                      leg.trailerDetails &&
                      leg.trailerDetails.gateNumber != null
                        ? `${leg.trailerDetails.gateNumber}`
                        : '',
                    actualCapacity: leg.trailerDetails?.actualCapacity
                      ? _toNumber(leg.trailerDetails?.actualCapacity)
                      : null,
                    loadingListItems:
                      leg.load &&
                      leg.trailerDetails &&
                      leg.isLoadingInTrailer &&
                      leg.trailerDetails.loadingListItems
                        ? leg.trailerDetails.loadingListItems.map(
                            (loadingListItem): LoadingListItemInput => ({
                              locationId: _toNumber(loadingListItem.locationId),
                              packages: _toNumber(loadingListItem.packages),
                              pallets: _toNumber(loadingListItem.pallets),
                              palletSpace: _toNumber(
                                loadingListItem.palletSpace,
                              ),
                              weight: _toNumber(loadingListItem.weight),
                              note: loadingListItem.note,
                              checked: loadingListItem.checked,
                              isDangerous: loadingListItem.isDangerous,
                              classification: loadingListItem.classification,
                              unNumber: _toNumberOrUndefined(
                                loadingListItem.unNumber,
                              ),
                              isLimitedQty: loadingListItem.isLimitedQty,
                            }),
                          )
                        : [],
                    registrationNumber: leg.trailerDetails?.registrationNumber,
                  },
                  loadingListItems: [],
                };
              }),
            };
          }),
          subcontractorId: values.subcontractorId as number,
          name: values.name
            ? values.name
            : values.routes
            ? values.routes.map((r) => r.routeId).join('//')
            : 'NewTour(DefaultName)',
        },
      },
    });

    if (createTourError == null) {
      router.push(`/traffic/tours/${data?.createTourTemplate.id}`);
    }
  };

  const initialValues = (): Omit<TourTemplateFormInput, 'subcontractorId'> & {
    subcontractorId?: number;
  } => ({
    currency: getCurrencyFromDepartment(department.id),
    subcontractorId: copyTourDataTourTemplate?.subcontractorId,
    dispatcherId: copyTourDataTourTemplate?.dispatcherId,
    note: copyTourDataTourTemplate?.note ?? '',
    driverName: '',
    driverPhoneNumber: '',
    licensePlate: '',
    price: copyTourDataTourTemplate?.price,
    responsiblePerson: copyTourDataTourTemplate?.responsiblePerson ?? '',
    groupingTag: copyTourDataTourTemplate?.groupingTag ?? '',
    isPriceEquallyDivided: false,
    routes: copyTourDataTourTemplate?.routes
      ? copyTourRoutesArray(
          departmentsData?.departments,
          copyTourDataTourTemplate?.routes,
        )
      : [newTourRoute(departmentsData?.departments)],
    startDate: '',
    name: copyTourDataTourTemplate?.name ?? '',
    id: '',
    isNewVersion: true,
  });

  return (
    <Grid container spacing={5} direction="column">
      <HelmetComponent
        title={`${t('actions.create.capitalized')} ${t(
          'resource.tour.capitalized',
        )} ${t('resource.template.lowercased')}`}
      />

      <Grid item>
        <Typography variant="h1">
          {`${t('resource.tour.capitalized')} ${t(
            'resource.template.lowercased',
          )}`}
        </Typography>
      </Grid>
      <Grid item>
        <TourTemplateForm
          onSubmit={onSubmit}
          initialValues={initialValues()}
          departments={departmentsData.departments}
          submitButtonLabel={t('button.create', {
            item: `${t('resource.tour.capitalized')} ${t(
              'resource.template.lowercased',
            )}`,
          })}
        />
      </Grid>
    </Grid>
  );
}
