import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Snackbar,
  TextField,
  MenuItem,
  Button,
  Theme,
  Box,
} from '@material-ui/core';
import {
  GridFilterModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { HelmetComponent } from '../components/HelmetComponent';
import { SelectDateRangeForm } from '../components/SelectDateRangeForm';
import {
  RouteType,
  useUpdateDriverMutation,
  useUpdateExtraColumnsMutation,
  useUpdateInvoiceMutation,
  useUpdateRouteGroupTagMutation,
  useUpdateRouteLegDataColumnsMutation,
} from '../generated/graphql';
import { Alert } from '@material-ui/lab';
import { TABLE_NAMES } from '../lib/constants';
import { DATE_FORMAT } from '../lib/date_time';
import { useHttpClient } from '../providers/HttpClientProvider';
import { routeColumns } from '../utils/RouteColumns';
import { ListRoutes } from './SporadicRoutes';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& p:first-child': {
        maxWidth: '75%',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        inlineSize: '75%',
      },
    },
    muiOdd: {
      backgroundColor: '#e7e7e7',
      '&.Mui-selected': {
        border: '1px solid #66bb6a',
      },
    },
    muiEven: {
      backgroundColor: '#fff',
      '&.Mui-selected': {
        border: '1px solid #66bb6a',
      },
    },
    formRoot: {
      display: 'flex',
      padding: theme.spacing(1, 4),
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
  }),
);

export function FixedRoutes() {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const { httpClient } = useHttpClient();
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { setFilterForFixed, updateSortConfiguration, getCustomConfiguration } =
    useUserConfiguration();
  const filterConfiguration = getCustomConfiguration(
    TABLE_NAMES.FixedRoutes,
    'countryFilter',
  ) as string;
  const [gridHeight, setGridHeight] = useState(0);
  const [fromCountry, setFromCountry] = useState('');
  const [toCountry, setToCountry] = useState('');
  const [gridFilter, setGridFilter] = useState(true);

  const filterCountry =
    filterConfiguration && filterConfiguration !== '#'
      ? `${filterConfiguration.split('#')[0]}-${
          filterConfiguration.split('#')[1]
        }`
      : '';

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [startDate, setStartDate] = useState(format(new Date(), DATE_FORMAT));
  const [endDate, setEndDate] = useState(format(new Date(), DATE_FORMAT));
  const [refreshGrid, setRefreshGrid] = useState<boolean>(false);
  const [routeData, setRouteData] = useState<ListRoutes[]>([]);
  const [totalRoutesCount, setTotalRoutesCount] = useState<number>(0);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [showAssigned, setShowAssigned] = useState<boolean>(false);
  const [filteredRouteData, setFilteredRouteData] = useState<ListRoutes[]>([]);
  const [errorObj, setErrorObj] = useState<{
    error: boolean;
    message: string;
  }>({
    error: false,
    message: '',
  });
  const getAllData = (
    startDate: string,
    endDate: string,
    showLoading = false,
  ) => {
    if (showLoading) setLoadingState(true);

    httpClient
      .getListRoutes({
        startDate: startDate,
        endDate: endDate,
        type: RouteType.Fixed,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setRouteData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setRouteData([]);
          setErrorObj({
            error: true,
            message: `${res.data.message}`,
          });
          console.error('# Fixed Routes Res error=', res.data.message);
        }
        if (showLoading) setLoadingState(false);
      })
      .catch((e) => {
        console.error('# Fixed error=', e);
        setLoadingState(false);
      });
  };

  useEffect(() => {
    const element = document.getElementById('dispatchRouteGrid');
    const positions = element?.getBoundingClientRect();
    if (positions) {
      setGridHeight(window.innerHeight - (positions.top + positions.top / 5));
    }
  }, []);

  useEffect(() => {
    const filteredRoutes = routeData.filter((field) => field.assigned === true);
    showAssigned
      ? setFilteredRouteData(filteredRoutes)
      : setFilteredRouteData(routeData);
  }, [showAssigned, routeData]);

  useEffect(() => {
    if (filterConfiguration && filterConfiguration !== '#') {
      setFromCountry(filterConfiguration.split('#')[0]);
      setToCountry(filterConfiguration.split('#')[1]);
      setFilterModel({
        items: [
          {
            id: 'fromToToCountry',
            columnField: 'fromToToCountry',
            value: filterCountry,
            operatorValue: 'equals',
          },
        ],
      });
      setGridFilter(false);
    }
  }, [filterConfiguration]);
  const [updateInvoice] = useUpdateInvoiceMutation();
  const [updateRouteGroupTag] = useUpdateRouteGroupTagMutation();
  const [updateDriver] = useUpdateDriverMutation();
  const [updateExtraColumns] = useUpdateExtraColumnsMutation();
  const [updateRouteLegDataColumns] = useUpdateRouteLegDataColumnsMutation();

  const handleFilterChanged = (fromCountry: string, toCountry: string) => {
    let newFilterModel: GridFilterModel;
    if (gridFilter) {
      newFilterModel = {
        items: [
          {
            id: 'fromToToCountry',
            columnField: 'fromToToCountry',
            value: `${fromCountry}-${toCountry}`,
            operatorValue: 'equals',
          },
        ],
      };
      setFilterForFixed(fromCountry, toCountry);
      setFilterModel(newFilterModel);
    } else {
      setFromCountry('');
      setToCountry('');
      newFilterModel = { items: [] };
      setFilterForFixed('', '');
      setFilterModel(newFilterModel);
    }
  };

  const handleDateRangeChanged = useCallback((values) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setRefreshGrid((current) => !current);
  }, []);

  useEffect(() => {
    getAllData(startDate, endDate, true);
  }, [refreshGrid]);

  const handleCellEditCommit = useCallback(
    ({ id, field, value }) => {
      if (['invoiceNumber', 'invoiceNote', 'agreedPrice'].includes(field)) {
        updateInvoice({
          variables: {
            id: parseInt(id, 10),
            invoiceData: {
              [field]: value,
            },
          },
        }).then(() => {
          getAllData(startDate, endDate);
        });
      } else if (['routeGroupTag'].includes(field)) {
        updateRouteGroupTag({
          variables: {
            id: parseInt(id, 10),
            routeGroupTag: value,
          },
        }).then((res) => {
          if (res.data && res.data.updateRouteGroupTag === false) {
            //Route locked error
            setErrorAlert(true);
          }
          getAllData(startDate, endDate);
        });
      } else if (
        ['driverName', 'driverPhoneNumber', 'licensePlate'].includes(field)
      ) {
        updateDriver({
          variables: {
            id: parseInt(id, 10),
            driverData: {
              [field]: value,
            },
          },
        }).then(() => {
          getAllData(startDate, endDate);
        });
      } else if (
        [
          'turnummer',
          'togref',
          'bpx',
          'pri03',
          'pri49',
          'upri03',
          'upri49',
          'parti',
          'brev',
          'pru',
          'rutekode',
          'trainNumber',
          'inneholdsbeskrivelse',
          'containerId',
        ].includes(field)
      ) {
        updateExtraColumns({
          variables: {
            id: parseInt(id, 10),
            extraColumnData: {
              [field]: value,
            },
          },
        }).then(() => {
          getAllData(startDate, endDate);
        });
      } else if (
        ['carRegistrationNumber', 'trailerRegistrationNumber'].includes(field)
      ) {
        const row = apiRef.current.getRow(id);
        updateRouteLegDataColumns({
          variables: {
            id: row.firstLegId,
            routeLegData: {
              [field]: value,
            },
          },
        }).then(() => {
          getAllData(startDate, endDate);
        });
      }
    },
    [startDate, endDate],
  );

  const handleSortingModelChange = (model: GridSortModel) => {
    setSortModel(model);
    updateSortConfiguration(TABLE_NAMES.FixedRoutes, model);
  };

  const onStateChangeHandler = (state: any) => {
    const visibleRows = state.filter.visibleRowsLookup;
    const visibleItems: any[] = [];
    for (const [id, value] of Object.entries(visibleRows)) {
      if (value === true) {
        visibleItems.push(id);
      }
    }
    if (visibleItems && visibleItems.length > 0) {
      setTotalRoutesCount(visibleItems.length);
    } else {
      setTotalRoutesCount(0);
    }
  };

  if (errorObj.error) {
    return <div>{errorObj.message}</div>;
  }

  return (
    <Grid container spacing={3}>
      <HelmetComponent title={t('resource.dispatchedRoute.plural')} />
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={errorAlert}
          key={'alertUpdated'}
          autoHideDuration={3000}
          onClose={() => {
            setErrorAlert(false);
          }}
        >
          <Alert
            onClose={() => {
              setErrorAlert(false);
            }}
            severity="error"
          >
            {`${t('attributes.routeIsLocked')}`}
          </Alert>
        </Snackbar>
      </div>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Typography variant="h1">
          {t('resource.dispatchedRoute.plural')}
        </Typography>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <SelectDateRangeForm
            startDate={startDate}
            endDate={endDate}
            onDateSelected={handleDateRangeChanged}
            buttonText={t('actions.apply')}
          />
        </Grid>
        <Grid item xs={5} className={classes.formRoot}>
          <TextField
            id="from-country"
            style={{ width: 120, marginLeft: 0, padding: 0 }}
            select
            size="small"
            variant="standard"
            value={fromCountry}
            label={t(`attributes.fromCountry`)}
            onChange={(event) => {
              if (
                fromCountry &&
                fromCountry !== (event.target.value as string)
              ) {
                setGridFilter(true);
              }
              setFromCountry(event.target.value as string);
            }}
          >
            <MenuItem value="SE">{t('countryInitial.sweden')}</MenuItem>
            <MenuItem value="DK">{t('countryInitial.denmark')}</MenuItem>
            <MenuItem value="NO">{t('countryInitial.norway')}</MenuItem>
            <MenuItem value="FI">{t('countryInitial.finland')}</MenuItem>
            <MenuItem value="NL">{t('countryInitial.netherland')}</MenuItem>
          </TextField>
          <TextField
            id="to-country"
            style={{ width: 100, marginLeft: 10 }}
            select
            size="small"
            variant="standard"
            value={toCountry}
            label={t(`attributes.toCountry`)}
            onChange={(event) => {
              if (toCountry && toCountry !== (event.target.value as string)) {
                setGridFilter(true);
              }
              setToCountry(event.target.value as string);
            }}
          >
            <MenuItem value="SE">{t('countryInitial.sweden')}</MenuItem>
            <MenuItem value="DK">{t('countryInitial.denmark')}</MenuItem>
            <MenuItem value="NO">{t('countryInitial.norway')}</MenuItem>
            <MenuItem value="FI">{t('countryInitial.finland')}</MenuItem>
            <MenuItem value="NL">{t('countryInitial.netherland')}</MenuItem>
          </TextField>
          <Button
            disabled={fromCountry != '' && toCountry != '' ? false : true}
            variant="contained"
            color="primary"
            onClick={() => {
              handleFilterChanged(fromCountry, toCountry);
              setGridFilter(!gridFilter);
            }}
          >
            {gridFilter ? t(`attributes.filter`) : t(`attributes.reset`)}
          </Button>
        </Grid>
        <Grid item xs={2} className={classes.formRoot}>
          <Button
            variant={showAssigned ? 'outlined' : 'contained'}
            color="primary"
            onClick={() => {
              setShowAssigned((assigned) => !assigned);
            }}
          >
            {showAssigned ? 'Assigned only' : 'ALL'}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={3} className={classes.formRoot}>
          <Typography
            style={{
              fontWeight: 'bolder',
              display: 'inline-block',
              marginTop: '5px',
            }}
          >
            {`Total Routes : `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
                marginTop: '5px',
              }}
            >
              {totalRoutesCount}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid item container id="dispatchRouteGrid">
        {loadingState ? (
          <div>Loading...</div>
        ) : (
          <Box
            style={{
              height: gridHeight ? gridHeight : 600,
              width: '100%',
            }}
          >
            <UserConfiguredDataGridPro
              tableName={TABLE_NAMES.FixedRoutes}
              rows={filteredRouteData || []}
              apiRef={apiRef}
              disableColumnReorder={false}
              columns={routeColumns({
                t,
                path,
                apiRef,
                classes,
                isForDispatched: true,
                updateExtraColumns,
                getAllData,
                startDate,
                endDate,
              })}
              onCellEditCommit={handleCellEditCommit}
              rowHeight={50}
              disableSelectionOnClick
              filterModel={filterModel}
              sortModel={sortModel}
              onSortModelChange={(model) => handleSortingModelChange(model)}
              onFilterModelChange={(model) => setFilterModel(model)}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? classes.muiEven
                  : classes.muiOdd
              }
              onStateChange={(state) => onStateChangeHandler(state)}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
