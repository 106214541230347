import { Button, Checkbox } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import {
  GridCellEditCommitParams,
  GridCellParams,
  GridColDef,
  GridDensityTypes,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PageviewIcon from '@material-ui/icons/Pageview';
import CheckIcon from '@mui/icons-material/Check';
import UnLockIcon from '@mui/icons-material/LockOpen';
import PingIcon from '@mui/icons-material/NotificationsActiveOutlined';
import {
  useGetLegsMatchingShipmentLazyQuery,
  usePingRouteLockUserLazyQuery,
  useUpdateLockMutation,
  useRouteLockSubSubscription,
} from '../../generated/graphql';
import { TABLE_NAMES } from '../../lib/constants';
import { UserConfiguredDataGridPro } from '../datagrid/UserConfiguredDataGridPro';
import { format } from 'date-fns';
import { DialogModal } from '../DialogModal';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '88vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface LinkShipmentModalProps {
  fromLocationId: number;
  toLocationId: number;
  transportDate: string;
  handleClose: () => void;
  open: boolean;
  onSelectConfirm: (
    routeId: number,
    legId: number,
    loadInCar: boolean,
    loadInTrailer: boolean,
  ) => void;
}

interface OrdersObj {
  id: number;
  routeID: number;
  legPos: number;
  routeName: string;
  routeType: string;
  isLocked: boolean;
  viewRoutePath: string;
  routeLockedBy: string;
  routeLockedAt: Date | string;
  isCheckListLocked: boolean;
  showPingIcon: boolean;
  unLockRoute: boolean;
  isLoadingInCar: boolean;
  isLoadingInTrailer: boolean;
  loadInCar: boolean;
  loadInTrailer: boolean;
}

export function LinkShipmentModal(props: LinkShipmentModalProps) {
  const {
    handleClose,
    open,
    onSelectConfirm,
    fromLocationId,
    toLocationId,
    transportDate,
  } = props;

  const { t } = useTranslation();

  const [updateLockMutation] = useUpdateLockMutation();
  const [pingToUnlockApi] = usePingRouteLockUserLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [openUnlockAnywayDialog, setOpenUnlockAnywayDialog] =
    useState<boolean>(false);
  const [dialogRouteId, setDialogRouteId] = useState<number>(0);
  const [linkObj, setLinkObj] = useState({
    routeId: 0,
    legId: 0,
    loadInCar: false,
    loadInTrailer: false,
  });
  const [rows, setRows] = useState<OrdersObj[]>([]);
  let availableRoutesIds: number[] = [];
  const [getMatchingLegsApi, { data, error, loading }] =
    useGetLegsMatchingShipmentLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    getMatchingLegsApi({
      variables: {
        fromLocationId,
        toLocationId,
        transportDate,
      },
    });
  }, []);

  const refetchList = useCallback(() => {
    getMatchingLegsApi({
      variables: {
        fromLocationId,
        toLocationId,
        transportDate,
      },
    });
  }, []);

  useRouteLockSubSubscription({
    variables: {
      routeId: 0,
    },
    async onSubscriptionData({ subscriptionData }) {
      if (subscriptionData.data && subscriptionData.data.routeLockSub) {
        if (
          availableRoutesIds.includes(
            subscriptionData.data.routeLockSub.routeId,
          )
        ) {
          refetchList();
        }
      }
    },
  });

  const unlockAnyway = (routeId: number) => {
    if (routeId !== 0) {
      updateLockMutation({
        variables: {
          routeId: routeId,
          lock: 0,
          reload: 1,
        },
      });
    }
  };

  const pingToUnlock = (routeId: number) => {
    pingToUnlockApi({
      variables: {
        routeId: routeId,
      },
    });
  };
  let gridRows: OrdersObj[];
  useEffect(() => {
    if (data) {
      const orders = data.fetchLegsMatchingShipment.map((leg) => {
        const isLocked =
          leg.route.routeLock != null && leg.route.routeLock?.lockedBy != '';
        const viewRoutePath = `${leg.route.id}-${leg.arrivalTime}`;
        const routeLockedBy =
          leg.route.routeLock != null && leg.route.routeLock?.lockedBy != ''
            ? leg.route.routeLock.lockedBy
            : '';
        const routeLockedAt =
          leg.route.routeLock != null && leg.route.routeLock?.lockedBy != ''
            ? new Date(leg.route.routeLock?.lockedAt as string)
            : '';
        const isCheckListLocked =
          leg.route.routeLock != null &&
          leg.route.routeLock?.lockedBy != '' &&
          leg.route.routeLock?.isChecklistLocked == true
            ? true
            : false;
        const showPingIcon =
          leg.route.routeLock == null ||
          leg.route.routeLock?.lockedBy == '' ||
          leg.route.routeLock?.isChecklistLocked == true
            ? false
            : true;
        const unLockRoute =
          leg.route.routeLock == null ||
          leg.route.routeLock?.lockedBy == '' ||
          leg.route.routeLock?.isChecklistLocked == true
            ? false
            : true;
        return {
          id: leg.id,
          legPos: leg.position,
          routeName: leg.route?.routeId,
          routeType: leg.route?.type,
          isLocked: isLocked,
          viewRoutePath: viewRoutePath,
          routeLockedBy: routeLockedBy,
          routeLockedAt: routeLockedAt,
          isCheckListLocked: isCheckListLocked,
          showPingIcon: showPingIcon,
          unLockRoute: unLockRoute,
          isLoadingInCar: leg.isLoadingInCar ? true : false,
          isLoadingInTrailer: leg.isLoadingInTrailer ? true : false,
          routeID: leg.route.id,
          loadInCar: false,
          loadInTrailer: false,
        };
      });
      gridRows = orders;
      setRows([...orders]);
    }
  }, [data]);
  const handleCellEditCommit = (params: GridCellEditCommitParams) => {
    const finalRows: OrdersObj[] = [];
    gridRows.forEach((row) => {
      if (row.id === params.id) {
        if (params.field === 'isLoadingInCar') {
          finalRows.push({
            ...row,
            loadInCar: params.value ? true : false,
            loadInTrailer: false,
          });
          if (params.value)
            setLinkObj({
              routeId: row.routeID,
              legId: row.id,
              loadInCar: true,
              loadInTrailer: false,
            });
        }
        if (params.field === 'isLoadingInTrailer') {
          finalRows.push({
            ...row,
            loadInTrailer: params.value ? true : false,
            loadInCar: false,
          });
          if (params.value)
            setLinkObj({
              routeId: row.routeID,
              legId: row.id,
              loadInCar: false,
              loadInTrailer: true,
            });
        }
      } else {
        finalRows.push({ ...row, loadInCar: false, loadInTrailer: false });
      }
    });
    setRows(finalRows);
    gridRows = finalRows;
  };

  const gridColumns: GridColDef[] = [
    {
      field: 'isLoadingInCar',
      headerName: 'Attach to Car',
      width: 90,
      sortable: false,
      filterable: false,
      editable: true,
      renderCell: (params) => {
        if (params.row.isLocked) return null;
        else
          return (
            <Checkbox
              style={{ padding: 0, color: 'green' }}
              checked={params.row.loadInCar}
              size="small"
              key={`${params.row.id}Car`}
              onChange={() =>
                handleCellEditCommit({
                  ...params,
                  value: params.row.loadInCar ? false : true,
                })
              }
            />
          );
      },
    },
    {
      field: 'isLoadingInTrailer',
      headerName: 'Attach to Trailer',
      width: 90,
      sortable: false,
      filterable: false,
      editable: true,
      renderCell: (params) => {
        if (params.row.isLocked || !params.row?.isLoadingInTrailer) return null;
        else
          return (
            <Checkbox
              style={{ padding: 0, color: 'green' }}
              checked={params.row.loadInTrailer}
              size="small"
              key={`${params.row.id}Trailer`}
              color="primary"
              onChange={() =>
                handleCellEditCommit({
                  ...params,
                  value: params.row.loadInTrailer ? false : true,
                })
              }
            />
          );
      },
    },
    {
      field: 'id',
      type: 'string',
      headerName: 'legID',
      minWidth: 100,
    },
    {
      field: 'legPos',
      type: 'string',
      headerName: 'legPosition',
      minWidth: 100,
    },
    {
      field: 'routeName',
      type: 'string',
      headerName: 'RouteName',
      minWidth: 100,
    },
    {
      field: 'routeType',
      type: 'string',
      headerName: 'RouteType',
      minWidth: 100,
    },
    {
      field: '',
      headerName: 'View Route',
      minWidth: 100,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.view')}`}>
              <Link
                to={`/terminal/routes/${params.row.viewRoutePath}`}
                target={'_blank'}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PageviewIcon color="action" />
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'routeLockedBy',
      type: 'string',
      headerName: 'LockedBy',
      minWidth: 100,
    },
    {
      field: 'routeLockedAt',
      type: 'string',
      headerName: 'LockedAt',
      minWidth: 100,
      valueFormatter({ value }) {
        if (value != '') return format(value as Date, 'dd-MM-yyyy HH:mm');
      },
    },
    {
      field: 'isChecklistLocked',
      headerName: 'ChecklistLocked',
      minWidth: 80,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        if (row.isChecklistLocked) return <CheckIcon />;
        else return null;
      },
    },
    {
      field: 'ping',
      headerName: 'Ping',
      minWidth: 80,
      disableColumnMenu: true,
      type: 'actions',
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        if (!row.showPingIcon) return null;
        else
          return (
            <Tooltip title={`${t('attributes.pingUser')}`}>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => {
                  pingToUnlock(row.routeID);
                }}
              >
                <PingIcon />
              </IconButton>
            </Tooltip>
          );
      },
    },
    {
      field: 'unlock',
      headerName: 'Unlock',
      minWidth: 80,
      disableColumnMenu: true,
      type: 'actions',
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        if (!row.unLockRoute) return null;
        else
          return (
            <Tooltip title={`${t('attributes.unlockAnyway')}`}>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => {
                  setDialogRouteId(row.routeID);
                  setOpenUnlockAnywayDialog(true);
                }}
              >
                <UnLockIcon />
              </IconButton>
            </Tooltip>
          );
      },
    },
  ];

  if (data && data.fetchLegsMatchingShipment) {
    availableRoutesIds = data.fetchLegsMatchingShipment.map(
      (sh) => sh.route.id,
    );
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        {error && <div>{error.message}</div>}

        {data && (
          <>
            <DialogModal
              open={openUnlockAnywayDialog}
              setOpen={setOpenUnlockAnywayDialog}
              contentText={String.raw`${t(
                'validation.unlockConfirmation',
              )}this route?  ${t('validation.unlockWarning')}`}
              doAction={() => {
                setOpenUnlockAnywayDialog(false);
                unlockAnyway(dialogRouteId);
              }}
              buttonText={t('button.yes')}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="h6">
                {'Link LoadingItem - Choose one leg'}
              </Typography>

              <IconButton style={{ marginBottom: 2 }} onClick={handleClose}>
                <CloseIcon fontSize="large" color="action" />
              </IconButton>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems={'center'}
              width="60%"
            >
              <Typography style={{ fontSize: 13, color: 'orange' }}>
                {'You can not link - If the route is locked'}
              </Typography>
            </Box>

            <div style={{ width: '100%', marginBottom: 8 }}>
              <UserConfiguredDataGridPro
                tableName={TABLE_NAMES.MatchingShipment}
                rows={rows}
                loading={loading}
                isRowSelectable={(params: GridRowParams) => {
                  if (
                    params.row.routeLockedBy &&
                    params.row.routeLockedBy != ''
                  ) {
                    return false;
                  }
                  return true;
                }}
                disableMultipleSelection
                disableSelectionOnClick
                columns={gridColumns}
                density={GridDensityTypes.Compact}
                autoHeight={true}
                // onCellEditCommit={handleCellEditCommit}
              />
            </div>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignSelf="flex-end | end"
              width="100%"
              mt={1}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={linkObj.legId ? false : true}
                onClick={() => {
                  onSelectConfirm(
                    linkObj.routeId,
                    linkObj.legId,
                    linkObj.loadInCar,
                    linkObj.loadInTrailer,
                  );
                  handleClose();
                }}
              >
                {t('button.confirmAndLink')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}
