import { TFunction } from 'i18next';
import * as yup from 'yup';
import { validationSchemaRouteCostAllocation } from '../../components/form/CostAllocationFields';
import { LegFieldInput } from '../../components/form/LegFields';
import { CURRENCIES } from '../constants';
import {
  loadingListItemYupValidation,
  terminalLoadingListItemYupValidation,
} from './loadingListItem';

export const routeValidationSchema = (
  t: TFunction,
  withCostAllocation = false,
  isTerminalRoute = false,
) =>
  yup.object().shape({
    transportationDate: yup.date().required(
      t('validation.isRequired', {
        name: t('attributes.transportationDate'),
      }),
    ),
    agreedPrice: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(
        0,
        t('validation.minNumber', {
          name: t('attributes.agreedPrice'),
          len: '0',
        }),
      ),
    currency: yup.string().oneOf(Object.keys(CURRENCIES)),
    note: yup.string(),
    driverName: yup.string(),
    driverPhoneNumber: yup.string(),
    licensePlate: yup.string(),
    capacity: isTerminalRoute
      ? yup
          .number()
          .required(
            t('validation.isRequired', { name: t('attributes.capacity') }),
          )
          .min(0)
      : yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
          .min(0),
    routeId: yup
      .string()
      .min(3)
      .required(t('validation.isRequired', { name: t('attributes.routeId') })),
    invoiceNumber: yup.string().optional().nullable(),
    invoiceNote: yup.string().optional().nullable(),
    dispatcherId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    subcontractorId: yup
      .number()
      .nullable()
      .required(
        t('validation.isRequired', { name: t('attributes.subcontractorId') }),
      ),
    legs: yup
      .array(
        yup.object().shape({
          arrivalTime: yup
            .string()
            .nullable()
            .matches(/^\d{2}:\d{2}(:\d{2})?$/, t('validation.timeFormatError'))
            .required(
              t('validation.isRequired', {
                name: t('attributes.arrivalTime'),
              }),
            ),
          departureTime: yup
            .string()
            .nullable()
            .matches(/^\d{2}:\d{2}(:\d{2})?$/, t('validation.timeFormatError')),
          locationId: yup.number().required(
            t('validation.isRequired', {
              name: t('attributes.location'),
            }),
          ),
          note: yup.string(),
          load: yup.boolean(),
          unload: yup.boolean(),
          isLoadingInCar: yup.boolean().test('isLoadingInCar', (value, ctx) => {
            const contextGeneric = ctx as unknown as {
              from: { value: Record<string, unknown> }[];
            };
            const leg = contextGeneric.from[0]
              .value as unknown as LegFieldInput;

            if (
              leg.load == true &&
              leg.isLoadingInCar == false &&
              leg.isLoadingInTrailer == false
            ) {
              return ctx.createError({
                path: ctx.path,
                message: `Load must be selected`,
              });
            } else return true;
          }),
          isLoadingInTrailer: yup
            .boolean()
            .test('isLoadingInTrailer', (value, ctx) => {
              const contextGeneric = ctx as unknown as {
                from: { value: Record<string, unknown> }[];
              };
              const leg = contextGeneric.from[0]
                .value as unknown as LegFieldInput;

              if (
                leg.load == true &&
                leg.isLoadingInCar == false &&
                leg.isLoadingInTrailer == false
              ) {
                return ctx.createError({
                  path: ctx.path,
                  message: `Load must be selected`,
                });
              } else return true;
            }),
          loadingListItems: isTerminalRoute
            ? terminalLoadingListItemYupValidation(t)
            : loadingListItemYupValidation(t),
          carDetails: yup.object().shape({
            loadingListItems: loadingListItemYupValidation(t),
          }),
          trailerDetails: yup.object().shape({
            loadingListItems: loadingListItemYupValidation(t),
          }),
        }),
      )
      .required(),
    files: yup.array(
      yup.object().shape({
        id: yup.number(),
      }),
    ),
    costAllocation: withCostAllocation
      ? validationSchemaRouteCostAllocation()
      : yup.mixed(),
    ftl: yup
      .object({
        cdc: yup.string(),
        cmr: yup.string(),
        currency: yup.string(),
        customerId: yup.number().nullable(),
        customerNumber: yup.string(),
        customerReference: yup.string(),
        invoiceNote: yup.string(),
        price: yup.number().min(0),
      })
      .nullable(),
  });

export const multipleRouteValidationSchema = (
  t: TFunction,
  withCostAllocation = false,
) =>
  yup.object().shape({
    startDate: yup
      .date()
      .transform((curr, orig) =>
        orig === '' || orig === 'Invalid Date' ? null : curr,
      )
      .nullable()
      .when('dateType', {
        is: (dateType: string) => dateType && dateType === 'dr',
        then: yup
          .date()
          .typeError(
            t('validation.isRequired', {
              name: t('attributes.startDate'),
            }),
          )
          .required(
            t('validation.isRequired', {
              name: t('attributes.startDate'),
            }),
          ),
      }),
    endDate: yup
      .date()
      .transform((curr, orig) =>
        orig === '' || orig === 'Invalid Date' ? null : curr,
      )
      .nullable()
      .when('dateType', {
        is: (dateType: string) => dateType && dateType === 'dr',
        then: yup
          .date()
          .typeError(
            t('validation.isRequired', {
              name: t('attributes.endDate'),
            }),
          )
          .required(
            t('validation.isRequired', {
              name: t('attributes.endDate'),
            }),
          )
          .min(yup.ref('startDate')),
      }),
    startExceptionDate: yup
      .date()
      .transform((curr, orig) =>
        orig === '' || orig === 'Invalid Date' ? null : curr,
      )
      .nullable()
      .when(['dateType', 'hasExceptionDates'], {
        is: (dateType: string, hasExceptionDates: boolean) =>
          dateType && dateType === 'dr' && hasExceptionDates,
        then: yup
          .date()
          .typeError(
            t('validation.isRequired', {
              name: t('attributes.startExceptionDate'),
            }),
          )

          .required(
            t('validation.isRequired', {
              name: t('attributes.startExceptionDate'),
            }),
          ),
      }),
    endExceptionDate: yup
      .date()
      .transform((curr, orig) =>
        orig === '' || orig === 'Invalid Date' ? null : curr,
      )
      .nullable()
      .when(['dateType', 'hasExceptionDates'], {
        is: (dateType: string, hasExceptionDates: boolean) =>
          dateType && dateType === 'dr' && hasExceptionDates,
        then: yup
          .date()
          .typeError(
            t('validation.isRequired', {
              name: t('attributes.endExceptionDate'),
            }),
          )

          .required(
            t('validation.isRequired', {
              name: t('attributes.endExceptionDate'),
            }),
          )
          .min(yup.ref('startExceptionDate')),
      }),
    selectedDays: yup.object().when('dateType', {
      is: (dateType: string) => dateType && dateType === 'dr',
      then: yup
        .object()
        .shape({
          mon: yup.boolean(),
          tue: yup.boolean(),
          wed: yup.boolean(),
          thu: yup.boolean(),
          fri: yup.boolean(),
          sat: yup.boolean(),
          sun: yup.boolean(),
        })
        .test('testDays', 'One day needs to be set', (value, ctx) => {
          if (Object.values(value).every((x) => !x)) {
            return ctx.createError({
              path: ctx.path,
              message: `At least one day needs to be set`,
            });
          }
          return true;
        })
        .required(
          t('validation.isRequired', {
            name: t('attributes.days'),
          }),
        ),
    }),
    selectedCalDates: yup.array().when('dateType', {
      is: (dateType: string) => dateType && dateType === 'cd',
      then: yup
        .array()
        .min(1, 'Choose at least one date')
        .required('At least one date is required'),
    }),
    agreedPrice: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(
        0,
        t('validation.minNumber', {
          name: t('attributes.agreedPrice'),
          len: '0',
        }),
      ),
    currency: yup.string().oneOf(Object.keys(CURRENCIES)),
    note: yup.string(),
    driverName: yup.string(),
    driverPhoneNumber: yup.string(),
    licensePlate: yup.string(),
    capacity: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0),
    routeId: yup
      .string()
      .min(3)
      .required(t('validation.isRequired', { name: t('attributes.routeId') })),
    invoiceNumber: yup.string().optional().nullable(),
    invoiceNote: yup.string().optional().nullable(),
    dispatcherId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    subcontractorId: yup
      .number()
      .nullable()
      .required(
        t('validation.isRequired', { name: t('attributes.subcontractorId') }),
      ),
    legs: yup
      .array(
        yup.object().shape({
          arrivalTime: yup
            .string()
            .nullable()
            .matches(/^\d{2}:\d{2}(:\d{2})?$/, t('validation.timeFormatError'))
            .required(
              t('validation.isRequired', {
                name: t('attributes.arrivalTime'),
              }),
            ),
          departureTime: yup
            .string()
            .nullable()
            .matches(/^\d{2}:\d{2}(:\d{2})?$/, t('validation.timeFormatError')),
          locationId: yup.number().required(
            t('validation.isRequired', {
              name: t('attributes.location'),
            }),
          ),
          note: yup.string(),
          load: yup.boolean(),
          loadingListItems: loadingListItemYupValidation(t),
        }),
      )
      .required(),
    files: yup.array(
      yup.object().shape({
        id: yup.number(),
      }),
    ),
    costAllocation: withCostAllocation
      ? validationSchemaRouteCostAllocation()
      : yup.mixed(),
    ftl: yup
      .object({
        cdc: yup.string(),
        cmr: yup.string(),
        currency: yup.string(),
        customerId: yup.number().nullable(),
        customerNumber: yup.string(),
        customerReference: yup.string(),
        invoiceNote: yup.string(),
        price: yup.number().min(0),
      })
      .nullable(),
  });
