import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { format, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../../lib/date_time';
import clsx from 'clsx';
import { SporadicRouteFormInput } from '../RouteFormRHF';
import { useFormContext, useWatch } from 'react-hook-form';
import { CommonTextFieldRHF } from './CommonTextFieldRHF';
import { SelectLocationRHF } from './SelectLocationRHF';
import { CheckboxFieldRHF } from './CheckboxFieldRHF';
import { TimePickerFieldRHF } from './TimePickerFieldRHF';
import { TransportationDateOffsetFieldRHF } from '../TransportationDateOffsetFieldRHF';
import { LoadingListRHF } from '../LoadingListRHF';
import { calculateTransportationAndProductionDates } from '../../lib/route_leg_dates';
import { SelectFuelRHF } from '../SelectFuelRHF';
import { CommonNumberFieldRHF } from './CommonNumberFieldRHF';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(0.5, 0),
  },
  legField: {
    width: '100%',
    fontSize: '10px',
    marginRight: 20,
    marginTop: 18,
    marginBottom: 18,
  },
  field: {
    width: '100%',
    fontSize: '10px',
    margin: 0,
  },
  checkboxLabel: {
    fontSize: theme.typography.fontSize,
  },
  smallCheckboxLabel: {
    color: '#fc3926',
    fontSize: 12,
    marginLeft: 4,
  },
  fieldsRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    marginTop: -8,

    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: 500,
      marginTop: 8,
    },
    '& > *:first-child': {
      width: '100%',
    },
    '& > *:last-child': {
      minWidth: 625,
      flex: 2,
      justifyContent: 'space-between',
    },
  },
  fieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
  transportationWrapper: {
    margin: '0 8px 0 25px',

    '& input': {
      padding: '8px 0',
      width: 101,
      textAlign: 'center',
    },
  },
  noteCheckbox: {
    margin: '0 8px',
  },
  loadingListButton: {
    padding: 0,
  },
  pushRight: {
    marginLeft: 14,
  },
  buttonWrapper: {
    display: 'flex',

    '& > *': {
      width: '24px',
      height: '24px',
      padding: '2px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  divider: {
    height: '5px',
    margin: '2px 0',
    backgroundColor: theme.palette.primary.light,
  },
}));

interface LegFieldsProps {
  disableOffsetCalculation?: boolean;
  readOnly: boolean;
  trafficReadOnly: boolean;
  isFromTerminal: boolean;
  index: number;
  legsLength: number;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  onRemove: (index: number) => void;
  from: string;
  fromCreateRoute?: string;
  isCreateTerminalRoute?: boolean;
  enableEditMode?: boolean;
}

export function LegFieldsRHF(props: LegFieldsProps) {
  const { t } = useTranslation();
  const {
    readOnly,
    trafficReadOnly, //terminalORreadonly
    index,
    legsLength,
    onMoveUp,
    onMoveDown,
    onRemove,
    from,
    disableOffsetCalculation = false,
    fromCreateRoute = '',
    isCreateTerminalRoute = false,
    isFromTerminal,
    enableEditMode = false,
  } = props;
  const classes = useStyles();

  const {
    control,
    setValue: setFieldValue,
    getValues: getFieldProps,
    getFieldState,
  } = useFormContext<SporadicRouteFormInput>();

  //set position dynamically
  setFieldValue(`legs.${index}.position`, index);

  const leg = getFieldProps(`legs.${index}`);
  const deletedByUser = leg.location ? leg.location.deletedBy : '';

  const [legNote, tranDate] = useWatch({
    control,
    name: [
      `legs.${index}.note`,
      `legs.${index}.transportationDate`,
      `legs.${index}.arrivalTime`,
      `legs.${index}.transportationDateOffset`,
      'transportationDate',
    ],
  });
  const [isLegUnload, isLegLoad, isLoadingInCar, isLoadingInTrailer] = useWatch(
    {
      control,
      name: [
        `legs.${index}.unload`,
        `legs.${index}.load`,
        `legs.${index}.isLoadingInCar`,
        `legs.${index}.isLoadingInTrailer`,
      ],
    },
  );

  const [arrivalTime, dateOffset, transportationDateString] = useWatch({
    control,
    name: [
      `legs.${index}.arrivalTime`,
      `legs.${index}.transportationDateOffset`,
      'transportationDate',
    ],
  });

  //for one selection
  useEffect(() => {
    if (isLegLoad && getFieldState(`legs.${index}.load`).isDirty) {
      setFieldValue(`legs.${index}.unload`, false, { shouldTouch: true });
    } else if (isLegLoad && getFieldState(`legs.${index}.load`).isTouched) {
      setFieldValue(`legs.${index}.unload`, false);
    }
  }, [isLegLoad]);

  useEffect(() => {
    if (isLegUnload && getFieldState(`legs.${index}.unload`).isDirty) {
      setFieldValue(`legs.${index}.load`, false, { shouldTouch: true });
    } else if (isLegUnload && getFieldState(`legs.${index}.unload`).isTouched) {
      setFieldValue(`legs.${index}.load`, false);
    }
  }, [isLegUnload]);

  //for dates calculations
  useEffect(() => {
    if (
      disableOffsetCalculation ||
      readOnly ||
      transportationDateString === '' ||
      (arrivalTime != undefined && arrivalTime == 'Invalid Date')
    ) {
      return;
    }
    const allLegs = getFieldProps('legs');

    calculateTransportationAndProductionDates(
      allLegs,
      transportationDateString,
    ).forEach((leg, index) => {
      setFieldValue(`legs.${index}.productionDate`, leg.productionDate);
      setFieldValue(`legs.${index}.transportationDate`, leg.transportationDate);
    });
  }, [arrivalTime, dateOffset]); //transportationDateString

  const [loadingListOpen, setLoadingListOpen] = useState<boolean>(
    leg.loadingListItems.length >= 0,
  );
  const locId: number | undefined =
    getFieldProps(`legs.${index}.locationId`) != undefined
      ? getFieldProps(`legs.${index}.locationId`)
      : 0;
  const isMarkedAsNonArrival = getFieldProps(
    `legs.${index}.isNonArrivalMarked`,
  );
  const nonArrivalMarkedFrom = getFieldProps(
    `legs.${index}.nonArrivalMarkedFrom`,
  );
  const handleClearNote = useCallback(() => {
    setFieldValue(`legs.${index}.note`, undefined);
  }, []);

  return (
    <Box className={classes.root}>
      <Box display="flex">
        <ViewDateField
          label={`${t('attributes.transportationDate')}:`}
          value={tranDate}
        />
      </Box>
      <Box className={classes.fieldsRoot}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={9}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={'flex-start'}
            >
              <SelectLocationRHF
                name={`legs.${index}.locationId`}
                controllerName={`legs.${index}.locationId`}
                className={classes.field}
                readOnly={
                  trafficReadOnly ||
                  (isCreateTerminalRoute && locId != undefined && locId > 0)
                }
                size="small"
                smallFontSize={true}
                fullWidth
                withLink
                locationPath={`legs.${index}.location`}
              />
              <Box
                sx={{
                  marginLeft: 4,
                }}
              >
                <SelectFuelRHF
                  controllerName={`legs.${index}.fuel`}
                  readOnly={trafficReadOnly}
                />
              </Box>
              <Box className={classes.fieldsWrapper}>
                <CheckboxFieldRHF
                  name={`legs.${index}.load`}
                  controllerName={`legs.${index}.load`}
                  label={t('attributes.load')}
                  readOnly={trafficReadOnly || isCreateTerminalRoute}
                  size="small"
                />
                <CheckboxFieldRHF
                  name={`legs.${index}.unload`}
                  controllerName={`legs.${index}.unload`}
                  label={t('attributes.unload')}
                  readOnly={trafficReadOnly || isCreateTerminalRoute}
                  size="small"
                />
              </Box>
            </Box>
          </Grid>

          {fromCreateRoute === 'ViewRoute' && leg.id != undefined && (
            <Grid item xs={3}>
              <Box
                display="flex"
                alignItems="flex-start"
                sx={{
                  marginLeft: 6,
                }}
              >
                {!readOnly && !isFromTerminal ? (
                  <CheckboxFieldRHF
                    name={`legs.${index}.isNonArrivalMarked`}
                    controllerName={`legs.${index}.isNonArrivalMarked`}
                    label={t('attributes.nonArrival')}
                    size="small"
                    isRed={true}
                  />
                ) : isMarkedAsNonArrival ? (
                  <Typography
                    variant="body2"
                    className={classes.smallCheckboxLabel}
                  >
                    {`Non-arrival ${
                      nonArrivalMarkedFrom
                        ? `[Marked from ${nonArrivalMarkedFrom}]`
                        : ''
                    }`}
                  </Typography>
                ) : null}
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item container>
          {deletedByUser ? (
            <Typography variant="body2" className={classes.smallCheckboxLabel}>
              {t('validation.deletedLocation')}
            </Typography>
          ) : null}
        </Grid>

        <Box display="flex" justifyContent="space-between" flexWrap>
          <Box display="flex" flexWrap alignItems="center">
            <TimePickerFieldRHF
              name={`legs.${index}.arrivalTime`}
              controllerName={`legs.${index}.arrivalTime`}
              readOnly={trafficReadOnly}
            />
            {!isCreateTerminalRoute && (
              <TimePickerFieldRHF
                name={`legs.${index}.departureTime`}
                controllerName={`legs.${index}.departureTime`}
                readOnly={trafficReadOnly}
              />
            )}

            {index !== 0 && !isCreateTerminalRoute && (
              <Box className={classes.transportationWrapper}>
                <TransportationDateOffsetFieldRHF
                  name={`legs.${index}.transportationDateOffset`}
                  controllerName={`legs.${index}.transportationDateOffset`}
                  readOnly={trafficReadOnly}
                />
              </Box>
            )}
            {!trafficReadOnly && (
              <Box ml={2} style={{ minWidth: 100 }}>
                <FormControlLabel
                  className={classes.noteCheckbox}
                  value={legNote != null}
                  disabled={readOnly}
                  onChange={() => {
                    if (legNote == null) {
                      setFieldValue(`legs.${index}.note`, '');
                    }
                    if (legNote === '') {
                      setFieldValue(`legs.${index}.note`, undefined);
                    }
                  }}
                  control={
                    <Checkbox
                      style={{ padding: 0 }}
                      checked={legNote != null}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      className={classes.checkboxLabel}
                      color="textPrimary"
                    >
                      {'Add note'}
                    </Typography>
                  }
                />
              </Box>
            )}
            {isLegLoad && !isCreateTerminalRoute && (
              <Tooltip
                title={`${t('button.view', {
                  item: t('resource.loadingList.lowercased'),
                })}`}
              >
                <IconButton
                  className={clsx(classes.loadingListButton, {
                    [classes.pushRight]: index === 0,
                  })}
                  color={loadingListOpen ? 'primary' : undefined}
                  disabled={readOnly && leg.loadingListItems.length === 0}
                  onClick={() => {
                    setLoadingListOpen(!loadingListOpen);
                  }}
                >
                  <ListAltIcon />
                </IconButton>
              </Tooltip>
            )}
            <Box
              ml={2}
              display="flex"
              alignItems="centre"
              justifyContent="flex-end"
            >
              <CommonTextFieldRHF
                name={`legs.${index}.routeDriverName`}
                controllerName={`legs.${index}.routeDriverName`}
                type="string"
                className={classes.legField}
                readOnly={trafficReadOnly}
              />

              <CommonTextFieldRHF
                name={`legs.${index}.routeDriverPhoneNumber`}
                controllerName={`legs.${index}.routeDriverPhoneNumber`}
                type="string"
                className={classes.legField}
                readOnly={trafficReadOnly}
              />
            </Box>
          </Box>
          {!trafficReadOnly && !isCreateTerminalRoute && !isFromTerminal && (
            <Box className={classes.buttonWrapper}>
              <IconButton
                disabled={index === 0}
                onClick={() => {
                  onMoveUp(index);
                }}
                size="small"
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                disabled={index === legsLength - 1}
                onClick={() => {
                  onMoveDown(index);
                }}
              >
                <ArrowDownwardIcon />
              </IconButton>
              {legsLength > 1 && (
                <IconButton
                  onClick={() => {
                    onRemove(index);
                  }}
                  disabled={leg.isAnyLinkedOrder}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {legNote != null && (
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          marginTop={1}
        >
          <CommonTextFieldRHF
            name={`legs.${index}.note`}
            controllerName={`legs.${index}.note`}
            readOnly={trafficReadOnly}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: !readOnly && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear note"
                    onClick={() => handleClearNote()}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      {isLegLoad ? (
        <>
          {/* for car */}
          <>
            <Box display="flex">
              <CheckboxFieldRHF
                name={`legs.${index}.isLoadingInCar`}
                controllerName={`legs.${index}.isLoadingInCar`}
                label={t('attributes.inCarLoad')}
                size="medium"
                readOnly={trafficReadOnly}
              />
            </Box>
            <Grid container justifyContent="flex-start">
              <Grid item xs={5}>
                <CommonNumberFieldRHF
                  name={`legs.${index}.carDetails.gateNumber`}
                  controllerName={`legs.${index}.carDetails.gateNumber`}
                  className={classes.legField}
                  readOnly={trafficReadOnly}
                  style={{ maxWidth: 140 }}
                />

                <CommonTextFieldRHF
                  name={`legs.${index}.carDetails.registrationNumber`}
                  controllerName={`legs.${index}.carDetails.registrationNumber`}
                  type="string"
                  className={classes.legField}
                  readOnly={trafficReadOnly}
                  style={{ maxWidth: 240 }}
                />
              </Grid>

              {isLoadingInCar && (
                <CommonNumberFieldRHF
                  name={`legs.${index}.carDetails.actualCapacity`}
                  controllerName={`legs.${index}.carDetails.actualCapacity`}
                  className={classes.legField}
                  readOnly={trafficReadOnly}
                  style={{ maxWidth: 100 }}
                />
              )}
            </Grid>
            <Box
              width="100%"
              sx={{ bgcolor: enableEditMode ? '#fff3e0' : '' }}
              display={!loadingListOpen ? 'none' : 'block'}
            >
              {isLoadingInCar && (
                <LoadingListRHF
                  key={`car-${index}`}
                  forTask={'CAR'}
                  load={isLegLoad}
                  formikPathIndex={index}
                  readOnly={readOnly}
                  isCreateTerminalRoute={isCreateTerminalRoute}
                  isFromCreate={
                    isCreateTerminalRoute ||
                    fromCreateRoute === 'CreateSporadicRoute' ||
                    fromCreateRoute === 'CreateMultipleSporadicRoute'
                  }
                />
              )}
            </Box>
          </>
          {/* for trailer */}
          <>
            <Box display="flex" alignItems="centre" justifyContent="flex-start">
              <CheckboxFieldRHF
                name={`legs.${index}.isLoadingInTrailer`}
                controllerName={`legs.${index}.isLoadingInTrailer`}
                label={t('attributes.inTrailerLoad')}
                size="medium"
                readOnly={trafficReadOnly}
              />
            </Box>
            {isLoadingInTrailer && (
              <>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={5}>
                    <CommonNumberFieldRHF
                      name={`legs.${index}.trailerDetails.gateNumber`}
                      controllerName={`legs.${index}.trailerDetails.gateNumber`}
                      className={classes.legField}
                      readOnly={trafficReadOnly}
                      style={{ maxWidth: 140 }}
                    />

                    <CommonTextFieldRHF
                      name={`legs.${index}.trailerDetails.registrationNumber`}
                      controllerName={`legs.${index}.trailerDetails.registrationNumber`}
                      type="string"
                      className={classes.legField}
                      readOnly={trafficReadOnly}
                      style={{ maxWidth: 240 }}
                    />
                  </Grid>

                  <CommonNumberFieldRHF
                    name={`legs.${index}.trailerDetails.actualCapacity`}
                    controllerName={`legs.${index}.trailerDetails.actualCapacity`}
                    className={classes.legField}
                    readOnly={trafficReadOnly}
                    style={{ maxWidth: 100 }}
                  />
                </Grid>
                <Box
                  width="100%"
                  sx={{ bgcolor: enableEditMode ? '#fff3e0' : '' }}
                  display={!loadingListOpen ? 'none' : 'block'}
                >
                  <LoadingListRHF
                    key={`tra-${index}`}
                    forTask={'TRAILER'}
                    load={isLegLoad}
                    formikPathIndex={index}
                    readOnly={readOnly}
                    isCreateTerminalRoute={isCreateTerminalRoute}
                    isFromCreate={
                      isCreateTerminalRoute ||
                      fromCreateRoute === 'CreateSporadicRoute' ||
                      fromCreateRoute === 'CreateMultipleSporadicRoute'
                    }
                  />
                </Box>{' '}
              </>
            )}
          </>
        </>
      ) : isLegUnload ? (
        // for unload
        <Grid container justifyContent="flex-start" spacing={1}>
          <Grid item xs={6}>
            <Box
              width="100%"
              style={{
                border: '1px solid #ebebeb',
                borderRadius: 5,
                paddingLeft: 5,
              }}
              component={'fieldset'}
            >
              <legend>CAR</legend>
              <CommonNumberFieldRHF
                name={`legs.${index}.carDetails.gateNumber`}
                controllerName={`legs.${index}.carDetails.gateNumber`}
                className={classes.legField}
                readOnly={trafficReadOnly}
                style={{ maxWidth: 120 }}
              />

              <CommonTextFieldRHF
                name={`legs.${index}.carDetails.registrationNumber`}
                controllerName={`legs.${index}.carDetails.registrationNumber`}
                type="string"
                className={classes.legField}
                readOnly={trafficReadOnly}
                style={{ maxWidth: 200 }}
              />
              <CommonNumberFieldRHF
                name={`legs.${index}.carDetails.actualCapacity`}
                controllerName={`legs.${index}.carDetails.actualCapacity`}
                className={classes.legField}
                readOnly={trafficReadOnly}
                style={{ maxWidth: 100 }}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box
              width="100%"
              style={{
                border: '1px solid #ebebeb',
                borderRadius: 5,
                paddingLeft: 5,
              }}
              component={'fieldset'}
            >
              <legend>TRAILER</legend>
              <CommonNumberFieldRHF
                name={`legs.${index}.trailerDetails.gateNumber`}
                controllerName={`legs.${index}.trailerDetails.gateNumber`}
                className={classes.legField}
                readOnly={trafficReadOnly}
                style={{ maxWidth: 120 }}
              />

              <CommonTextFieldRHF
                name={`legs.${index}.trailerDetails.registrationNumber`}
                controllerName={`legs.${index}.trailerDetails.registrationNumber`}
                type="string"
                className={classes.legField}
                readOnly={trafficReadOnly}
                style={{ maxWidth: 200 }}
              />
              <CommonNumberFieldRHF
                name={`legs.${index}.trailerDetails.actualCapacity`}
                controllerName={`legs.${index}.trailerDetails.actualCapacity`}
                className={classes.legField}
                readOnly={trafficReadOnly}
                style={{ maxWidth: 100 }}
              />
            </Box>
          </Grid>
        </Grid>
      ) : null}

      {isLegLoad &&
      !leg.isNewLegVariant &&
      leg.loadingListItems &&
      leg.loadingListItems.length > 0 &&
      from === 'LegsFields' &&
      leg.loadingListItems.findIndex((ll) => ll.isDangerous === true) !== -1 ? (
        <Typography
          style={{
            marginTop: 6,
            marginBottom: 2,
            fontSize: 12,
            fontWeight: 'bolder',
            color: 'red',
          }}
        >
          {`${t('attributes.warningOfDangerousGoods')}`}
        </Typography>
      ) : isLegLoad &&
        ((leg.isNewLegVariant &&
          leg.carDetails != null &&
          leg.carDetails.loadingListItems &&
          leg.carDetails.loadingListItems.length > 0 &&
          from === 'LegsFields' &&
          leg.carDetails.loadingListItems.findIndex(
            (ll) => ll.isDangerous === true,
          ) !== -1) ||
          (leg.isNewLegVariant &&
            leg.trailerDetails != null &&
            leg.trailerDetails.loadingListItems &&
            leg.trailerDetails.loadingListItems.length > 0 &&
            from === 'LegsFields' &&
            leg.trailerDetails.loadingListItems.findIndex(
              (ll) => ll.isDangerous === true,
            ) !== -1)) ? (
        <Typography
          style={{
            marginTop: 6,
            marginBottom: 2,
            fontSize: 12,
            fontWeight: 'bolder',
            color: 'red',
          }}
        >
          {`${t('attributes.warningOfDangerousGoods')}`}
        </Typography>
      ) : null}
      {legsLength - 1 !== index && <Divider className={classes.divider} />}
    </Box>
  );
}
const useStyleViewDate = makeStyles(() => ({
  root: {
    display: 'flex',

    '& p': {
      fontSize: 12,
      marginRight: 5,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}));

interface ViewDateFieldProps {
  label: string;
  value?: string;
}

function ViewDateField(props: ViewDateFieldProps) {
  const { label, value } = props;
  const classes = useStyleViewDate();

  if (value == null || value === '') {
    return null;
  }

  const date = parse(value, DATE_FORMAT, new Date());
  const dateString = format(date, DATE_FORMAT);

  return (
    <Box className={classes.root}>
      <Typography className={classes.bold}>{label}</Typography>
      <Typography>{dateString}</Typography>
    </Box>
  );
}
