import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Modal,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ReactComponent as NotesIcon } from '../../svgIcons/notesicon.svg';
import { ReactComponent as DangerIcon } from '../../svgIcons/dangericon.svg';
import { ReactComponent as TerminalIcon } from '../../svgIcons/terminalicon.svg';
import { ReactComponent as CustomsIcon } from '../../svgIcons/customsicon.svg';
import OkIcon from '@mui/icons-material/CheckCircle';
import NotOkIcon from '@mui/icons-material/Cancel';
import PageviewIcon from '@material-ui/icons/Pageview';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  FieldArray,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styleForTimeModal } from '../datagrid/LoadingListModal';
import { CheckboxIconField } from '../form/CheckboxIconField';
import { FastCheckbox } from '../form/FastCheckbox';
import { FastTextFieldNote } from '../form/FastTextFieldNote';
import { FastTextFieldPackage } from '../form/FastTextFieldPackage';
import { FastTextFieldPallets } from '../form/FastTextFieldPallets';
import { FastTextFieldPalletSpace } from '../form/FastTextFieldPalletSpace';
import { FastTextFieldWeight } from '../form/FastTextFieldWeight';
import { SelectLocation } from '../form/SelectLocation';
import { SimpleMessageDialog } from '../SimpleMessageDialog';
import { SelectEnumFieldSmall } from '../form/SelectEnumFieldSmall';
import {
  DangerousGoodsClassification,
  EditShipmentLoadingList,
  ShipmentEditInput,
  useDeleteShipmentMutation,
  useGetShipmentByIdLazyQuery,
  useEditShipmentMutation,
  useCreateShipmentMutation,
  CreateShipmentLoadingList,
  useUpdatePartialShipmentMutation,
  useShipmentPartialUpdateSubSubscription,
  CustomsProcedureNew,
} from '../../generated/graphql';
import { FastTextFieldUnNumber } from '../form/FastTextFieldUnNumber';
import { InfoOutlined } from '@material-ui/icons';
import { CommonTextField } from '../form/CommonTextField';
import { CommonDateField } from '../form/CommonDateField';
import { shipmentValidation } from '../../views/Shipment';
import { Maybe } from 'graphql/jsutils/Maybe';
import _ from 'lodash';
import { useUserConfiguration } from '../../providers/UserConfigurationProvider';

const useStylesForLoadingList = makeStyles(() =>
  createStyles({
    greenChecked: {
      backgroundColor: '#d3f8d3',
    },
    redBorder: {
      border: '1px solid red',
    },
    greyBackFocused: {
      backgroundColor: '#f1f5eb',
      paddingTop: 2,
      paddingBottom: 2,
    },
    blueBackReadOnly: {
      backgroundColor: '#d1e7fc',
      paddingTop: 2,
      paddingBottom: 2,
    },
    root: {
      margin: 0,
      padding: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
    noPadding: {
      padding: '0 !important',
    },
    deleteButton: {
      padding: '0 !important',
      marginRight: 1,
    },
    listItem: {
      '&.MuiListItem': {
        padding: 0,
      },
      '&.MuiListItem-gutters': {
        padding: 0,
      },
      '.MuiListItem-root': {
        padding: 0,
      },
    },
    numberFields: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    selecLocationField: {
      width: '98%',
      fontSize: '10px',
      margin: 0,
      padding: 0,
    },
    field: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    iconButton: {
      padding: '0 !important',
      marginRight: 10,
    },
  }),
);

interface editShipmentProps {
  id: number;
  shipmentDate: string;
  showDate: boolean;
  templateUpdated: () => void;
  updatedFrom: string;
}
interface EditLoadingList extends EditShipmentLoadingList {
  addNote: boolean;
  isCustom: boolean;
  fileAttachment: boolean;
  isTerminal: boolean;
  routeName?: Maybe<string>;
  locationName: string;
}

export function EditShipmentModal(props: editShipmentProps) {
  const {
    id,
    showDate,
    templateUpdated,
    updatedFrom,
    shipmentDate: currentShipmentDate,
  } = props;
  const { t } = useTranslation();
  const classes = useStylesForLoadingList();
  const selectedGreen = '#66ab49';
  const dangerRed = '#ff0000';
  const normalGrey = '#6e6e6e';
  const [updateShipmentModal] = useEditShipmentMutation();
  const [updatePartialShipment] = useUpdatePartialShipmentMutation();
  const [createShipment] = useCreateShipmentMutation();
  const [deleteShipment] = useDeleteShipmentMutation();
  //const [modalShipmentId, setModalShipmentId] = useState(0);
  const [viewShipment, setViewShipment] = useState(false);
  const [isUpdateInProgress, setUpdateInProgress] = useState(false);
  const [initObj, setInitObj] = useState<{
    date?: string | null;
    name: string;
    loadingListValue: EditLoadingList[];
    isTemplate: boolean;
    fromLocationId: number;
    toLocationId: number;
  }>({
    date: '',
    name: '',
    loadingListValue: [],
    isTemplate: false,
    fromLocationId: 0,
    toLocationId: 0,
  });
  const [openLimitedQtyInfoDialog, setOpenLimitedQtyInfoDialog] =
    useState<boolean>(false);
  const [currentFocusedIndex, setCurrentFocusedIndex] = useState<number>(-1);
  const [readOnly, setReadOnly] = useState(false);
  const selectedDarkGreen = '#3c9317';
  const [fetchShipment, { data }] = useGetShipmentByIdLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const { myUserId } = useUserConfiguration();
  const shipmentDateStr = initObj.date ?? '2020-01-01';

  const todayDate = new Date().setHours(0, 0, 0, 0);
  const shipmentDateDate = new Date(shipmentDateStr).setHours(0, 0, 0, 0);
  const isPastShipmentDate = todayDate > shipmentDateDate && !showDate;

  //PubSub when field updated
  useShipmentPartialUpdateSubSubscription({
    variables: {
      shipmentId: id,
      userId: `${myUserId}`,
    },
    async onSubscriptionData({ subscriptionData }) {
      if (
        initObj.loadingListValue.length > 0 &&
        subscriptionData.data &&
        subscriptionData.data.shipmentPartialUpdateSub &&
        subscriptionData.data.shipmentPartialUpdateSub.orderId &&
        subscriptionData.data.shipmentPartialUpdateSub.orderId !== 0
      ) {
        //Update only specific order-field in formik
        const orderIndex = initObj.loadingListValue.findIndex(
          (o) =>
            subscriptionData.data &&
            o.id === subscriptionData.data.shipmentPartialUpdateSub.orderId,
        );
        if (
          orderIndex != -1 &&
          subscriptionData.data.shipmentPartialUpdateSub.forFieldValue != ''
        ) {
          const newLLItem = initObj.loadingListValue[orderIndex];
          const fieldName =
            subscriptionData.data.shipmentPartialUpdateSub.forFieldName;

          if (fieldName === 'customWaybillNo') {
            newLLItem.customWaybillNo =
              subscriptionData.data.shipmentPartialUpdateSub.forFieldValue;
          } else if (fieldName === 'packages') {
            newLLItem.packages = parseInt(
              subscriptionData.data.shipmentPartialUpdateSub.forFieldValue,
            );
          } else if (fieldName === 'pallets') {
            newLLItem.pallets = parseInt(
              subscriptionData.data.shipmentPartialUpdateSub.forFieldValue,
            );
          } else if (fieldName === 'palletSpace') {
            newLLItem.palletSpace = parseInt(
              subscriptionData.data.shipmentPartialUpdateSub.forFieldValue,
            );
          } else if (fieldName === 'weight') {
            newLLItem.weight = parseInt(
              subscriptionData.data.shipmentPartialUpdateSub.forFieldValue,
            );
          } else if (fieldName === 'customCleared') {
            newLLItem.customCleared =
              subscriptionData.data.shipmentPartialUpdateSub.forFieldValue ===
              'true';
          } else if (fieldName === 'customClearedForImport') {
            newLLItem.customClearedForImport =
              subscriptionData.data.shipmentPartialUpdateSub.forFieldValue ===
              'true';
          } else if (fieldName === 'terminalCleared') {
            newLLItem.terminalCleared =
              subscriptionData.data.shipmentPartialUpdateSub.forFieldValue ===
              'true';
          }

          setInitObj({
            ...initObj,
            loadingListValue: [...initObj.loadingListValue, newLLItem],
          });
        }
      }
    },
  });

  //Update by Field onBlur/CheckedChange
  const apiPartialUpdate = (
    orderId: Maybe<number> | undefined,
    forField: string,
    newVal: string,
  ) => {
    if (orderId != null) {
      updatePartialShipment({
        variables: {
          input: {
            shipmentId: id,
            shipmentDate: currentShipmentDate,
            orderId: orderId ?? 0,
            forFieldName: forField,
            forFieldValue: newVal,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (data?.shipmentById) {
      const loadingList: EditLoadingList[] = [];
      if (data.shipmentById.loadingListItems) {
        data.shipmentById.loadingListItems.forEach((list) => {
          loadingList.push({
            id: list.id,
            locationId: list.locationId,
            locationName:
              list.location && list.location?.name ? list.location?.name : '',
            note: list.note,
            checked: list.checked,
            isDangerous: list.isDangerous,
            classification: list.classification,
            unNumber: list.unNumber,
            isLimitedQty: list.isLimitedQty,
            pallets: list.pallets,
            weight: list.weight,
            packages: list.packages,
            palletSpace: list.palletSpace,
            connectedNB: list.connectedNB,
            customCmrNo: list.customCmrNo,
            customInvoiceNo: list.customInvoiceNo,
            customMrnNo: list.customMrnNo,
            customNote: list.customNote,
            customVOECId: list.customVOECId,
            customWaybillNo: list.customWaybillNo ?? '',
            customCleared: list.customCleared,
            customClearedForImport: list.customClearedForImport,
            isRemainingGoods: list.isRemainingGoods,
            isVoecCustomer: list.isVoecCustomer,
            terminalCleared: list.terminalCleared,
            terminalNotCleared: list.terminalNotCleared,
            terminalShipmentNotArrived: list.terminalShipmentNotArrived,
            terminalDevInfo: list.terminalDevInfo,
            terminalDevPackages: list.terminalDevPackages,
            terminalDevPallets: list.terminalDevPallets,
            terminalDevPalletSpace: list.terminalDevPalletSpace,
            terminalDevWeight: list.terminalDevWeight,
            isImportedFromShipment:
              list.isImportedFromShipment &&
              list.routeDetail &&
              list.routeDetail.routeName != '',
            addNote: false,
            isCustom: false,
            fileAttachment: false,
            isTerminal: false,
            routeName: list.routeDetail ? list.routeDetail.routeName : '',
            customsProcedure: list.customsProcedure,
          });
        });
      }
      //Any one item is imported OR past date shipment And not template
      setReadOnly(
        loadingList.some((list) => list.isImportedFromShipment) ||
          isPastShipmentDate,
      );
      //setPartialImport()
      setInitObj({
        date: data.shipmentById.shipmentDate,
        name: data.shipmentById.name,
        loadingListValue: _.orderBy(loadingList, (item) => item.locationName, [
          'asc',
        ]),
        isTemplate: data.shipmentById.shipmentDate ? false : true,
        fromLocationId: data.shipmentById.fromLocationId,
        toLocationId: data.shipmentById.toLocationId,
      });
      setViewShipment(true);
    }
  }, [data]);

  const handleBorderVisibility = (index: number) => {
    if (index !== currentFocusedIndex) setCurrentFocusedIndex(index);
  };
  function getChecked(isChecked: boolean): string {
    if (isChecked) return classes.greenChecked;
    else return '';
  }

  function getDangerous(isDangerous: boolean): string {
    if (isDangerous) return classes.redBorder;
    else return '';
  }

  return (
    <>
      <Tooltip title={`View/Modify Shipment`}>
        <IconButton
          style={{ padding: 0 }}
          onClick={() => {
            fetchShipment({ variables: { id: id } });
          }}
        >
          <PageviewIcon color="action" />
        </IconButton>
      </Tooltip>
      {viewShipment && (
        <Modal
          open={viewShipment}
          onClose={() => {
            setViewShipment(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableScrollLock
        >
          <Box sx={styleForTimeModal}>
            <Formik
              initialValues={initObj}
              validate={(value) => {
                try {
                  validateYupSchema(value, shipmentValidation(t), true, value);
                } catch (err) {
                  const errors = yupToFormErrors<{ error: string }>(err);
                  return errors;
                }
                return {};
              }}
              validateOnBlur
              validateOnChange={false}
              onSubmit={(value) => {
                //if initObj.date is true which means we are viewing shipment not Template
                // because in Template date is false
                //So if initObj.date is false means we are viewing template and after submit
                // value.date is true then only we are creating shipment from template
                if (!initObj.date && value.date) {
                  const loadinglist: CreateShipmentLoadingList[] =
                    value.loadingListValue.map((list) => ({
                      id: undefined,
                      locationId: list.locationId,
                      checked: list.checked,
                      packages: list.packages,
                      palletSpace: list.palletSpace,
                      pallets: list.pallets,
                      weight: list.weight,
                      note: list.note,
                      isDangerous: list.isDangerous,
                      classification: list.classification,
                      isLimitedQty: list.isLimitedQty ?? false,
                      isVoecCustomer: list.isVoecCustomer ?? false,
                      isRemainingGoods: list.isRemainingGoods ?? false,
                      unNumber: list.unNumber,
                      customsProcedure: list.customsProcedure,
                    }));

                  createShipment({
                    variables: {
                      input: {
                        id: undefined,
                        name: value.name,
                        shipmentDate: value.date,
                        fromLocationId: value.fromLocationId,
                        toLocationId: value.toLocationId,
                        createdFrom: updatedFrom,
                        loadingListItem: loadinglist,
                      },
                    },
                  }).then(() => {
                    setViewShipment(false);
                    templateUpdated();
                  });
                } else {
                  const loadinglist: EditShipmentLoadingList[] =
                    value.loadingListValue.map((list) => ({
                      id: list.id,
                      checked: list.checked,
                      classification: list.classification,
                      isDangerous: list.isDangerous,
                      isLimitedQty: list.isLimitedQty,
                      locationId: list.locationId,
                      note: list.note,
                      packages: list.packages,
                      palletSpace: list.palletSpace,
                      pallets: list.pallets,
                      weight: list.weight,
                      unNumber: list.unNumber,
                      connectedNB: list.connectedNB,
                      customCmrNo: list.customCmrNo,
                      customInvoiceNo: list.customInvoiceNo,
                      customMrnNo: list.customMrnNo,
                      customNote: list.customNote,
                      customVOECId: list.customVOECId,
                      customWaybillNo: list.customWaybillNo,
                      customCleared: list.customCleared,
                      customClearedForImport: list.customClearedForImport,
                      isRemainingGoods: list.isRemainingGoods,
                      isVoecCustomer: list.isVoecCustomer,
                      terminalCleared: list.terminalCleared,
                      terminalNotCleared: list.terminalNotCleared,
                      terminalShipmentNotArrived:
                        list.terminalShipmentNotArrived,
                      isImportedFromShipment: list.isImportedFromShipment,
                      terminalDevInfo: list.terminalDevInfo,
                      terminalDevPackages: list.terminalDevPackages,
                      terminalDevPallets: list.terminalDevPallets,
                      terminalDevPalletSpace: list.terminalDevPalletSpace,
                      terminalDevWeight: list.terminalDevWeight,
                      customsProcedure: list.customsProcedure,
                    }));
                  const obj: ShipmentEditInput = {
                    id: id,
                    name: value.name,
                    shipmentDate: value.date,
                    fromLocationId: value.fromLocationId,
                    toLocationId: value.toLocationId,
                    updatedFrom: updatedFrom,
                    loadingListItems: loadinglist,
                  };
                  setUpdateInProgress(true);
                  updateShipmentModal({
                    variables: { input: obj },
                  }).then(() => {
                    setUpdateInProgress(false);
                    templateUpdated();
                  });
                }
              }}
            >
              {(props) => {
                return (
                  <Form>
                    <Grid container alignItems="flex-start">
                      <Grid item xs={3}>
                        <CommonTextField name="name" readOnly={readOnly} />
                      </Grid>
                      <Grid item xs={2}>
                        {(initObj.date || showDate) && (
                          <>
                            <CommonDateField
                              name="date"
                              variant="outlined"
                              readOnly={readOnly}
                            />
                            {showDate && (
                              <Typography
                                style={{
                                  fontSize: 12,
                                }}
                                color="textPrimary"
                              >
                                {t('attributes.addDate')}
                              </Typography>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ marginTop: 10 }}>
                      <Grid item xs={5}>
                        <SelectLocation
                          name={'fromLocationId'}
                          fullWidth
                          size="small"
                          label={'toTerminal'}
                          readOnly={readOnly}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <SelectLocation
                          name={'toLocationId'}
                          fullWidth
                          size="small"
                          label={'toTerminal'}
                          readOnly={readOnly}
                        />
                      </Grid>
                    </Grid>

                    <FieldArray
                      name={'loadingListValue'}
                      render={(arrayHelpers) => (
                        <>
                          <Grid
                            item
                            xs={12}
                            spacing={2}
                            container
                            className={classes.root}
                          >
                            <SimpleMessageDialog
                              open={openLimitedQtyInfoDialog}
                              setOpen={setOpenLimitedQtyInfoDialog}
                              title={t('validation.limitedQtyInfoTitle')}
                              contentText={t('validation.limitedQtyInfo')}
                              isMultiLine={true}
                            />
                            <Grid item xs={10}>
                              <Typography variant="subtitle1" align="left">
                                {t('resource.loadingList.capitalized')}
                              </Typography>
                            </Grid>
                            {isPastShipmentDate ? null : (
                              <Grid item xs={2}>
                                <Box
                                  display="flex"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      arrayHelpers.push({
                                        id: undefined,
                                        packages: 0,
                                        pallets: 0,
                                        weight: 0,
                                        palletSpace: 0,
                                        note: '',
                                        locationId: undefined,
                                        checked: false,
                                        isDangerous: false,
                                        classification: undefined,
                                        unNumber: 0,
                                        isLimitedQty: false,
                                        addNote: false,
                                        isCustom: false,
                                        addFiles: false,
                                        isTerminal: false,
                                        customVOECId: '',
                                        customInvoiceNo: '',
                                        customMrnNo: '',
                                        customWaybillNo: '',
                                        customCmrNo: '',
                                        customNote: '',
                                        connectedNB: '',
                                        isVoecCustomer: false,
                                        isRemainingGoods: false,
                                        customCleared: false,
                                        customClearedForImport: false,
                                        terminalCleared: false,
                                        terminalNotCleared: false,
                                        terminalShipmentNotArrived: false,
                                        terminalDevInfo: '',
                                        terminalDevPackages: 0,
                                        terminalDevPallets: 0,
                                        terminalDevPalletSpace: 0,
                                        terminalDevWeight: 0,
                                        fileAttachment: false,
                                        customProcedure: undefined,
                                      });
                                    }}
                                  >
                                    {t('button.add', { item: t('order') })}
                                  </Button>
                                </Box>
                              </Grid>
                            )}

                            {props.values.loadingListValue.length > 0 && (
                              <Grid
                                xs={12}
                                item
                                container
                                style={{
                                  backgroundColor: '#c0c4bc',
                                  marginBottom: 5,
                                  borderRadius: 2,
                                  marginTop: 5,
                                }}
                              >
                                <Grid item container xs={4}>
                                  <Grid item xs={2}>
                                    <Typography
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {'Status'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Typography
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {t('attributes.customer')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item container xs={1}>
                                  <Grid item xs={3}>
                                    <Typography
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {'PCS'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {'PAL'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {'PPL'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {'KG'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {!initObj.isTemplate ? (
                                  <Grid item container xs={3}>
                                    <Grid item xs={7}>
                                      <Typography
                                        variant="body2"
                                        color="textPrimary"
                                      >
                                        {'Cust.WayBill'}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <Typography
                                        variant="body2"
                                        color="textPrimary"
                                      >
                                        {'RouteName'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ) : null}

                                <Grid item container xs={3}>
                                  <Typography
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {'Customs procedure'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                            <Grid
                              item
                              xs={12}
                              style={{ backgroundColor: '#f6fce8' }}
                            >
                              <List className={classes.noPadding}>
                                {props.values.loadingListValue.map(
                                  (_order, index) => {
                                    const isItemReadOnly =
                                      (_order.isImportedFromShipment != null &&
                                        _order.isImportedFromShipment ===
                                          true) ||
                                      isPastShipmentDate;

                                    return (
                                      <>
                                        <ListItem
                                          key={index}
                                          divider
                                          className={`${getChecked(
                                            _order.checked,
                                          )} ${getDangerous(
                                            _order.isDangerous,
                                          )} ${classes.noPadding}`}
                                        >
                                          <Grid
                                            xs={12}
                                            item
                                            container
                                            key={index}
                                            style={{
                                              width: '100%',
                                              marginBottom: 5,
                                            }}
                                            onFocus={(event) => {
                                              if (
                                                !event.currentTarget.contains(
                                                  event.relatedTarget as Node,
                                                )
                                              ) {
                                                handleBorderVisibility(index);
                                              }
                                            }}
                                            onBlur={(event) => {
                                              if (
                                                !event.currentTarget.contains(
                                                  event.relatedTarget as Node,
                                                )
                                              ) {
                                                setCurrentFocusedIndex(-1);
                                              }
                                            }}
                                            className={`${
                                              currentFocusedIndex === index &&
                                              !isItemReadOnly
                                                ? classes.greyBackFocused
                                                : isItemReadOnly
                                                ? classes.blueBackReadOnly
                                                : ''
                                            } ${classes.noPadding}`}
                                          >
                                            <Box
                                              display="flex"
                                              justifyContent="center"
                                              alignItems="flex-start"
                                              style={{ width: '100%' }}
                                            >
                                              <Grid item container xs={4}>
                                                <Grid item xs={1}>
                                                  <Tooltip title="Terminal cleared?">
                                                    <IconButton
                                                      className={
                                                        classes.iconButton
                                                      }
                                                    >
                                                      {_order.terminalCleared ? (
                                                        <OkIcon
                                                          htmlColor={
                                                            selectedDarkGreen
                                                          }
                                                        />
                                                      ) : (
                                                        <NotOkIcon
                                                          htmlColor={dangerRed}
                                                        />
                                                      )}
                                                    </IconButton>
                                                  </Tooltip>
                                                </Grid>
                                                <Grid item xs={1}>
                                                  <Tooltip title="Custom cleared?">
                                                    <IconButton
                                                      className={
                                                        classes.iconButton
                                                      }
                                                    >
                                                      {_order.customCleared &&
                                                      _order.customClearedForImport ? (
                                                        <OkIcon
                                                          htmlColor={
                                                            selectedDarkGreen
                                                          }
                                                        />
                                                      ) : (
                                                        <NotOkIcon
                                                          htmlColor={dangerRed}
                                                        />
                                                      )}
                                                    </IconButton>
                                                  </Tooltip>
                                                </Grid>
                                                <Grid item xs={10}>
                                                  <SelectLocation
                                                    key={`loadingListInitValue_${index}`}
                                                    name={`loadingListValue.${index}.locationId`}
                                                    className={
                                                      classes.selecLocationField
                                                    }
                                                    readOnly={isItemReadOnly}
                                                    size="small"
                                                    smallFontSize
                                                    noLabel
                                                    smallPadding
                                                    shortAddress={true}
                                                  />
                                                </Grid>
                                              </Grid>
                                              <Grid item container xs={1}>
                                                <Grid item xs={3}>
                                                  <FastTextFieldPackage
                                                    noLabel
                                                    size="small"
                                                    smallFontSize
                                                    name={`loadingListValue.${index}.packages`}
                                                    className={
                                                      classes.numberFields
                                                    }
                                                    type="number"
                                                    smallPadding
                                                    readOnly={isItemReadOnly}
                                                    onBlur={(e) => {
                                                      props.setFieldValue(
                                                        `loadingListValue.${index}.packages`,
                                                        parseInt(
                                                          e.target.value,
                                                        ) ?? 0,
                                                      );
                                                      apiPartialUpdate(
                                                        _order.id,
                                                        'packages',
                                                        e.target.value ==
                                                          null ||
                                                          e.target.value == ''
                                                          ? '0'
                                                          : e.target.value,
                                                      );
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item xs={3}>
                                                  <FastTextFieldPallets
                                                    noLabel
                                                    size="small"
                                                    smallFontSize
                                                    name={`loadingListValue.${index}.pallets`}
                                                    className={
                                                      classes.numberFields
                                                    }
                                                    type="number"
                                                    smallPadding
                                                    readOnly={isItemReadOnly}
                                                    onBlur={(e) => {
                                                      props.setFieldValue(
                                                        `loadingListValue.${index}.pallets`,
                                                        parseInt(
                                                          e.target.value,
                                                        ) ?? 0,
                                                      );
                                                      apiPartialUpdate(
                                                        _order.id,
                                                        'pallets',
                                                        e.target.value ==
                                                          null ||
                                                          e.target.value == ''
                                                          ? '0'
                                                          : e.target.value,
                                                      );
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item xs={3}>
                                                  <FastTextFieldPalletSpace
                                                    noLabel
                                                    size="small"
                                                    smallFontSize
                                                    smallPadding
                                                    name={`loadingListValue.${index}.palletSpace`}
                                                    className={
                                                      classes.numberFields
                                                    }
                                                    type="number"
                                                    readOnly={isItemReadOnly}
                                                    onBlur={(e) => {
                                                      props.setFieldValue(
                                                        `loadingListValue.${index}.palletSpace`,
                                                        parseFloat(
                                                          e.target.value,
                                                        ) ?? 0,
                                                      );
                                                      apiPartialUpdate(
                                                        _order.id,
                                                        'palletSpace',
                                                        e.target.value ==
                                                          null ||
                                                          e.target.value == ''
                                                          ? '0'
                                                          : e.target.value,
                                                      );
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item xs={3}>
                                                  <FastTextFieldWeight
                                                    noLabel
                                                    size="small"
                                                    smallFontSize
                                                    smallPadding
                                                    name={`loadingListValue.${index}.weight`}
                                                    className={
                                                      classes.numberFields
                                                    }
                                                    type="number"
                                                    readOnly={isItemReadOnly}
                                                    onBlur={(e) => {
                                                      props.setFieldValue(
                                                        `loadingListValue.${index}.weight`,
                                                        parseFloat(
                                                          e.target.value,
                                                        ) ?? 0,
                                                      );
                                                      apiPartialUpdate(
                                                        _order.id,
                                                        'weight',
                                                        e.target.value ==
                                                          null ||
                                                          e.target.value == ''
                                                          ? '0'
                                                          : e.target.value,
                                                      );
                                                    }}
                                                  />
                                                </Grid>
                                              </Grid>

                                              {!initObj.isTemplate ? (
                                                <Grid item container xs={3}>
                                                  <Grid item xs={7}>
                                                    <CommonTextField
                                                      name={`loadingListValue.${index}.customWaybillNo`}
                                                      size="small"
                                                      label={false}
                                                      className={classes.field}
                                                      smallPadding
                                                      readOnly={isItemReadOnly}
                                                      noLabel
                                                      smallFontSize
                                                      onBlur={(e) => {
                                                        apiPartialUpdate(
                                                          _order.id,
                                                          'customWaybillNo',
                                                          e.target.value ?? '',
                                                        );
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={5}>
                                                    <CommonTextField
                                                      name={`loadingListValue.${index}.routeName`}
                                                      noLabel
                                                      size="small"
                                                      smallFontSize
                                                      smallPadding
                                                      readOnly
                                                    />
                                                  </Grid>
                                                </Grid>
                                              ) : null}
                                              <Grid
                                                item
                                                container
                                                xs={2}
                                                style={{
                                                  marginLeft: 2,
                                                  padding: 0,
                                                }}
                                              >
                                                <SelectEnumFieldSmall
                                                  name={`loadingListValue.${index}.customsProcedure`}
                                                  key={`loadingListValue.${index}.customsProcedure`}
                                                  enumObject={
                                                    CustomsProcedureNew
                                                  }
                                                  translationPath={
                                                    'enums.customsProcedure'
                                                  }
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                              >
                                                <Box
                                                  width={'100%'}
                                                  display="flex"
                                                  justifyContent={
                                                    initObj.isTemplate
                                                      ? 'flex-start'
                                                      : 'space-evenly'
                                                  }
                                                  style={
                                                    initObj.isTemplate
                                                      ? { marginLeft: 14 }
                                                      : undefined
                                                  }
                                                  alignItems="flex-start"
                                                >
                                                  {updatedFrom == 'Traffic' && (
                                                    <Tooltip title="Need of check from customers??">
                                                      <FastCheckbox
                                                        name={`loadingListValue.${index}.checked`}
                                                        label={''}
                                                        size="small"
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                      />
                                                    </Tooltip>
                                                  )}
                                                  <Tooltip title="Add note">
                                                    <div>
                                                      <CheckboxIconField
                                                        name={`loadingListValue.${index}.addNote`}
                                                        icon={
                                                          <NotesIcon
                                                            height={24}
                                                            width={24}
                                                            fill={normalGrey}
                                                          />
                                                        }
                                                        checkedIcon={
                                                          <NotesIcon
                                                            height={24}
                                                            width={24}
                                                            fill={selectedGreen}
                                                          />
                                                        }
                                                        size="small"
                                                      />
                                                    </div>
                                                  </Tooltip>
                                                  <Tooltip
                                                    title={`${t(
                                                      'attributes.tooltipDangerousGoodsIcon',
                                                    )}`}
                                                  >
                                                    <div>
                                                      <CheckboxIconField
                                                        name={`loadingListValue.${index}.isDangerous`}
                                                        icon={
                                                          <DangerIcon
                                                            height={24}
                                                            width={24}
                                                            fill={normalGrey}
                                                          />
                                                        }
                                                        checkedIcon={
                                                          <DangerIcon
                                                            height={24}
                                                            width={24}
                                                            fill={dangerRed}
                                                          />
                                                        }
                                                        size="small"
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                      />
                                                    </div>
                                                  </Tooltip>
                                                  {!initObj.isTemplate ? (
                                                    <Tooltip title="Custom fields">
                                                      <div>
                                                        <CheckboxIconField
                                                          name={`loadingListValue.${index}.isCustom`}
                                                          icon={
                                                            <CustomsIcon
                                                              height={24}
                                                              width={24}
                                                              fill={normalGrey}
                                                            />
                                                          }
                                                          checkedIcon={
                                                            <CustomsIcon
                                                              height={24}
                                                              width={24}
                                                              fill={
                                                                selectedGreen
                                                              }
                                                            />
                                                          }
                                                          size="small"
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                  ) : null}
                                                  {!initObj.isTemplate ? (
                                                    <Tooltip title="Terminal fields">
                                                      <div>
                                                        <CheckboxIconField
                                                          name={`loadingListValue.${index}.isTerminal`}
                                                          icon={
                                                            <TerminalIcon
                                                              height={24}
                                                              width={24}
                                                              fill={normalGrey}
                                                            />
                                                          }
                                                          checkedIcon={
                                                            <TerminalIcon
                                                              height={24}
                                                              width={24}
                                                              fill={
                                                                selectedGreen
                                                              }
                                                            />
                                                          }
                                                          size="small"
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                  ) : null}
                                                  <>
                                                    {props.values
                                                      .loadingListValue
                                                      .length >= 2 &&
                                                    !isItemReadOnly ? (
                                                      <IconButton
                                                        className={
                                                          classes.deleteButton
                                                        }
                                                        onClick={() => {
                                                          arrayHelpers.remove(
                                                            index,
                                                          );
                                                        }}
                                                      >
                                                        <DeleteIcon />
                                                      </IconButton>
                                                    ) : null}
                                                  </>
                                                </Box>
                                              </Grid>
                                            </Box>

                                            {_order.addNote && (
                                              <Grid
                                                container
                                                justifyContent="flex-start"
                                                style={{
                                                  marginTop: 5,
                                                  marginBottom: 5,
                                                }}
                                              >
                                                <Grid item container xs={5}>
                                                  <Grid item xs={2}>
                                                    <Typography
                                                      style={{
                                                        fontSize: 12,
                                                      }}
                                                      color="textPrimary"
                                                    >{`Note-`}</Typography>
                                                  </Grid>
                                                  <Grid item xs={10}>
                                                    <FastTextFieldNote
                                                      noLabel
                                                      size="medium"
                                                      multiline
                                                      smallFontSize
                                                      name={`loadingListValue.${index}.note`}
                                                      className={classes.field}
                                                      smallPadding
                                                      readOnly={isItemReadOnly}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            )}
                                            {_order.isDangerous && (
                                              <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="flex-start"
                                                style={{
                                                  width: '100%',
                                                  marginTop: 1,
                                                }}
                                              >
                                                {/* new for dangerous goods info */}
                                                <Grid
                                                  item
                                                  xs={5}
                                                  container
                                                  direction="row"
                                                  alignItems="center"
                                                >
                                                  <Grid item xs={2}>
                                                    <Typography
                                                      style={{
                                                        fontSize: 12,
                                                      }}
                                                      color="textPrimary"
                                                    >{`Goods Info -`}</Typography>
                                                  </Grid>
                                                  <Grid item xs={10}>
                                                    <SelectEnumFieldSmall
                                                      name={`loadingListValue.${index}.classification`}
                                                      enumObject={
                                                        DangerousGoodsClassification
                                                      }
                                                      translationPath={
                                                        'enums.dangerousGoodsClass'
                                                      }
                                                      readOnly={isItemReadOnly}
                                                    />
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={2}
                                                  container
                                                  direction="row"
                                                  alignItems="center"
                                                >
                                                  <Grid item xs={2}>
                                                    <Typography
                                                      style={{
                                                        fontSize: 12,
                                                      }}
                                                      color="textPrimary"
                                                    >{`UN-`}</Typography>
                                                  </Grid>
                                                  <Grid item xs={10}>
                                                    <FastTextFieldUnNumber
                                                      noLabel
                                                      size="small"
                                                      smallFontSize
                                                      name={`loadingListValue.${index}.unNumber`}
                                                      className={
                                                        classes.numberFields
                                                      }
                                                      type="number"
                                                      placeholder="4 digit number"
                                                      readOnly={isItemReadOnly}
                                                      smallPadding
                                                      autoComplete="off"
                                                      onInput={(e) => {
                                                        (
                                                          e.target as HTMLInputElement
                                                        ).value = Math.max(
                                                          0,
                                                          parseInt(
                                                            (
                                                              e.target as HTMLInputElement
                                                            ).value,
                                                          ),
                                                        )
                                                          .toString()
                                                          .slice(0, 4);
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={2}
                                                  container
                                                  direction="row"
                                                >
                                                  <Grid item xs={10}>
                                                    <FastCheckbox
                                                      name={`loadingListValue.${index}.isLimitedQty`}
                                                      label={t(
                                                        'attributes.limitedQty',
                                                      )}
                                                      size="small"
                                                      readOnly={isItemReadOnly}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={2}>
                                                    <Tooltip
                                                      title={`${t(
                                                        'attributes.tooltipLimitedQtyInfo',
                                                      )}`}
                                                    >
                                                      <IconButton
                                                        className={
                                                          classes.deleteButton
                                                        }
                                                        onClick={() =>
                                                          setOpenLimitedQtyInfoDialog(
                                                            true,
                                                          )
                                                        }
                                                      >
                                                        <InfoOutlined />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </Grid>
                                                </Grid>
                                                <Grid item xs={3}></Grid>
                                              </Box>
                                            )}

                                            {_order.isCustom && (
                                              <>
                                                <Grid
                                                  container
                                                  style={{ marginTop: 5 }}
                                                >
                                                  <Grid container item xs={3}>
                                                    <Grid
                                                      item
                                                      xs={3}
                                                      direction="row"
                                                      alignItems="center"
                                                    >
                                                      <Typography
                                                        style={{
                                                          fontSize: 12,
                                                          marginTop: 3,
                                                        }}
                                                        color="textPrimary"
                                                      >{`VOEC ID:`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                      <CommonTextField
                                                        name={`loadingListValue.${index}.customVOECId`}
                                                        size="small"
                                                        label={false}
                                                        className={
                                                          classes.field
                                                        }
                                                        smallPadding
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container item xs={3}>
                                                    <Grid
                                                      item
                                                      xs={2}
                                                      direction="row"
                                                      alignItems="center"
                                                    >
                                                      <Typography
                                                        style={{
                                                          fontSize: 12,
                                                          marginTop: 3,
                                                        }}
                                                        color="textPrimary"
                                                      >{`Invoice:`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                      <CommonTextField
                                                        name={`loadingListValue.${index}.customInvoiceNo`}
                                                        size="small"
                                                        label={false}
                                                        className={
                                                          classes.field
                                                        }
                                                        smallPadding
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Grid item container xs={3}>
                                                    <Grid
                                                      item
                                                      xs={3}
                                                      direction="row"
                                                      alignItems="flex-start"
                                                    >
                                                      <Typography
                                                        style={{
                                                          fontSize: 12,
                                                          marginTop: 3,
                                                        }}
                                                        color="textPrimary"
                                                      >{`MRN No:`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                      <CommonTextField
                                                        name={`loadingListValue.${index}.customMrnNo`}
                                                        size="small"
                                                        label={false}
                                                        className={
                                                          classes.field
                                                        }
                                                        smallPadding
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Grid item container xs={3}>
                                                    <Grid
                                                      item
                                                      xs={2}
                                                      direction="row"
                                                      alignItems="center"
                                                    >
                                                      <Typography
                                                        style={{
                                                          fontSize: 12,
                                                          marginTop: 3,
                                                        }}
                                                        color="textPrimary"
                                                      >{`Waybill:`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                      <CommonTextField
                                                        name={`loadingListValue.${index}.customWaybillNo`}
                                                        size="small"
                                                        label={false}
                                                        className={
                                                          classes.field
                                                        }
                                                        smallPadding
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Grid>

                                                <Grid
                                                  container
                                                  style={{ marginTop: 5 }}
                                                >
                                                  <Grid item container xs={3}>
                                                    <Grid item xs={3}>
                                                      <Typography
                                                        style={{
                                                          fontSize: 12,
                                                          marginTop: 3,
                                                        }}
                                                        color="textPrimary"
                                                      >{`CMR No:`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                      <CommonTextField
                                                        name={`loadingListValue.${index}.customCmrNo`}
                                                        size="small"
                                                        label={false}
                                                        className={
                                                          classes.field
                                                        }
                                                        smallPadding
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>

                                                  <Grid item container xs={3}>
                                                    <Grid item xs={2}>
                                                      <Typography
                                                        style={{
                                                          fontSize: 12,
                                                          marginTop: 3,
                                                        }}
                                                        color="textPrimary"
                                                      >{`Note:`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                      <CommonTextField
                                                        name={`loadingListValue.${index}.customNote`}
                                                        size="small"
                                                        label={false}
                                                        className={
                                                          classes.field
                                                        }
                                                        smallPadding
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Grid item container xs={3}>
                                                    <Grid item xs={3}>
                                                      <Typography
                                                        style={{
                                                          fontSize: 12,
                                                          marginTop: 3,
                                                        }}
                                                        color="textPrimary"
                                                      >{`Connected No:`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                      <CommonTextField
                                                        name={`loadingListValue.${index}.connectedNB`}
                                                        size="small"
                                                        label={false}
                                                        className={
                                                          classes.field
                                                        }
                                                        smallPadding
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Grid item container xs={3}>
                                                    <Grid item xs={12}>
                                                      <FastCheckbox
                                                        name={`loadingListValue.${index}.customCleared`}
                                                        label={t(
                                                          'attributes.clearedFromCustom',
                                                        )}
                                                        size="small"
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                        checkedChange={(c) => {
                                                          apiPartialUpdate(
                                                            _order.id,
                                                            'customCleared',
                                                            c
                                                              ? 'true'
                                                              : 'false',
                                                          );
                                                        }}
                                                      />
                                                      <FastCheckbox
                                                        name={`loadingListValue.${index}.customClearedForImport`}
                                                        label={t(
                                                          'attributes.clearedFromCustomImport',
                                                        )}
                                                        size="small"
                                                        readOnly={
                                                          isItemReadOnly
                                                        }
                                                        checkedChange={(c) => {
                                                          apiPartialUpdate(
                                                            _order.id,
                                                            'customClearedForImport',
                                                            c
                                                              ? 'true'
                                                              : 'false',
                                                          );
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </>
                                            )}

                                            {_order.isTerminal && (
                                              <Box
                                                display="flex"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{
                                                  width: '100%',
                                                  marginTop: 1,
                                                  marginLeft: 28,
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={12}
                                                  container
                                                  direction="row"
                                                  alignItems="center"
                                                >
                                                  <Grid item xs={3}>
                                                    <FastCheckbox
                                                      name={`loadingListValue.${index}.terminalCleared`}
                                                      label={t(
                                                        'attributes.terminalCleared',
                                                      )}
                                                      size="small"
                                                      isShipmentBox={true}
                                                      readOnly={isItemReadOnly}
                                                      checkedChange={(c) => {
                                                        apiPartialUpdate(
                                                          _order.id,
                                                          'terminalCleared',
                                                          c ? 'true' : 'false',
                                                        );
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={3}>
                                                    <FastCheckbox
                                                      name={`loadingListValue.${index}.terminalNotCleared`}
                                                      label={t(
                                                        'attributes.terminalNotCleared',
                                                      )}
                                                      size="small"
                                                      isShipmentBox={true}
                                                      readOnly={isItemReadOnly}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={3}>
                                                    <FastCheckbox
                                                      name={`loadingListValue.${index}.terminalShipmentNotArrived`}
                                                      label={t(
                                                        'attributes.shipmentNotArrived',
                                                      )}
                                                      size="small"
                                                      isShipmentBox={true}
                                                      readOnly={isItemReadOnly}
                                                    />
                                                  </Grid>
                                                  {props.values
                                                    .loadingListValue[index]
                                                    .terminalNotCleared && (
                                                    <Grid
                                                      xs={12}
                                                      container
                                                      direction="row"
                                                      alignItems="center"
                                                      style={{ marginTop: 4 }}
                                                    >
                                                      <Grid item xs={1}>
                                                        <Typography
                                                          style={{
                                                            fontSize: 12,
                                                          }}
                                                          color="textPrimary"
                                                        >{`Packages`}</Typography>
                                                        <FastTextFieldPackage
                                                          noLabel
                                                          size="small"
                                                          smallFontSize
                                                          name={`loadingListValue.${index}.terminalDevPackages`}
                                                          className={
                                                            classes.numberFields
                                                          }
                                                          type="number"
                                                          smallPadding
                                                          readOnly={
                                                            isItemReadOnly
                                                          }
                                                        />
                                                      </Grid>
                                                      <Grid item xs={1}>
                                                        <Typography
                                                          style={{
                                                            fontSize: 12,
                                                          }}
                                                          color="textPrimary"
                                                        >{`Pallets`}</Typography>
                                                        <FastTextFieldPallets
                                                          noLabel
                                                          size="small"
                                                          smallFontSize
                                                          name={`loadingListValue.${index}.terminalDevPallets`}
                                                          className={
                                                            classes.numberFields
                                                          }
                                                          type="number"
                                                          smallPadding
                                                          readOnly={
                                                            isItemReadOnly
                                                          }
                                                        />
                                                      </Grid>
                                                      <Grid item xs={1}>
                                                        <Typography
                                                          style={{
                                                            fontSize: 12,
                                                          }}
                                                          color="textPrimary"
                                                        >{`PalletPlace`}</Typography>
                                                        <FastTextFieldPalletSpace
                                                          noLabel
                                                          size="small"
                                                          smallFontSize
                                                          smallPadding
                                                          name={`loadingListValue.${index}.terminalDevPalletSpace`}
                                                          className={
                                                            classes.numberFields
                                                          }
                                                          type="number"
                                                          readOnly={
                                                            isItemReadOnly
                                                          }
                                                        />
                                                      </Grid>
                                                      <Grid item xs={1}>
                                                        <Typography
                                                          style={{
                                                            fontSize: 12,
                                                          }}
                                                          color="textPrimary"
                                                        >{`Weight`}</Typography>
                                                        <FastTextFieldWeight
                                                          noLabel
                                                          size="small"
                                                          smallFontSize
                                                          smallPadding
                                                          name={`loadingListValue.${index}.terminalDevWeight`}
                                                          className={
                                                            classes.numberFields
                                                          }
                                                          type="number"
                                                          readOnly={
                                                            isItemReadOnly
                                                          }
                                                        />
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xs={3}
                                                        style={{
                                                          marginRight: 2,
                                                        }}
                                                      >
                                                        <Typography
                                                          style={{
                                                            fontSize: 12,
                                                          }}
                                                          color="textPrimary"
                                                        >{`Information`}</Typography>
                                                        <FastTextFieldNote
                                                          noLabel
                                                          size="small"
                                                          multiline
                                                          smallFontSize
                                                          name={`loadingListValue.${index}.terminalDevInfo`}
                                                          className={
                                                            classes.field
                                                          }
                                                          smallPadding
                                                          readOnly={
                                                            isItemReadOnly
                                                          }
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  )}
                                                </Grid>
                                              </Box>
                                            )}
                                          </Grid>
                                        </ListItem>
                                        <Divider
                                          style={{
                                            backgroundColor: 'green',
                                            marginTop: 5,
                                            marginBottom: 5,
                                          }}
                                        />
                                      </>
                                    );
                                  },
                                )}
                              </List>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    />
                    <Grid
                      item
                      container
                      justifyContent="center"
                      style={{ marginTop: 10 }}
                    >
                      {isPastShipmentDate ? null : initObj.isTemplate &&
                        props.values.date ? (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginRight: 10 }}
                        >
                          {t('actions.createShipment')}
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginRight: 10 }}
                          disabled={isUpdateInProgress}
                        >
                          {`${
                            isUpdateInProgress
                              ? t('validation.loadingApi')
                              : t('actions.update')
                          }`}
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          setViewShipment(false);
                        }}
                      >
                        {t('button.close')}
                      </Button>
                      {isPastShipmentDate ? (
                        <Typography>{'ReadOnly due to Past date!'}</Typography>
                      ) : !readOnly ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            deleteShipment({
                              variables: {
                                id,
                                updatedFrom,
                                shipmentDate: currentShipmentDate,
                              },
                            }).then(() => {
                              setViewShipment(false);
                              templateUpdated();
                            });
                          }}
                        >
                          {showDate ? 'Delete Template' : 'Delete Shipment'}
                        </Button>
                      ) : null}
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Modal>
      )}
    </>
  );
}
